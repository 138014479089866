import { useRouter } from 'next/router';
import { objectToQueryString, sanitizeQuery } from '../helpers/queryString';
import { decodeValue, encodeValue } from '../helpers/encrypt';

export const encryptFilters = (query) => {
  const { companySlug, q, page, ...filterValues } = query;
  let filter = null;
  if (filterValues && Object.keys(filterValues).length > 0) {
    filter = encodeValue(JSON.stringify(filterValues));
    return objectToQueryString({
      query: {
        q,
        page,
        filter,
      },
    });
  }
  return objectToQueryString({
    query: {
      q,
      page,
    },
  });
};

export const decryptQuery = (query) => {
  const { filter, ...otherQuery } = query;
  let decodedFilter = filter;
  if (decodedFilter) {
    const decoded = decodeValue(decodedFilter);
    try {
      decodedFilter = JSON.parse(decodeURIComponent(decoded));
    } catch (e) {
      decodedFilter = {};
    }
  }
  return { ...decodedFilter, ...otherQuery };
};

export const removeEmpty = (obj) => {
  const result = { ...obj };
  Object.keys(result).forEach((key) => {
    const isArray = Array.isArray(result[key]);
    if (isArray && !result[key].length) {
      delete result[key];
    }
  });
  return result;
};

const useEncryptedRouter = () => {
  const baseRouter = useRouter();
  const encryptedRouter = { ...baseRouter };

  // decrypt the query string
  // overwrite the query using the new parsing style
  encryptedRouter.query = decryptQuery(sanitizeQuery(baseRouter.query));
  // overwrite router.push so that it can encrypt the query as expected
  encryptedRouter.push = ({ pathname = '', query = null, options = { shallow: true } }) => {
    let url = pathname;

    // Dynamic routes need to be configured here for variable replacement
    if (baseRouter.query.companySlug) {
      url = pathname.replace(/\[companySlug]/, baseRouter.query.companySlug);
    }
    const cleanQuery = removeEmpty(query);

    if (cleanQuery && Object.keys(cleanQuery).length) {
      const encryptedQuery = encryptFilters(cleanQuery);
      url = `${url}?${encryptedQuery}`;
    }
    return baseRouter.push(url, undefined, options);
  };
  return encryptedRouter;
};

export default useEncryptedRouter;
