import { handleActions } from 'redux-actions';
import produce from 'immer';
import { updateReferrer, updateNavigationHistory } from '../actions/page';

export const initialState = {
  referrer: null,
  internalNav: false,
  history: [],
};

/* eslint-disable default-case, no-param-reassign */
const pageReducer = handleActions(
  {
    [updateNavigationHistory]: produce((draft, action) => {
      const { url } = action.payload;
      draft.history.unshift(url);
      draft.internalNav = draft.history.length > 0;
      return draft;
    }),
    [updateReferrer]: produce((draft, action) => {
      const { referrer } = action.payload;
      draft.referrer = referrer;
      return draft;
    }),
  },
  initialState,
);

export default pageReducer;
