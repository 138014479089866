import { call, put, takeLatest } from 'redux-saga/effects';
import { destroyCookie, setCookie } from 'nookies';
import { post } from '../../api/index';
import {
  loginRequest,
  loginFailureRequest,
  userTokenAuthenticationRequest,
  loadLogoutRequest,
  loadedLogoutRequest,
} from '../actions/userActions';

export function* loginRequestWorker({ payload }) {
  const { network, values } = payload;
  const { id: collectionId } = network;
  try {
    const response = yield call(post, '/login', {
      ...values,
      collectionId,
    });
    const { data } = response;
    const { token } = data;
    yield call(setCookie, null, 'token', token, {
      maxAge: 60 * 60 * 24,
      path: '/',
    });
    yield put(userTokenAuthenticationRequest({ network, token, source: 'login-form', ...values }));
  } catch (e) {
    yield put(loginFailureRequest(e));
  }
}

export function* logoutRequestWorker({ payload }) {
  const { network } = payload;
  yield call(destroyCookie, null, 'token');
  yield put(loadedLogoutRequest({ network }));
}

function* loginRequestWatcher() {
  yield takeLatest(loginRequest().type, loginRequestWorker);
  yield takeLatest(loadLogoutRequest().type, logoutRequestWorker);
}

export default loginRequestWatcher;
