import React from 'react';
import PropTypes from 'prop-types';

import USWorkAuthorizationForm from '../../../components/molecules/USWorkAuthorizationForm';

import { OnboardingPropTypes } from '../initialValues';

const USWorkAuthorizationStep = ({ formData, nextStep, prevStep }) => (
  <USWorkAuthorizationForm
    initialValues={formData}
    onSubmit={nextStep}
    onBack={prevStep}
    saveText="Next step"
    title="US Work Authorization"
  />
);

USWorkAuthorizationStep.propTypes = {
  formData: OnboardingPropTypes.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default USWorkAuthorizationStep;
