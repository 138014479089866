/* eslint-disable camelcase, no-console */
import isbot from 'isbot';
export const urlLocationInfo = () => {
  const { host, href, pathname, protocol } = typeof window !== 'undefined' && window.location;
  return { path: pathname, host, url: href, protocol };
};

export const analyticsUrlLocationInfo = () => {
  const { path, host, url } = urlLocationInfo();
  return { path, host, url };
};

export const isBot = () => isbot(navigator.userAgent);

export const isPreview = (host, previewUrl, networkUrl) => host === previewUrl && networkUrl !== previewUrl;

export const commonProperties = (network, user, properties = {}) => {
  let commonProps = {};
  const { id: networkId } = network;
  const { id: userId, userTalentCollection } = user;

  if (networkId && !properties.networkId) {
    commonProps.collectionId = networkId;
  }

  if (userId && !properties.userId) {
    commonProps.userId = userId;
  }
  // isEmployerAdmin is the legacy name of isCompanyAdmin and could be used in some reports
  // TODO: remove isEmployerAdmin and use isCompanyAdmin in the reports
  const {
    isCompanyAdmin: isEmployerAdmin,
    isNetworkAdmin,
    isNetworkAmbassador,
  } = userTalentCollection || {
    isCompanyAdmin: false,
    isNetworkAdmin: false,
    isNetworkAmbassador: false,
  };

  commonProps = {
    ...commonProps,
    isNetworkAdmin,
    isNetworkAmbassador,
    isEmployerAdmin,
  };
  return commonProps;
};

export const userProfileUpdatedSkillEvent = (skill, network, source) => {
  const { value: id, label: name } = skill;

  return {
    network,
    eventName: 'profile:skill_updated',
    properties: {
      id,
      skillId: id,
      name,
      source,
      collection_id: network.id,
    },
  };
};

export const selectedSkillsEvents = ({ skills, network }) => {
  if (skills) {
    return skills.map((skill) => userProfileUpdatedSkillEvent(skill, network, 'profile'));
  }
  return [];
};
