import { handleActions } from 'redux-actions';
import produce from 'immer';
import { hydrate } from '../actions/hydrate';
import {
  companiesListFailure,
  companiesListRequest,
  companiesListSuccess,
  loadedDiscardCompany,
  moreCompaniesRequest,
} from '../actions/companies';

export const initialState = {
  found: [],
  total: 0,
  initialized: false,
  isLoading: false,
  isLazyLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const companiesReducer = handleActions(
  {
    [moreCompaniesRequest]: produce((draft) => {
      draft.isLoading = true;
      return draft;
    }),
    [loadedDiscardCompany]: produce((draft, action) => {
      draft.found.map((company) => {
        if (company.id === action.payload.company.id) {
          company.isDiscarded = action.payload.discarded;
        }
        return company;
      });
      return draft;
    }),
    [companiesListRequest]: produce((draft) => {
      draft.isLoading = true;
      return draft;
    }),
    [companiesListSuccess]: produce((draft, action) => {
      const { companies, total, page } = action.payload;
      if (page) {
        draft.found = [...draft.found, ...companies];
        if (companies.length) {
          draft.isLazyLoading = true;
        }
      } else {
        draft.found = companies;
      }
      draft.total = parseInt(total || '0', 10);
      draft.isLoading = false;
      draft.initialized = true;
      return draft;
    }),
    [companiesListFailure]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [hydrate]: produce((draft, action) => {
      const { companies } = action.payload;
      const { initialized } = companies;
      if (initialized) {
        draft = { ...companies };
        draft.isLoading = false;
      }
      return draft;
    }),
  },
  initialState,
);

export default companiesReducer;
