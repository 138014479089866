import { createAction, createActions } from 'redux-actions';

export const { allJobsRequest, moreJobsRequest, allJobsSuccess, allJobsFailure, allJobsReset } = createActions(
  'ALL_JOBS_REQUEST',
  'MORE_JOBS_REQUEST',
  'ALL_JOBS_SUCCESS',
  'ALL_JOBS_FAILURE',
  'All_JOBS_RESET',
);

export const { loadJobRequest, loadedJobRequest, errorJobRequest, cleanJobRequest } = createActions(
  'LOAD_JOB_REQUEST',
  'LOADED_JOB_REQUEST',
  'ERROR_JOB_REQUEST',
  'CLEAN_JOB_REQUEST',
);

export const {
  loadFavoriteJob,
  loadedFavoriteJob,
  errorFavoriteJob,
  loadDiscardJob,
  loadedDiscardJob,
  errorDiscardJob,
} = createActions(
  'LOAD_FAVORITE_JOB',
  'LOADED_FAVORITE_JOB',
  'ERROR_FAVORITE_JOB',
  'LOAD_DISCARD_JOB',
  'LOADED_DISCARD_JOB',
  'ERROR_DISCARD_JOB',
);

export const { loadFavoriteJobsList, loadedFavoriteJobsList, errorFavoriteJobsList } = createActions(
  'LOAD_FAVORITE_JOBS_LIST',
  'LOADED_FAVORITE_JOBS_LIST',
  'ERROR_FAVORITE_JOBS_LIST',
);

export const { loadDiscardedJobsList, loadedDiscardedJobsList, errorDiscardedJobsList } = createActions(
  'LOAD_DISCARDED_JOBS_LIST',
  'LOADED_DISCARDED_JOBS_LIST',
  'ERROR_DISCARDED_JOBS_LIST',
);

export const { jobLinkClicked, applyToJobClicked } = createActions('JOB_LINK_CLICKED', 'APPLY_TO_JOB_CLICKED');

export const {
  loadJobApplicationSubmitted,
  loadedJobApplicationSubmitted,
  errorJobApplicationSubmitted,
  resetErrorJobApplicationSubmitted,
} = createActions(
  'LOAD_JOB_APPLICATION_SUBMITTED',
  'LOADED_JOB_APPLICATION_SUBMITTED',
  'ERROR_JOB_APPLICATION_SUBMITTED',
  'RESET_ERROR_JOB_APPLICATION_SUBMITTED',
);

export const flagWrongDataClick = createAction('FLAG_WRONG_DATA_CLICK');
