import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import H1 from './H1';
import H2 from './H2';
import Paragraph from './Paragraph';
import Link from './Link';
import { Pre } from './pre';

const MarkdownRenderer = ({ source }) => (
  <ReactMarkdown
    components={{
      h1: H1,
      h2: H2,
      p: Paragraph,
      a: Link,
      pre: Pre,
    }}
  >
    {source}
  </ReactMarkdown>
);

MarkdownRenderer.propTypes = {
  source: PropTypes.string,
};

MarkdownRenderer.defaultProps = {
  source: '',
};

export default MarkdownRenderer;
