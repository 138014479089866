import { withScope, captureException, captureEvent, captureMessage } from '@sentry/nextjs';
import logger from '../helpers/logger';

const isDevelopmentEnvironment = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

class SentryAPI {
  static trackError(errorName, extraContext = null) {
    const { serverResponse } = extraContext || {};
    const { xhrStatus } = serverResponse || {};
    if (xhrStatus === 'abort') {
      return;
    }
    if (isDevelopmentEnvironment()) {
      logger.error({ extraContext }, `Track Error: ${errorName}`);
    } else {
      withScope((scope) => {
        if (extraContext) {
          scope.setContext('error content', extraContext);
        }
        const error = errorName instanceof Error ? errorName : new Error(errorName);
        captureException(error);
      });
    }
  }

  static captureEvent({ name, stackTrace }) {
    if (isDevelopmentEnvironment()) {
      logger.info({ stackTrace }, `Capture Event: ${name}`);
    } else {
      captureEvent({
        message: name,
        stacktrace: stackTrace,
      });
    }
  }

  static captureMessage(message) {
    if (isDevelopmentEnvironment()) {
      logger.info(message);
    } else {
      captureMessage(message);
    }
  }
}

export default SentryAPI;
