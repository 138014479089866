/* eslint-disable react/jsx-no-target-blank, react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Checkbox, FormField, OnboardingLayoutContent, Message, FormFieldError } from '@getro/rombo';
import { Field, Form, Formik } from 'formik';
import { BroadcastChannel } from 'broadcast-channel';
import { Button, Box, Flex } from 'rebass/styled-components';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import STRINGS from '../../../constants/strings';
import { OnboardingPropTypes } from '../initialValues';
import UserAPI from '../../../api/UserAPI';
import ProfileSectionTitle from '../../../components/atoms/ProfileSectionTitle';
import { signupTakenEmail } from '../../../redux/actions/onboardingActions';
import { NetworkSchema } from '../../../schema/network';
import usePrivacyPolicyUrl from '../../../hooks/usePrivacyPolicyUrl';

const CreateAccountStep = ({ formData: initialFormData, nextStep, prevStep, network }) => {
  const dispatch = useDispatch();

  const [emailNotAvailable, setEmailNotAvailable] = useState(false);
  const [checkingEmailAvailability, setCheckingEmailAvailability] = useState(false);
  const router = useRouter();

  const [formData, setFormData] = useState(initialFormData);
  const { id: userId, forcePasswordUpdate, linkedinId } = formData;
  const isSignUp = !userId && !linkedinId;
  const needsPassword = isSignUp || forcePasswordUpdate;
  const { privacyPolicyUrl } = usePrivacyPolicyUrl(network);

  const createFormSchema = () =>
    Yup.object().shape({
      firstName: Yup.string().required(STRINGS.validation.requiredField),
      lastName: Yup.string().required(STRINGS.validation.requiredField),
      email: Yup.string().email().required(STRINGS.validation.requiredField),
      linkedinId: Yup.string().notRequired(),
      password: needsPassword
        ? Yup.string().min(8, STRINGS.validation.passwordLength).required(STRINGS.validation.requiredField)
        : Yup.string().notRequired(),
      termsOfService: Yup.boolean().oneOf([true], STRINGS.validation.termsAccepted),
    });

  // LinkedIn connect
  const [linkedinConnectError, setLinkedinConnectError] = useState(false);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('linkedin');
    broadcastChannel.onmessage = (msg) => {
      const { accessToken, userData } = msg;
      if (!accessToken || userData === {}) {
        setLinkedinConnectError(true);
        return;
      }

      setLinkedinConnectError(false);
      // eslint-disable-next-line no-shadow
      const { firstName, lastName, emailAddress: email, avatarUrl, linkedinId } = userData;

      setFormData({
        ...formData,
        linkedinId,
        firstName,
        lastName,
        email,
        avatarUrl,
      });
    };

    return () => broadcastChannel.close();
  }, [formData]);

  const checkEmailAvailability = (email) => {
    setCheckingEmailAvailability(true);

    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      UserAPI.validate({ email })
        .then(() => {
          setCheckingEmailAvailability(false);
          resolve(true);
        })
        .catch((e) => {
          const { response } = e;
          const { status } = response || {};
          setCheckingEmailAvailability(false);
          if (status === 422) {
            dispatch(signupTakenEmail({ network, email }));
            resolve(false);
          } else {
            resolve(true);
          }
        });
    });
  };

  const handleSubmit = async (values) => {
    setEmailNotAvailable(false);
    if (!values.id) {
      const emailAvailable = await checkEmailAvailability(values.email);

      if (!emailAvailable) {
        setEmailNotAvailable(true);
        return;
      }
    }

    nextStep(values);
  };

  const onSignInClick = () => {
    router.replace('/login');
  };

  return (
    <Formik enableReinitialize initialValues={formData} validationSchema={createFormSchema()} onSubmit={handleSubmit}>
      {({ values, touched, errors, setFieldValue }) => {
        const { termsOfService } = values;

        const title = isSignUp ? 'First, let’s create your account' : 'Double-check your information';

        return (
          <Form>
            <OnboardingLayoutContent nextStepSubmit onBack={prevStep} title={title} loading={checkingEmailAvailability}>
              <Field type="hidden" name="avatarUrl" />
              <Field type="hidden" name="linkedinId" />

              {linkedinConnectError && (
                <Message type="error">
                  We couldn&apos;t connect your LinkedIn account, please try again or create your account using your
                  email address.
                </Message>
              )}

              {emailNotAvailable && (
                <Message type="error">
                  It looks like you have an existing profile on another talent network using Getro (using the same
                  email).{' '}
                  <Button
                    onClick={onSignInClick}
                    type="button"
                    variant="secondary"
                    fontWeight="medium"
                    p={0}
                    m={0}
                    color="primary"
                    sx={{
                      fontSize: 1,
                      border: 'none',
                    }}
                  >
                    Sign in to your account here &gt;
                  </Button>
                </Message>
              )}

              <ProfileSectionTitle>
                {isSignUp ? 'Or, create my account manually' : 'Account information'}
              </ProfileSectionTitle>

              <Flex sx={{ columnGap: 4 }} flexDirection={['column', 'row']}>
                <Box flex="1" mb="3">
                  <Field type="text" name="firstName" component={FormField} label="First name" />
                </Box>

                <Box flex="1" mb="3">
                  <Field type="text" name="lastName" component={FormField} label="Last name" />
                </Box>
              </Flex>

              <Flex mb="3" sx={{ columnGap: 4 }} flexDirection={['column', 'row']}>
                <Box flex="1" mb="3">
                  <Field type="text" name="email" component={FormField} label="Email address" disabled={userId} />
                </Box>

                {needsPassword && (
                  <Box flex="1" mb="3">
                    <Field type="password" name="password" component={FormField} label="Password" width="full" />
                  </Box>
                )}
              </Flex>

              <Field
                name="termsOfService"
                component={(data) => (
                  <>
                    <Checkbox
                      label={
                        <span>
                          I have read and accept {network.name}&rsquo;s{' '}
                          <a href={privacyPolicyUrl} target="_blank">
                            Privacy Policy
                          </a>{' '}
                          and the{' '}
                          <a href="https://www.getro.com/terms" target="_blank">
                            Terms of Use
                          </a>{' '}
                          of this software.
                        </span>
                      }
                      name="termsOfService"
                      checked={termsOfService}
                      onChange={(e) => setFieldValue('termsOfService', e.target.checked)}
                      {...data}
                    />
                    {touched.termsOfService && errors.termsOfService ? (
                      <FormFieldError error={errors.termsOfService} />
                    ) : null}
                  </>
                )}
              />
            </OnboardingLayoutContent>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateAccountStep.propTypes = {
  formData: OnboardingPropTypes.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  network: NetworkSchema.isRequired,
};

export default CreateAccountStep;
