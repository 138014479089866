import { createActions } from 'redux-actions';

export const { fetchNetworkRequest, fetchNetworkSuccess, fetchNetworkFailure } = createActions(
  'FETCH_NETWORK_REQUEST',
  'FETCH_NETWORK_SUCCESS',
  'FETCH_NETWORK_FAILURE',
);

export const { fetchNetworkScriptsSuccess, fetchNetworkScriptsFailure } = createActions(
  'FETCH_NETWORK_SCRIPTS_SUCCESS',
  'FETCH_NETWORK_SCRIPTS_FAILURE',
);
