import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { parseCookies, setCookie, destroyCookie } from 'nookies';

const useAdDev = () => {
  const cookies = parseCookies();
  const [adDev, setAdDev] = useState(cookies.adDev || false);
  const router = useRouter();
  const { query } = router;
  const { start_ad_dev: startAdDev, stop_ad_dev: stopAdDev } = query;

  useEffect(() => {
    if (startAdDev) {
      setCookie(null, 'adDev', true, {
        maxAge: 60 * 60 * 24,
        path: '/',
      });
      setAdDev(true);
      delete router.query.start_ad_dev;
      router.push({ pathname: router.pathname, query: router.query });
    }

    if (stopAdDev) {
      destroyCookie(null, 'adDev');
      setAdDev(false);
      delete router.query.stop_ad_dev;
      router.push({ pathname: router.pathname, query: router.query });
    }
  }, [router, startAdDev, stopAdDev]);

  return adDev;
};

export default useAdDev;
