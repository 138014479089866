import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass/styled-components';

const H1 = ({ node, ...props }) => <Text as="h1" data-testid="h1-decorator" tx="markdown" variant="h1" {...props} />;

H1.propTypes = {
  node: PropTypes.object.isRequired,
};

export default H1;
