import getConfig from 'next/config';
import snakeCase from 'lodash.snakecase';
import { get, post } from './index';
import SentryAPI from './SentryAPI';

const { publicRuntimeConfig } = getConfig();
const urlFor = (id) => `collections/${id}`;
const companiesUrlFor = (id) => `${urlFor(id)}/organizations`;

class NetworkAPI {
  static async show(networkUrl) {
    const { apiV2BaseUrl } = publicRuntimeConfig;
    try {
      const response = await get(urlFor(networkUrl), {
        baseURL: apiV2BaseUrl,
      });
      const { data } = response;
      const { data: collection } = data;
      const { id, attributes } = collection;
      return {
        id,
        ...attributes,
        loaded: true,
      };
    } catch (e) {
      const { response = {} } = e;
      const { status } = response;
      if (status && status !== 403 && status !== 404) {
        SentryAPI.trackError('Retrieve network failed', { id: networkUrl || 'not defined', e });
      }
      throw e;
    }
  }

  static async customFilters({ networkId }) {
    const { apiV2BaseUrl } = publicRuntimeConfig;

    if (!networkId) return [];

    try {
      const response = await get(`collections/${networkId}/custom_filters`, {
        baseURL: apiV2BaseUrl,
      });

      const { data } = response;
      const { data: customFilters = [] } = data;
      return customFilters.map((customFilter) => {
        const { id, attributes } = customFilter;
        if (attributes.values) {
          attributes.values = attributes.values.map((item) => ({ ...item, value: item.name }));
        }
        const { name, values: options, ...otherAttributes } = attributes;
        const prefix = 'organization.topics';
        return {
          id,
          name,
          queryParam: `${prefix}.${snakeCase(attributes.name)}`,
          options,
          ...otherAttributes,
        };
      });
    } catch (e) {
      const { response = {} } = e;
      const { status } = response;
      if (status && status !== 429) {
        SentryAPI.trackError('Retrieve custom filters failed', { id: networkId || 'not defined', e });
      }

      throw e;
    }
  }

  static async activeCompanies(id, params) {
    if (!id) return [];

    try {
      const response = await get(companiesUrlFor(id), {
        params: {
          ...params,
          page: 1,
          perPage: 9999,
        },
      });
      const { data } = response;
      const { items } = data;

      return items;
    } catch (e) {
      const { response = {} } = e;
      const { status } = response;
      if (status && status !== 429) {
        SentryAPI.trackError('Retrieve network companies failed', { id, params, e });
      }
      return [];
    }
  }

  static async saveOnboardingData(id, payload) {
    const { apiV2BaseUrl } = publicRuntimeConfig;

    try {
      const response = await post(`/collections/${id}/job_seekers`, payload, {
        baseURL: apiV2BaseUrl,
      });

      return response.data;
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('Saving onboarding data failed', { id, payload, e });
      }
      throw e;
    }
  }
}

export { urlFor, companiesUrlFor };

export default NetworkAPI;
