import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { parseCookies, setCookie, destroyCookie } from 'nookies';

const useMolFeatureFlags = () => {
  const cookies = parseCookies();
  const [molEarlyAccessUsers, setMolEarlyAccessUsers] = useState(cookies.molEarlyAccessUsers || false);
  const [molExtendedAccessUsers, setMolExtendedAccessUsers] = useState(cookies.molExtendedAccessUsers || false);
  const router = useRouter();
  const { query } = router;
  const {
    start_mol_extended_access_users: startMolExtendedAccessUsers,
    stop_mol_extended_access_users: stopMolExtendedAccessUsers,
    start_mol_early_access_users: startMolEarlyAccessUsers,
    stop_mol_early_access_users: stopMolEarlyAccessUsers,
  } = query;

  useEffect(() => {
    if (startMolEarlyAccessUsers) {
      setCookie(null, 'molEarlyAccessUsers', true, {
        maxAge: 60 * 60 * 24,
        path: '/',
      });
      setMolEarlyAccessUsers(true);
      delete router.query.start_mol_early_access_users;
      router.push({ pathname: router.pathname, query: router.query });
    }

    if (stopMolEarlyAccessUsers) {
      destroyCookie(null, 'molEarlyAccessUsers');
      setMolEarlyAccessUsers(false);
      delete router.query.stop_mol_early_access_users;
      router.push({ pathname: router.pathname, query: router.query });
    }
  }, [router, startMolEarlyAccessUsers, stopMolEarlyAccessUsers]);

  useEffect(() => {
    if (startMolExtendedAccessUsers) {
      setCookie(null, 'molExtendedAccessUsers', true, {
        maxAge: 60 * 60 * 24,
        path: '/',
      });
      setMolExtendedAccessUsers(true);
      delete router.query.start_mol_extended_access_users;
      router.push({ pathname: router.pathname, query: router.query });
    }

    if (stopMolExtendedAccessUsers) {
      destroyCookie(null, 'molExtendedAccessUsers');
      setMolExtendedAccessUsers(false);
      delete router.query.stop_mol_extended_access_users;
      router.push({ pathname: router.pathname, query: router.query });
    }
  }, [router, startMolExtendedAccessUsers, stopMolExtendedAccessUsers]);

  return { molEarlyAccessUsers, molExtendedAccessUsers };
};

export default useMolFeatureFlags;
