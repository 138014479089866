import { select, takeLatest, call, put } from 'redux-saga/effects';
import {
  jobAlertPreferencesUpdateRequest,
  jobAlertPreferencesUpdateSuccess,
} from '../actions/jobAlertPreferencesActions';
import { errorUpdateUserProfile } from '../actions/profileActions';
import JobAlertPreferencesAPI from '../../api/JobAlertPreferencesAPI';
import { currentPathnameSelector } from '../selectors/router';

export function* jobAlertPreferencesUpdateRequestWorker({ payload }) {
  const { user, network } = payload;
  let preferences = {};

  try {
    const { locations, frequency, ...otherUserJobAlertPreferenceProps } = user;

    preferences = {
      ...otherUserJobAlertPreferenceProps,
      locationIds: locations.map(({ value }) => value),
    };

    if (frequency) {
      preferences = { ...preferences, frequency: frequency.value };
    }

    yield call(JobAlertPreferencesAPI.update, { preferences, networkId: network.id });

    yield put(jobAlertPreferencesUpdateSuccess(user));
  } catch (e) {
    const pathname = yield select(currentPathnameSelector);

    const { response } = e;
    const { data, status } = response || {};
    if (status === 422) {
      yield put(
        errorUpdateUserProfile({
          pathname,
          message: data.join(', '),
        }),
      );
    } else {
      yield put(
        errorUpdateUserProfile({
          pathname,
          message: 'There was an unexpected error.',
        }),
      );
    }
  }
}

export default function* jobAlertPreferencesUpdateRequestWatcher() {
  yield takeLatest(jobAlertPreferencesUpdateRequest().type, jobAlertPreferencesUpdateRequestWorker);
}
