import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass/styled-components';

const Paragraph = ({ node, ...props }) => (
  <Text as="p" data-testid="paragraph-decorator" mb={[3]} tx="markdown" variant="paragraph" {...props} />
);

Paragraph.propTypes = {
  node: PropTypes.object.isRequired,
};

export default Paragraph;
