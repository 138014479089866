import PropTypes from 'prop-types';

export const NetworkSchema = PropTypes.shape({
  allCompanies: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, slug: PropTypes.string }),
  ),
  scripts: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
    }),
  ),
  features: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  domain: PropTypes.string,
  employerOnboardingSuccessText: PropTypes.string,
  heroColorMask: PropTypes.string,
  label: PropTypes.string,
  legal: PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    ccpaCompliant: PropTypes.bool,
    ccpaPolicyUrl: PropTypes.string,
    dataPrivacyRegulatoryBody: PropTypes.string,
    dataPrivacyRegulatoryNumber: PropTypes.string,
  }),
  logoUrl: PropTypes.string,
  mainImageUrl: PropTypes.string,
  metaFaviconUrl: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaTwitterUsername: PropTypes.string,
  metaImageUrl: PropTypes.string,
  name: PropTypes.string,
  tabsOrder: PropTypes.string,
  tagline: PropTypes.string,
  slug: PropTypes.string,
  subtitle: PropTypes.string,
  url: PropTypes.string,
  deliveredAt: PropTypes.string,
  visibility: PropTypes.string,
  tnSettings: PropTypes.shape({
    tnCtaTitleText: PropTypes.string,
    tnCtaButtonText: PropTypes.string,
    tnCtaDescriptionText: PropTypes.string,
    tnTabIntroductionText: PropTypes.string,
    tnSignUpWelcomeText: PropTypes.string,
    tnHidden: PropTypes.bool,
    tnSignUpWelcomeTitle: PropTypes.string,
  }),
  previewUrl: PropTypes.string,
  jobsCount: PropTypes.number,
  companiesCount: PropTypes.number,
  jobBoardFilters: PropTypes.shape({
    jobFunctionFilter: PropTypes.bool.isRequired,
    stageFilter: PropTypes.bool.isRequired,
    industryTagsFilter: PropTypes.bool.isRequired,
    companySizeFilter: PropTypes.bool.isRequired,
    locationFilter: PropTypes.bool.isRequired,
  }).isRequired,
  externalPrivacyPolicyUrl: PropTypes.string,
});
