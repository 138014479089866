import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { ActionsFooter, OnboardingLayoutContent } from '@getro/rombo';
import STRINGS from '../../../constants/strings';
import USWorkAuthorizationFields from './USWorkAuthorizationFields';

const formSchema = Yup.object().shape({
  usWorkAuthorization: Yup.boolean().required(STRINGS.validation.requiredField).nullable(),
  requireSponsorshipVisa: Yup.boolean().required(STRINGS.validation.requiredField).nullable(),
});

const USWorkAuthorizationForm = ({ disableSave, initialValues, onBack, onSubmit, saveText, title }) => {
  const handleSubmit = (values) => onSubmit(values);

  return (
    <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
      {({ dirty, values, setFieldValue }) => {
        const { usWorkAuthorization, requireSponsorshipVisa } = values;

        const onChange = (e) => {
          const { target } = e;
          const { name, value } = target;

          setFieldValue(name, value === 'true');
        };

        return (
          <Form>
            <OnboardingLayoutContent title={title}>
              <USWorkAuthorizationFields
                onChange={onChange}
                requireSponsorshipVisa={requireSponsorshipVisa}
                usWorkAuthorization={usWorkAuthorization}
              />

              <ActionsFooter saveDisabled={disableSave && !dirty} saveAsSubmit saveText={saveText} onBack={onBack} />
            </OnboardingLayoutContent>
          </Form>
        );
      }}
    </Formik>
  );
};

USWorkAuthorizationForm.propTypes = {
  disableSave: PropTypes.bool,
  initialValues: PropTypes.shape({
    requireSponsorshipVisa: PropTypes.bool,
    usWorkAuthorization: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  saveText: PropTypes.string,
  title: PropTypes.string,
};

USWorkAuthorizationForm.defaultProps = {
  initialValues: {},
  disableSave: false,
  onBack: () => {},
  saveText: undefined,
  title: '',
};

export default USWorkAuthorizationForm;
