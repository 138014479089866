import { handleActions } from 'redux-actions';
import produce from 'immer';
import uniqBy from 'lodash.uniqby';
import {
  errorDiscardedJobsList,
  loadDiscardedJobsList,
  loadedDiscardedJobsList,
  loadedDiscardJob,
} from '../actions/jobs';
import { loadedLogoutRequest } from '../actions/userActions';

export const initialState = {
  jobs: [],
  page: 1,
  total: 0,
  error: null,
  isLazyLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const discardedJobs = handleActions(
  {
    [loadDiscardedJobsList]: produce((draft) => {
      draft.error = null;
      return draft;
    }),
    [loadedDiscardJob]: produce((draft, action) => {
      const { discarded } = action.payload;
      const job = { ...action.payload.job };
      if (!discarded) {
        const index = draft.jobs.findIndex((item) => item.id === job.id);
        draft.jobs.splice(index, 1);
        draft.total -= 1;
      } else if (discarded) {
        job.isDiscarded = true;
        draft.jobs = uniqBy([...draft.jobs, job], 'id');
        draft.total += 1;
      }
      return draft;
    }),
    [loadedDiscardedJobsList]: produce((draft, action) => {
      const { items, meta, page } = action.payload;
      draft.page = parseInt(page || 1, 10);
      draft.total = parseInt(meta.total, 10);
      if (items.length) {
        draft.isLazyLoading = true;
      }
      draft.jobs = uniqBy([...draft.jobs, ...items], 'id');
      return draft;
    }),
    [errorDiscardedJobsList]: produce((draft, action) => {
      draft = { ...initialState };
      draft.error = action.payload.error;
      return draft;
    }),
    [loadedLogoutRequest]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
  },
  initialState,
);

export default discardedJobs;
