/* eslint-disable camelcase */
import getConfig from 'next/config';
import { put, get, post } from './index';
import SentryAPI from './SentryAPI';
import { encryptFilters } from '../hooks/useEncryptedRouter';

const { publicRuntimeConfig } = getConfig();

class JobAlertsAPI {
  static parseResponse = (response) => {
    const { data } = response;
    const { data: jobAlert } = data;
    const { id, attributes } = jobAlert;
    return { id, ...attributes };
  };

  static async create({ networkId, email, filters }) {
    try {
      const { apiV2BaseUrl: baseURL } = publicRuntimeConfig;

      const response = await post(`/job_alerts`, { collectionId: networkId, email, filters }, { baseURL }, {});
      return JobAlertsAPI.parseResponse(response);
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('failed to create job alert', { e });
      }
      throw e;
    }
  }

  static async update({ token, filters }) {
    try {
      const { apiV2BaseUrl: baseURL } = publicRuntimeConfig;

      await put(`/job_alerts/${token}`, { filters }, { baseURL }, {});
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('failed to update job alerts', { e, token, filters });
      }
      throw e;
    }
  }

  static async load({ token }) {
    try {
      const { apiV2BaseUrl: baseURL } = publicRuntimeConfig;
      const response = await get(`/job_alerts/${token}`, { baseURL });
      return JobAlertsAPI.parseResponse(response);
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('Retrieve job alert token failed', { token, e });
      }
      throw e;
    }
  }

  static async confirm({ token }) {
    try {
      const { apiV2BaseUrl: baseURL } = publicRuntimeConfig;
      const response = await put(`/job_alerts/${token}/confirm`, {}, { baseURL });
      return JobAlertsAPI.parseResponse(response);
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('Confirm job alert failed', { token, e });
      }
      throw e;
    }
  }

  static async unsubscribe({ token }) {
    try {
      const { apiV2BaseUrl: baseURL } = publicRuntimeConfig;
      await put(`/job_alerts/${token}/unsubscribe`, {}, { baseURL });
      return true;
    } catch (e) {
      const { response } = e;
      if (response) {
        if (response.status === 422) return false;
        SentryAPI.trackError('Unsubscribe from job alert failed', { token, e });
      }
      throw e;
    }
  }

  static filtersToQuery(jobAlert) {
    const { filters } = jobAlert;
    const {
      query: q,
      organizationIds,
      locations,
      jobFunctions,
      headCount,
      industryTags,
      stage,
      customFilters,
    } = filters;
    return {
      q,
      job_functions: jobFunctions,
      'organization.id': organizationIds,
      searchable_locations: locations,
      headCount,
      industryTags,
      stage,
      ...customFilters,
    };
  }

  static queryToFilters(filters) {
    const {
      q: query,
      job_functions: jobFunctions,
      'organization.id': organizationIds,
      searchable_locations: locations,
      industry_tags: industryTags,
      stage,
      head_count: headCount,
      'organization.head_count': organizationHeadCount,
      'organization.stage': organizationStage,
      'organization.industry_tags': organizationIndustryTags,
      ...customFilters
    } = filters;

    return {
      query,
      jobFunctions,
      organizationIds: organizationIds?.map((id) => parseInt(id, 10)),
      locations,
      industryTags: industryTags || organizationIndustryTags,
      stage: stage || organizationStage,
      headCount: headCount || organizationHeadCount,
      customFilters,
    };
  }

  static filtersToEncryptedQuery(jobAlert) {
    return encryptFilters(JobAlertsAPI.filtersToQuery(jobAlert));
  }
}

export default JobAlertsAPI;
