import { createActions } from 'redux-actions';

export const { loginRequest, loginSuccessRequest, loginFailureRequest, loginSsoError } = createActions(
  'LOGIN_REQUEST',
  'LOGIN_SUCCESS_REQUEST',
  'LOGIN_FAILURE_REQUEST',
  'LOGIN_SSO_ERROR',
);

export const { loadLogoutRequest, loadedLogoutRequest } = createActions('LOAD_LOGOUT_REQUEST', 'LOADED_LOGOUT_REQUEST');

export const { userTokenAuthenticationRequest } = createActions('USER_TOKEN_AUTHENTICATION_REQUEST');

export const { toggleSignUpModal } = createActions('TOGGLE_SIGN_UP_MODAL');
