import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { ActionsFooter, CheckBoxes, RadioButtons, OnboardingLayoutContent } from '@getro/rombo';
import { JOB_SEARCH_STATUS_VALUES, EMPLOYMENT_TYPES, SENIORITIES } from '../../../constants/index';
import STRINGS from '../../../constants/strings';

const formSchema = Yup.object().shape({
  employmentTypes: Yup.array(Yup.string()).required(STRINGS.validation.selectAtLeastOne),
  jobSearchStatus: Yup.string().required(STRINGS.validation.requiredField).nullable(),
  seniority: Yup.string().required(STRINGS.validation.requiredField).nullable(),
});

const CareerInformationForm = ({
  disableSave,
  initialValues,
  onSubmit,
  onBack,
  saveText,
  title,
  errorMessage,
  updating,
}) => {
  // Remove job_level_unspecified value because it's not shown in the form
  const preparedInitialValues = {
    ...initialValues,
    seniority: initialValues.seniority === 'job_level_unspecified' ? null : initialValues.seniority,
  };

  return (
    <Formik initialValues={preparedInitialValues} validationSchema={formSchema} onSubmit={onSubmit} enableReinitialize>
      {({ dirty, values, setFieldValue }) => {
        const { jobSearchStatus, employmentTypes, seniority } = values;

        const onChange = (e) => {
          const { target, name, value } = e;
          if (target) {
            setFieldValue(target.name, target.value);
            return;
          }

          setFieldValue(name, value);
        };

        return (
          <Form>
            <OnboardingLayoutContent title={title}>
              <Box
                mx="auto"
                my={4}
                sx={{
                  '&:first-child': {
                    marginTop: 0,
                  },
                }}
              >
                <Field>
                  {(data) => (
                    <RadioButtons
                      name="jobSearchStatus"
                      label="Where are you in your job search?"
                      elements={JOB_SEARCH_STATUS_VALUES}
                      checkedValue={jobSearchStatus}
                      onChange={onChange}
                      size="md"
                      {...data}
                    />
                  )}
                </Field>
              </Box>

              <Box
                mx="auto"
                my={4}
                sx={{
                  '&:first-child': {
                    marginTop: 0,
                  },
                }}
              >
                <Field>
                  {(data) => (
                    <Box
                      sx={{
                        '& > label': {
                          display: 'flex',
                        },
                      }}
                    >
                      <CheckBoxes
                        elements={EMPLOYMENT_TYPES}
                        name="employmentTypes"
                        label="What types of jobs are you open to?"
                        onChange={onChange}
                        checkedValues={employmentTypes}
                        {...data}
                      />
                    </Box>
                  )}
                </Field>
              </Box>

              <Box
                mx="auto"
                my={4}
                sx={{
                  '&:first-child': {
                    marginTop: 0,
                  },
                }}
              >
                <Field>
                  {(data) => (
                    <RadioButtons
                      name="seniority"
                      label="What’s your level of work experience?"
                      elements={SENIORITIES}
                      checkedValue={seniority}
                      onChange={onChange}
                      size="md"
                      {...data}
                    />
                  )}
                </Field>
              </Box>
              <ActionsFooter
                saveDisabled={disableSave && !dirty}
                saveAsSubmit
                saveText={saveText}
                onBack={onBack}
                errorMessage={errorMessage}
                loading={updating}
              />
            </OnboardingLayoutContent>
          </Form>
        );
      }}
    </Formik>
  );
};

CareerInformationForm.propTypes = {
  disableSave: PropTypes.bool,
  initialValues: PropTypes.shape({
    employmentTypes: PropTypes.arrayOf(PropTypes.string),
    jobSearchStatus: PropTypes.string,
    seniority: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  saveText: PropTypes.string,
  title: PropTypes.string,
  errorMessage: PropTypes.node,
  updating: PropTypes.bool,
};

CareerInformationForm.defaultProps = {
  disableSave: false,
  errorMessage: null,
  initialValues: {},
  onBack: () => {},
  saveText: undefined,
  title: '',
  updating: false,
};

export default CareerInformationForm;
