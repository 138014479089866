import { get } from './index';
import SentryAPI from './SentryAPI';

class SkillsAPI {
  static async search(q) {
    try {
      const response = await get('/skills', {
        params: {
          q,
        },
      });

      const { data } = response;
      const { items } = data;
      return items.map((skill) => {
        const { id: value, name: label } = skill;
        return { value, label };
      });
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('Search skills', { q, e });
      }
      throw e;
    }
  }
}

export default SkillsAPI;
