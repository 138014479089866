import { parseCookies, setCookie } from 'nookies';
import getConfig from 'next/config';
import isbot from 'isbot';
import UserAPI from '../api/UserAPI';
import { rehydrateLocationsDetails } from './userDataRequestHelper';
import { loginSuccessRequest } from '../redux/actions/userActions';
import SentryAPI from '../api/SentryAPI';

export const initializeUser = (store) => async (ctx) => {
  const { query, req } = ctx;
  const { token } = query;
  const { network } = req;
  const cookies = parseCookies(ctx);
  if (token || cookies.token) {
    const currentToken = token || cookies.token;
    if (!cookies.token || cookies.token !== currentToken) {
      setCookie(ctx, 'token', currentToken, {
        maxAge: 60 * 60 * 24,
        path: '/',
      });
    }
    const userData = await UserAPI.me(currentToken);
    userData.userTalentCollection = userData.userTalentCollection || {};
    userData.locations = await rehydrateLocationsDetails(userData);
    store.dispatch(
      loginSuccessRequest({
        network,
        user: { ...userData, authenticationToken: currentToken },
      }),
    );
  }
};

export const getSSRProtocolAndHost = (ctx) => {
  const { req } = ctx;
  const { connection, headers } = req;
  const { encrypted } = connection;
  const { host } = headers;
  const protocol = encrypted ? 'https:' : 'http:';

  return { host, protocol };
};

export const talentNetworkRouteGuard = (hasTalentNetwork) => {
  if (hasTalentNetwork) {
    return null;
  }

  return { notFound: true };
};

export const detectNetworkMismatch = ({ network = {}, req }) => {
  const { serverRuntimeConfig } = getConfig();
  const { nextHostname } = serverRuntimeConfig;
  let hostname = req.headers.host.split(':')[0];
  if (nextHostname) {
    hostname = nextHostname;
  }
  if (req.headers['getro-network']) {
    hostname = req.headers['getro-network'].split(':')[0];
  }

  if (!network?.id) return;
  if (!hostname) return;
  if (network.url === hostname) return;
  if (network.previewUrl === hostname) return;

  SentryAPI.trackError('Network mismatch', {
    _href: req.asPath,
    _isBot: isbot(req.headers['user-agent']),
    hostname,
    networkDomain: network.domain,
  });
};
