import { handleActions } from 'redux-actions';
import produce from 'immer';
import { initScriptAdvancedDesign } from '../actions/advancedDesign';
import { hydrate } from '../actions/hydrate';

export const initialState = {
  initialized: false,
  isScriptLoaded: false,
};

/* eslint-disable default-case, no-param-reassign */
const advancedDesignReducer = handleActions(
  {
    [initScriptAdvancedDesign]: produce((draft, action) => {
      draft.isScriptLoaded = action.payload.init;
      return draft;
    }),
    [hydrate]: produce((draft, action) => {
      const { advancedDesign } = action.payload;
      draft = advancedDesign;
      return draft;
    }),
  },
  initialState,
);

export default advancedDesignReducer;
