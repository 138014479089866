import { call, put, takeLatest } from 'redux-saga/effects';
import { errorCustomFilters, loadCustomFilters, loadedCustomFilters } from '../actions/customFilterActions';
import NetworkAPI from '../../api/NetworkAPI';

export function* customFiltersRequest({ payload }) {
  const { useOrganizationPrefix, network } = payload;
  try {
    const response = yield call(NetworkAPI.customFilters, { useOrganizationPrefix, networkId: network.id });
    yield put(loadedCustomFilters({ filters: response }));
  } catch (e) {
    yield put(errorCustomFilters());
  }
}

export default function* customFiltersWatcher() {
  yield takeLatest(loadCustomFilters().type, customFiltersRequest);
}
