/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import { Flex } from 'rebass/styled-components';
import { Plus } from 'lucide-react';

import { Button } from '@getro/rombo';

const { publicRuntimeConfig } = getConfig();

const PickerOverlay = dynamic(() => import('filestack-react').then((mod) => mod.PickerOverlay), { ssr: false });

const UploadImage = ({ className, currentValue, imageType, onClear, onError, onSuccess, size, ...rest }) => {
  const [showPicker, setShowPicker] = useState(false);
  const DEFAULT_OPTIONS = {
    accept: ['image/*'],
    fromSources: ['local_file_system', 'url'],
    onCancel: () => {
      setShowPicker(false);
    },
    onClose: () => {
      setShowPicker(false);
    },
  };
  let options = DEFAULT_OPTIONS;

  switch (imageType) {
    case 'favicon':
      options = {
        ...options,
        transformations: {
          crop: { aspectRatio: 1, force: true },
        },
        imageMin: [16, 16],
        imageMax: [512, 512],
      };
      break;
    case 'logo':
      options = {
        ...options,
        transformations: {
          crop: { aspectRatio: 1, force: true },
        },
        imageMin: [200, 200],
        imageMax: [900, 900],
      };
      break;
    case 'profile':
      options = {
        ...options,
        transformations: {
          crop: { aspectRatio: 1, force: true },
        },
        imageMin: [200, 200],
        imageMax: [900, 900],
      };
      break;
    case 'hero':
      options = {
        ...options,
        transformations: {
          crop: { aspectRatio: 2, force: true },
        },
        imageMax: [2400, 1200],
      };
      break;
    default:
  }

  const { fileStackApiKey: apiKey } = publicRuntimeConfig;

  if (!apiKey) {
    return null;
  }

  return (
    <Flex data-testid="upload-image" alignItems="center" sx={{ gap: '16px' }} {...rest}>
      {currentValue && (
        <Button type="button" variant="tertiary" onClick={onClear}>
          Remove
        </Button>
      )}
      <Button type="button" icon={currentValue ? null : Plus} variant="tertiary" onClick={() => setShowPicker(true)}>
        {currentValue ? 'Change' : 'Add image'}
      </Button>

      {showPicker && <PickerOverlay apikey={apiKey} onSuccess={onSuccess} onError={onError} pickerOptions={options} />}
    </Flex>
  );
};

UploadImage.propTypes = {
  className: PropTypes.string,
  currentValue: PropTypes.string,
  imageType: PropTypes.oneOf(['favicon', 'logo', 'hero', 'profile']).isRequired,
  onClear: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
};

UploadImage.defaultProps = {
  className: '',
  currentValue: null,
  onClear: () => {},
  onError: () => {},
  onSuccess: () => {},
  size: 'medium',
};

export default UploadImage;
