import { createActions } from 'redux-actions';

export const { loadAcceptIntroduction, loadedAcceptIntroduction, errorAcceptIntroduction } = createActions(
  'LOAD_ACCEPT_INTRODUCTION',
  'LOADED_ACCEPT_INTRODUCTION',
  'ERROR_ACCEPT_INTRODUCTION',
);

export const { loadRejectIntroduction, loadedRejectIntroduction, errorRejectIntroduction } = createActions(
  'LOAD_REJECT_INTRODUCTION',
  'LOADED_REJECT_INTRODUCTION',
  'ERROR_REJECT_INTRODUCTION',
);
