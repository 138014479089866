import { call, put, debounce } from 'redux-saga/effects';
import {
  locationSuggestionsFailure,
  locationSuggestionsRequest,
  locationSuggestionsSuccess,
} from '../actions/locationSuggestions';
import SearchClient from '../../api/SearchClient';

export function* locationsSuggestionRequestEvent({ payload }) {
  const { network, company, term, origin } = payload;
  try {
    const results = yield call(SearchClient.findLocationSuggestions, { network, company, term, origin });
    yield put(locationSuggestionsSuccess({ suggestions: results, term }));
  } catch (e) {
    yield put(locationSuggestionsFailure(e));
  }
}

export default function* fetchLocationSuggestions() {
  yield debounce(500, locationSuggestionsRequest().type, locationsSuggestionRequestEvent);
}
