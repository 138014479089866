import createLucideIcon from '../createLucideIcon';

const FileText = createLucideIcon('FileText', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['line', { x1: '16', x2: '8', y1: '13', y2: '13', key: '14keom' }],
  ['line', { x1: '16', x2: '8', y1: '17', y2: '17', key: '17nazh' }],
  ['line', { x1: '10', x2: '8', y1: '9', y2: '9', key: '1a5vjj' }],
]);

export default FileText;
