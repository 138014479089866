import { handleActions } from 'redux-actions';
import produce from 'immer';
import uniqBy from 'lodash.uniqby';
import { loadedLogoutRequest } from '../actions/userActions';
import {
  errorDiscardedCompaniesList,
  loadDiscardedCompaniesList,
  loadedDiscardCompany,
  loadedDiscardedCompaniesList,
} from '../actions/companies';

export const initialState = {
  companies: [],
  page: 1,
  total: 0, // todo: remove total
  error: null,
  isLazyLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const discardedCompanies = handleActions(
  {
    [loadDiscardedCompaniesList]: produce((draft) => {
      draft.error = null;
      return draft;
    }),
    [loadedDiscardCompany]: produce((draft, action) => {
      const { discarded } = action.payload;
      const company = { ...action.payload.company };
      if (!discarded) {
        const index = draft.companies.findIndex((item) => item.id === company.id);
        draft.companies.splice(index, 1);
        draft.total -= 1;
      } else if (discarded) {
        company.isDiscarded = true;
        draft.companies = uniqBy([...draft.companies, company], 'id');
        draft.total += 1;
      }
      return draft;
    }),
    [loadedDiscardedCompaniesList]: produce((draft, action) => {
      const { items, page } = action.payload;
      draft.page = parseInt(page || 1, 10);
      if (items.length) {
        draft.isLazyLoading = true;
      }
      draft.companies = uniqBy([...draft.companies, ...items], 'id');
      draft.total = draft.companies.length;
      return draft;
    }),
    [errorDiscardedCompaniesList]: produce((draft, action) => {
      draft = { ...initialState };
      draft.error = action.payload.error;
      return draft;
    }),
    [loadedLogoutRequest]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
  },
  initialState,
);

export default discardedCompanies;
