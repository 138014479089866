import { all, fork } from 'redux-saga/effects';
import networkInformationRequest from './networkInformationRequest';
import moveOnboardingForwardRequestSaga from './moveOnboardingForwardRequest';
import moveOnboardingBackwardRequestSaga from './moveOnboardingBackwardRequest';
import submitOnboardingRequest from './submitOnboardingRequest';
import jobsInitializeRequest from './jobsRequest';
import profileUpdateRequest from './profileUpdateRequest';
import jobAlertPreferencesUpdateRequestWatcher from './jobAlertPreferencesUpdateRequest';
import fetchLocationSuggestions from './locationSuggestions';
import companiesRequest from './companiesRequest';
import loginRequestWatcher from './loginSaga';
import analyticsWatcher from './analytics';
import passwordWatcher from './passwordSaga';
import accountSettingsWatcher from './accountSettingsSaga';
import loadIntroductionsWatcher from './requestIntroductionSagas';
import loadCreateIntroductionRequestWatcher from './introductionsSagas';
import userTokenAuthenticationRequestWatcher from './userTokenAuthenticationRequest';
import customFiltersWatcher from './customFiltersRequest';
import jobFunctionsWatcher from './jobFunctionsRequest';
import jobAlertWatcher from './jobAlertSagas';
import jobApplicationWatcher from './jobApplicationSaga';
import filterWatcher from './filterSaga';

export default function* rootSaga() {
  yield all([
    fork(companiesRequest),
    fork(fetchLocationSuggestions),
    fork(networkInformationRequest),
    fork(moveOnboardingForwardRequestSaga),
    fork(moveOnboardingBackwardRequestSaga),
    fork(submitOnboardingRequest),
    fork(jobsInitializeRequest),
    fork(passwordWatcher),
    fork(accountSettingsWatcher),
    fork(profileUpdateRequest),
    fork(jobAlertPreferencesUpdateRequestWatcher),
    fork(loginRequestWatcher),
    fork(analyticsWatcher),
    fork(loadCreateIntroductionRequestWatcher),
    fork(loadIntroductionsWatcher),
    fork(userTokenAuthenticationRequestWatcher),
    fork(customFiltersWatcher),
    fork(jobFunctionsWatcher),
    fork(jobAlertWatcher),
    fork(jobApplicationWatcher),
    fork(filterWatcher),
  ]);
}
