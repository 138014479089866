import { createActions } from 'redux-actions';

export const { toggleIntroductionRequestModal } = createActions('TOGGLE_INTRODUCTION_REQUEST_MODAL');

export const { loadCreateIntroductionRequest, loadedCreateIntroductionRequest, errorCreateIntroductionRequest } =
  createActions(
    'LOAD_CREATE_INTRODUCTION_REQUEST',
    'LOADED_CREATE_INTRODUCTION_REQUEST',
    'ERROR_CREATE_INTRODUCTION_REQUEST',
  );

export const {
  loadUpdateIntroductionsRemaining,
  loadedUpdateIntroductionsRemaining,
  errorUpdateIntroductionsRemaining,
} = createActions(
  'LOAD_UPDATE_INTRODUCTIONS_REMAINING',
  'LOADED_UPDATE_INTRODUCTIONS_REMAINING',
  'ERROR_UPDATE_INTRODUCTIONS_REMAINING',
);

export const { loadUpdateLinkedInUrl, loadedUpdateLinkedInUrl, errorUpdateLinkedInUrl } = createActions(
  'LOAD_UPDATE_LINKED_IN_URL',
  'LOADED_UPDATE_LINKED_IN_URL',
  'ERROR_UPDATE_LINKED_IN_URL',
);
