import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Search } from '@getro/rombo';
import { checkArrayHasDuplicatedElements } from '../../../helpers/arrayHelpers';
import { hasError } from '../../../helpers/formHelpers';

import GooglePlacesAPI from '../../../api/GooglePlacesAPI';

const SearchLocation = ({ disabled, label, name, onChange, value, form, placeholder, ...rest }) => {
  const onSearchLocation = async (query) => {
    if (!query.length) {
      return [];
    }

    return GooglePlacesAPI.searchGoogle(query);
  };
  const error = hasError(name, form);
  const className = useMemo(() => (error ? 'field error' : 'field'), [error]);

  const onLocationSelected = (e) => {
    const { target } = e;
    const { value: newValue } = target;

    if (Array.isArray(newValue)) {
      if (checkArrayHasDuplicatedElements(newValue)) return;
    }

    onChange(e);
  };

  return (
    <div className={className}>
      <Search
        {...rest}
        data-testid="search-locations"
        disabled={disabled}
        name={name}
        orientation="horizontal"
        value={value}
        onChange={onLocationSelected}
        onSearch={onSearchLocation}
        form={form}
        label={label}
        placeholder={placeholder}
      />
    </div>
  );
};

SearchLocation.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ]),
  placeholder: PropTypes.string,
};

SearchLocation.defaultProps = {
  disabled: false,
  label: null,
  multiple: false,
  value: null,
  form: null,
  placeholder: 'Start typing...',
};

export default SearchLocation;
