import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  loginFailureRequest,
  loginRequest,
  loginSuccessRequest,
  loadedLogoutRequest,
  toggleSignUpModal,
  userTokenAuthenticationRequest,
} from '../actions/userActions';
import initialValues from '../../containers/onboarding/initialValues';
import { JOB_ALERT_FREQUENCIES, PROFILE_VISIBILITIES } from '../../constants';
import { jobAlertPreferencesUpdateSuccess } from '../actions/jobAlertPreferencesActions';
import {
  loadedUpdateUserProfile,
  loadedUpdateUserProfileVisibility,
  loadedUpdateWorkPreferences,
} from '../actions/profileActions';
import { loadedCreateIntroductionRequest, loadedUpdateLinkedInUrl } from '../actions/introductionRequestActions';
import { loadedDiscardJob, loadedFavoriteJob } from '../actions/jobs';
import { loadedApiPasswordReset } from '../actions/passwordActions';
import { loadedDiscardCompany, loadedFavoriteCompany } from '../actions/companies';

export const initialState = {
  organizationLikeIds: [],
  organizationDiscardIds: [],
  jobLikeIds: [],
  jobDiscardIds: [],
  loading: false,
  loaded: false,
  showSignUpModal: false,
};

const extractLocationFrom = (locationData) => {
  if (!locationData) return null;

  const { placeId: value, name: label } = locationData;
  return { value, label };
};

const mapElementsForSelect = (elements) => {
  if (!elements) return [];

  return elements.map((element) => {
    const { id: value, name: label } = element;

    return { value, label };
  });
};

const mapLocationsForSelect = (elements) => {
  if (!elements) return [];

  return elements.map((element) => {
    const { placeId: value, name: label, country } = element;

    return { value, label, country };
  });
};

const rehydrate = (user) => {
  const { userJobAlertPreference = {} } = user;
  const { locations = [], frequency, ...otherUserJobAlertPreferenceProps } = userJobAlertPreference || {};
  return {
    ...initialValues,
    ...user,
    currentLocation: extractLocationFrom(user.currentLocation),
    skills: mapElementsForSelect(user.skills),
    visibility: PROFILE_VISIBILITIES.find((v) => v.value === user.visibility) || PROFILE_VISIBILITIES[0],
    locations: mapLocationsForSelect(user.locations),
    userJobAlertPreference: {
      ...otherUserJobAlertPreferenceProps,
      locations: mapLocationsForSelect(locations),
      frequency: JOB_ALERT_FREQUENCIES.find(({ value }) => value === frequency) || null,
    },
  };
};

/* eslint-disable default-case, no-param-reassign */
const userReducer = handleActions(
  {
    [loadedFavoriteCompany]: produce((draft, action) => {
      const { company, favorite } = action.payload;
      if (favorite) {
        draft.organizationLikeIds.push(company.id);
      } else {
        const index = draft.organizationLikeIds.indexOf(company.id);
        draft.organizationLikeIds.splice(index, 1);
      }
      return draft;
    }),
    [loadedDiscardCompany]: produce((draft, action) => {
      const { company, discarded } = action.payload;
      if (discarded) {
        draft.organizationDiscardIds.push(company.id);
      } else {
        const index = draft.organizationDiscardIds.indexOf(company.id);
        draft.organizationDiscardIds.splice(index, 1);
      }
      return draft;
    }),
    [loadedFavoriteJob]: produce((draft, action) => {
      const { job, favorite } = action.payload;
      if (favorite) {
        draft.jobLikeIds.push(job.id);
      } else {
        const index = draft.jobLikeIds.indexOf(job.id);
        draft.jobLikeIds.splice(index, 1);
      }
      return draft;
    }),
    [loadedDiscardJob]: produce((draft, action) => {
      const { job, discarded } = action.payload;
      if (discarded) {
        draft.jobDiscardIds.push(job.id);
      } else {
        const index = draft.jobDiscardIds.indexOf(job.id);
        draft.jobDiscardIds.splice(index, 1);
      }
      return draft;
    }),
    [toggleSignUpModal]: produce((draft, action) => {
      if (action.payload && Object.prototype.hasOwnProperty.call(action.payload, 'showModal')) {
        draft.showSignUpModal = action.payload.showModal;
      } else {
        draft.showSignUpModal = !draft.showSignUpModal;
      }
      return draft;
    }),
    [jobAlertPreferencesUpdateSuccess]: produce((draft, action) => {
      const { mailTnTlJobAlerts, ...otherProps } = action.payload;
      draft.mailTnTlJobAlerts = mailTnTlJobAlerts;
      draft.userJobAlertPreference = {
        ...otherProps,
      };
      return draft;
    }),
    [loadedUpdateUserProfile]: produce((draft, action) => {
      const { user } = action.payload;
      draft = rehydrate({ ...draft, ...user });
      return draft;
    }),
    [loadedUpdateWorkPreferences]: produce((draft, action) => {
      const { user } = action.payload;
      draft = rehydrate({ ...draft, ...user });
      return draft;
    }),
    [loadedUpdateUserProfileVisibility]: produce((draft, action) => {
      const { user } = action.payload;
      draft = rehydrate({ ...draft, ...user });
      return draft;
    }),
    [loginRequest]: produce((draft) => {
      draft.loading = true;
      return draft;
    }),
    [userTokenAuthenticationRequest]: produce((draft) => {
      draft.loading = true;
      return draft;
    }),
    [loginSuccessRequest]: produce((draft, action) => {
      const { payload } = action;
      draft = rehydrate(payload.user);
      draft.loading = false;
      draft.loaded = true;
      draft.showSignUpModal = false;
      return draft;
    }),
    [loadedApiPasswordReset]: produce((draft) => {
      draft.forcePasswordUpdate = false;
      return draft;
    }),
    [loginFailureRequest]: produce((draft, action) => {
      const { payload } = action;
      const { response } = payload || {};
      const { status, data } = response || {};
      const { error } = data || {};

      draft = {
        ...initialState,
        loaded: true,
        loading: false,
      };

      if (status !== undefined) {
        draft.status = status;
      }

      if (error !== undefined) {
        draft.error = error;
      }

      return draft;
    }),
    [loadedLogoutRequest]: produce((draft) => {
      draft = { ...initialState };
      return draft;
    }),
    [loadedCreateIntroductionRequest]: produce((draft, action) => {
      const { payload } = action;
      draft.introductionRequests = [...draft.introductionRequests, payload];
      return draft;
    }),
    [loadedUpdateLinkedInUrl]: produce((draft, action) => {
      const { payload } = action;
      const { linkedinUrl } = payload;
      draft.linkedinUrl = linkedinUrl;
      return draft;
    }),
  },
  initialState,
);

export default userReducer;
