import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  loadedAcceptIntroduction,
  loadAcceptIntroduction,
  errorAcceptIntroduction,
  loadRejectIntroduction,
  loadedRejectIntroduction,
  errorRejectIntroduction,
} from '../actions/introductionsActions';

export const initialState = {
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = handleActions(
  {
    [loadAcceptIntroduction]: produce((draft, action) => {
      const {
        payload: { id },
      } = action;
      draft[id] = { error: false };
      return draft;
    }),
    [loadedAcceptIntroduction]: produce((draft, action) => {
      const {
        payload: { introduction },
      } = action;
      draft[introduction.id] = introduction;
      draft[introduction.id].error = false;
      return draft;
    }),
    [errorAcceptIntroduction]: produce((draft, action) => {
      const { payload: id } = action;
      draft[id] = { error: true };
      return draft;
    }),
    [loadRejectIntroduction]: produce((draft, action) => {
      const {
        payload: { id },
      } = action;
      draft[id] = { error: false };
      return draft;
    }),
    [loadedRejectIntroduction]: produce((draft, action) => {
      const {
        payload: { introduction },
      } = action;
      draft[introduction.id] = introduction;
      draft[introduction.id].error = false;
      return draft;
    }),
    [errorRejectIntroduction]: produce((draft, action) => {
      const { payload: id } = action;
      draft[id] = { error: true };
      return draft;
    }),
  },
  initialState,
);

export default reducer;
