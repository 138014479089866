import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

const ProfileSectionTitle = ({ children, ...rebassProps }) => (
  <Box
    fontWeight="medium"
    mx="auto"
    mt={4}
    mb={3}
    sx={{
      fontSize: 3,
      lineHeight: '1.2',
      '&:first-child': {
        marginTop: 0,
      },
    }}
    {...rebassProps}
  >
    {children}
  </Box>
);

ProfileSectionTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ProfileSectionTitle;
