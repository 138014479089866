import { createActions } from 'redux-actions';

export const { loadUpdateUserProfile, loadedUpdateUserProfile, errorUpdateUserProfile } = createActions(
  'LOAD_UPDATE_USER_PROFILE',
  'LOADED_UPDATE_USER_PROFILE',
  'ERROR_UPDATE_USER_PROFILE',
);

export const { loadUpdateUserProfileVisibility, loadedUpdateUserProfileVisibility, errorUpdateUserProfileVisibility } =
  createActions(
    'LOAD_UPDATE_USER_PROFILE_VISIBILITY',
    'LOADED_UPDATE_USER_PROFILE_VISIBILITY',
    'ERROR_UPDATE_USER_PROFILE_VISIBILITY',
  );

export const { loadUpdateWorkPreferences, loadedUpdateWorkPreferences, errorUpdateWorkPreferences } = createActions(
  'LOAD_UPDATE_WORK_PREFERENCES',
  'LOADED_UPDATE_WORK_PREFERENCES',
  'ERROR_UPDATE_WORK_PREFERENCES',
);
