import { takeLatest, select, put, call } from 'redux-saga/effects';
import {
  loadCreateIntroductionRequest,
  loadedCreateIntroductionRequest,
  errorCreateIntroductionRequest,
  loadUpdateIntroductionsRemaining,
  loadedUpdateIntroductionsRemaining,
  errorUpdateIntroductionsRemaining,
  loadUpdateLinkedInUrl,
  loadedUpdateLinkedInUrl,
  errorUpdateLinkedInUrl,
} from '../actions/introductionRequestActions';
import {
  makeSelectIntroductionRequestOrganization,
  makeSelectIntroductionRequestsRemaining,
  makeSelectIntroductionRequestsLimit,
} from '../selectors/introductionRequest';
import { get, post } from '../../api';
import SentryAPI from '../../api/SentryAPI';
import UserAPI from '../../api/UserAPI';
import { makeFeatureSelector } from '../selectors/network';

export function* loadIntroductionRequestWorker({ payload }) {
  const { network, coverLetter } = payload;
  const hasIntroductionsLimit = makeFeatureSelector('introductions_limit', network);
  const organization = yield select(makeSelectIntroductionRequestOrganization);
  const params = {
    organizationId: organization.id,
    customMessage: coverLetter,
    collectionId: network.id,
  };

  try {
    yield call(post, '/introduction_requests', params);
    const remaining = yield select(makeSelectIntroductionRequestsRemaining);
    const limit = yield select(makeSelectIntroductionRequestsLimit);

    yield put(
      loadedCreateIntroductionRequest({
        introducibleId: organization.id,
        introducibleType: 'Organization',
        status: 'pending',
        networkId: network.id,
        name: organization.name,
        limit,
        remaining,
        hasIntroductionsLimit,
        network,
      }),
    );
  } catch (e) {
    const { response } = e;
    const { data: serverResponse } = response || {};

    yield call(SentryAPI.trackError, 'Create Introduction failed', {
      sentParams: params,
      serverResponse,
    });

    yield put(errorCreateIntroductionRequest());
  }
}

export function* loadUpdateIntroductionsRemainingWorker({ payload }) {
  const { network } = payload;
  const params = {
    collection_id: network.id,
  };

  try {
    const result = yield call(get, '/introduction_requests/remainder', { params });
    const { data } = result;
    yield put(loadedUpdateIntroductionsRemaining({ data, network }));
  } catch (e) {
    const { response } = e;
    const { data: serverResponse } = response || {};

    yield call(SentryAPI.trackError, 'retrieving introduction requests remainder failed', {
      sentParams: params,
      serverResponse,
    });

    yield put(errorUpdateIntroductionsRemaining());
  }
}

export function* loadUpdateLinkedInUrlWorker(action) {
  const { payload } = action;
  const { url: linkedinUrl } = payload;

  try {
    yield call(UserAPI.update, { linkedinUrl });
    yield put(loadedUpdateLinkedInUrl({ linkedinUrl }));
  } catch (e) {
    yield put(errorUpdateLinkedInUrl());
  }
}

export default function* loadCreateIntroductionRequestWatcher() {
  yield takeLatest(loadCreateIntroductionRequest().type, loadIntroductionRequestWorker);
  yield takeLatest(loadUpdateIntroductionsRemaining().type, loadUpdateIntroductionsRemainingWorker);
  yield takeLatest(loadUpdateLinkedInUrl().type, loadUpdateLinkedInUrlWorker);
}
