import { select, takeLatest, call, put } from 'redux-saga/effects';
import {
  loadUpdateUserProfile,
  loadedUpdateUserProfile,
  errorUpdateUserProfile,
  loadUpdateUserProfileVisibility,
  loadedUpdateUserProfileVisibility,
  errorUpdateUserProfileVisibility,
  loadUpdateWorkPreferences,
  loadedUpdateWorkPreferences,
  errorUpdateWorkPreferences,
} from '../actions/profileActions';
import prepareRequestData, { rehydrateLocationsDetails } from '../../helpers/userDataRequestHelper';
import UserAPI from '../../api/UserAPI';
import SentryAPI from '../../api/SentryAPI';
import { request } from '../../api';
import { currentPathnameSelector } from '../selectors/router';

export function* profileUpdateRequestWorker({ payload }) {
  const { updatedUser, network } = payload;
  const { id: networkId } = network;

  const pathname = yield select(currentPathnameSelector);
  const result = yield call(prepareRequestData, updatedUser, networkId);

  try {
    const user = yield call(UserAPI.update, result);
    user.locations = yield call(rehydrateLocationsDetails, user);

    yield put(
      loadedUpdateUserProfile({
        pathname,
        user,
      }),
    );
  } catch (e) {
    const { response } = e;
    const { data, status } = response || {};
    if (status === 422) {
      yield put(
        errorUpdateUserProfile({
          pathname,
          message: data.join(', '),
        }),
      );
    } else {
      yield put(
        errorUpdateUserProfile({
          pathname,
          message: 'There was an unexpected error.',
        }),
      );
    }
  }
}

export function* profileUpdateWorkPreferencesWorker({ payload }) {
  const { updatedUser, network } = payload;
  const pathname = yield select(currentPathnameSelector);
  const result = yield call(prepareRequestData, updatedUser, network.id);

  try {
    const user = yield call(UserAPI.update, result);
    user.locations = yield call(rehydrateLocationsDetails, user);

    yield put(
      loadedUpdateWorkPreferences({
        pathname,
        user,
      }),
    );
  } catch (e) {
    const { response } = e;
    const { data, status } = response || {};
    if (status === 422) {
      yield put(
        errorUpdateWorkPreferences({
          pathname,
          message: data.join(', '),
        }),
      );
    } else {
      yield put(
        errorUpdateWorkPreferences({
          pathname,
          message: 'There was an unexpected error.',
        }),
      );
    }
  }
}

export function* loadUpdateUserProfileVisibilityEvent({ payload: updatedUserTalentCollection }) {
  const pathname = yield select(currentPathnameSelector);

  const {
    id: userTalentCollectionId,
    userId: memberId,
    collectionId,
    visibility,
    hideFromCompanies,
    hideFromDomains,
  } = updatedUserTalentCollection;

  try {
    yield call(request, {
      url: `/user_talent_collections/${userTalentCollectionId}`,
      options: {
        method: 'put',
        data: {
          visibility: visibility && visibility.value,
          hideFromCompanyIds: hideFromCompanies && hideFromCompanies.map((item) => item.value),
          hideFromDomains,
        },
      },
    });

    const { data } = yield call(request, {
      url: `/members/${memberId}`,
      options: {
        params: { collection_id: collectionId },
      },
    });
    const user = data;
    user.locations = yield call(rehydrateLocationsDetails, user);
    yield put(
      loadedUpdateUserProfileVisibility({
        pathname,
        user,
      }),
    );
  } catch (e) {
    yield call(SentryAPI.trackError, 'update user profile visibility', {
      networkId: collectionId,
      payload: updatedUserTalentCollection,
      e,
    });
    const { response } = e;
    const { data, status } = response || {};
    if (status === 422) {
      yield put(
        errorUpdateUserProfileVisibility({
          pathname,
          message: data.join(', '),
        }),
      );
    } else {
      yield put(
        errorUpdateUserProfileVisibility({
          pathname,
          message: 'There was an unexpected error.',
        }),
      );
    }
  }
}

export default function* profileUpdateRequestWatcher() {
  yield takeLatest(loadUpdateUserProfile().type, profileUpdateRequestWorker);
  yield takeLatest(loadUpdateWorkPreferences().type, profileUpdateWorkPreferencesWorker);
  yield takeLatest(loadUpdateUserProfileVisibility().type, loadUpdateUserProfileVisibilityEvent);
}
