import { createActions } from 'redux-actions';

export const { loadJobFunctions, loadedJobFunctions, errorJobFunctions } = createActions(
  'LOAD_JOB_FUNCTIONS',
  'LOADED_JOB_FUNCTIONS',
  'ERROR_JOB_FUNCTIONS',
);

export const { loadAllJobFunctions, loadedAllJobFunctions, errorAllJobFunctions } = createActions(
  'LOAD_ALL_JOB_FUNCTIONS',
  'LOADED_ALL_JOB_FUNCTIONS',
  'ERROR_ALL_JOB_FUNCTIONS',
);
