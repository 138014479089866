import { createActions } from 'redux-actions';

export const { loadTalentOnboardingSubmit, loadedTalentOnboardingSubmit, errorTalentOnboardingSubmit } = createActions(
  'LOAD_TALENT_ONBOARDING_SUBMIT',
  'LOADED_TALENT_ONBOARDING_SUBMIT',
  'ERROR_TALENT_ONBOARDING_SUBMIT',
);

export const {
  talentOnboardingCtaClick,
  talentOnboardingCompleteStep,
  talentOnboardingCompleted,
  talentOnboardingResumeTooBig,
} = createActions(
  'TALENT_ONBOARDING_CTA_CLICK',
  'TALENT_ONBOARDING_COMPLETE_STEP',
  'TALENT_ONBOARDING_COMPLETED',
  'TALENT_ONBOARDING_RESUME_TOO_BIG',
);
