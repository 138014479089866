/* eslint-disable max-lines */
import getConfig from 'next/config';
import humps from 'humps';
import axios from 'axios';
import SentryAPI from './SentryAPI';
import { optimizeImage } from '../helpers/fileHelpers';
import logger from '../helpers/logger';
import Sort from '../helpers/sort';

const { publicRuntimeConfig } = getConfig();
export const { sandboxAuthToken: SANDBOX_AUTH_TOKEN, customPath: BASE_PATH } = publicRuntimeConfig;

export const DEFAULT_JOB_HITS_PER_PAGE = 20;
export const DEFAULT_COMPANY_HITS_PER_PAGE = 12;

class SearchClient {
  static async fetch(network, { type, query = {}, method = 'GET', camelizeKeys = true }) {
    const { apiV2BaseUrl: baseURL } = publicRuntimeConfig;
    const params = new URLSearchParams();

    Object.entries(humps.decamelizeKeys(query)).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => params.append(`${key}[]`, item));
      } else {
        params.set(key, value);
      }
    });

    const queryParams = params.toString();
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    if (SANDBOX_AUTH_TOKEN) {
      headers.Authorization = `Basic ${SANDBOX_AUTH_TOKEN}`;
    }

    const res = await axios(
      `${baseURL}collections/${network.id}/search/${type}${method === 'GET' && queryParams ? `?${queryParams}` : ''}`,
      {
        method,
        headers,
        ...(method !== 'GET' ? { data: query } : undefined),
      },
    );

    return camelizeKeys ? humps.camelizeKeys(res.data) : res.data;
  }

  static async getHeroNumbers(network) {
    try {
      const { results } = await SearchClient.fetch(network, { type: 'network_counts' });

      return results;
    } catch (e) {
      logger.error({ id: network.id, e }, 'Search: get hero numbers failed');
      SentryAPI.trackError('Search: get hero numbers failed', { id: network.id, e: JSON.stringify(e) });
      return {
        jobsCount: 0,
        companiesCount: 0,
      };
    }
  }

  static async findJobFunctions(network) {
    try {
      const {
        results: { jobFunctions = [] },
      } = await SearchClient.fetch(network, { type: 'job_functions' });

      return Sort.alphabetic(
        jobFunctions.map((jobFunction) => ({
          name: jobFunction,
          value: encodeURIComponent(jobFunction.trim()),
        })),
      );
    } catch (e) {
      SentryAPI.trackError('Search: get job functions failed', { id: network.id, e: JSON.stringify(e) });
      return [];
    }
  }

  static async findJobs({ network, page = 0, filters = '', query = '', hitsPerPage = DEFAULT_JOB_HITS_PER_PAGE }) {
    if (!network.id) return { jobs: [], total: 0 };

    try {
      const { results } = await SearchClient.fetch(network, {
        type: 'jobs',
        method: 'POST',
        query: {
          hitsPerPage,
          page,
          filters,
          query,
        },
      });
      const { jobs, count } = results;

      return humps.camelizeKeys({
        jobs: jobs
          .filter(({ organization }) => organization !== null && organization !== undefined)
          .map((job) => {
            const { id, organization: company, objectID, ...otherProps } = job;
            // eslint-disable-next-line camelcase
            const { logoUrl } = company;
            return {
              id: id === undefined && objectID ? parseInt(objectID, 10) : id,
              isDiscarded: false,
              organization: { ...company, logoUrl: optimizeImage(logoUrl) },
              ...otherProps,
            };
          }),
        page,
        total: count,
      });
    } catch (e) {
      SentryAPI.trackError('Search: find jobs failed', { id: network.id, e: JSON.stringify(e) });
      return { jobs: [], total: 0 };
    }
  }

  static async findLocationSuggestions({ network, company, term = '', maxFacetHits = 6, origin = 'jobs' }) {
    if (!network.id) return [];

    try {
      const data = await SearchClient.fetch(network, {
        type: 'locations',
        query: {
          maxHits: maxFacetHits,
          query: term,
          origin,
          ...(company?.id ? { organizationId: company.id } : undefined),
        },
      });

      return data?.results?.locations?.filter((location) => location.value !== 'Remote').slice(0, 5) || [];
    } catch (e) {
      SentryAPI.trackError('Search: find location suggestions failed', { id: network.id, term, e: JSON.stringify(e) });
      return [];
    }
  }

  static async findCompanies({ network, page = 0, filters = '', query = '' }) {
    if (!network.id) return { companies: [], total: 0 };

    try {
      const { results } = await SearchClient.fetch(network, {
        type: 'companies',
        method: 'POST',
        query: {
          hitsPerPage: DEFAULT_COMPANY_HITS_PER_PAGE,
          page,
          query,
          filters,
        },
      });
      const { companies, count } = results;

      return humps.camelizeKeys({
        companies: companies.map((item) => {
          const { _highlightResult, id, objectID, ...otherProps } = item;
          // eslint-disable-next-line camelcase
          const { logoUrl } = item;
          return {
            id: id === undefined && objectID ? parseInt(objectID, 10) : id,
            isDiscarded: false,
            logoUrl: optimizeImage(logoUrl),
            ...otherProps,
          };
        }),
        page,
        total: count,
      });
    } catch (e) {
      SentryAPI.trackError('Search: find companies failed', { id: network.id, e: JSON.stringify(e) });
      return { companies: [], total: 0 };
    }
  }

  static async findCompaniesJobTotals({ network, companies }) {
    if (!network.id) return companies.map((c) => ({ ...c, activeJobsCount: 0 }));

    const organizationIds = companies.map(({ id }) => id);

    try {
      const {
        results: { counts },
      } = await SearchClient.fetch(network, {
        type: 'job_counts_by_company',
        query: {
          organizationIds,
        },
      });

      return companies.map((company) => {
        const activeJobsCount = counts[company.id] ?? 0;

        return {
          ...company,
          logoUrl: optimizeImage(company.logoUrl),
          activeJobsCount,
        };
      });
    } catch (e) {
      SentryAPI.trackError('Search: find company total jobs failed', { id: network.id, e: JSON.stringify(e) });
      return companies.map((c) => ({ ...c, activeJobsCount: 0 }));
    }
  }

  static async findIndustryTags({ network, type = 'Job' }) {
    if (!network.id) return [];

    try {
      return SearchClient.fetch(network, {
        type: 'industry_tags',
        query: {
          type,
        },
      });
    } catch (e) {
      SentryAPI.trackError('Search: find industry tags failed', { id: network.id, e: JSON.stringify(e) });
      throw e;
    }
  }

  static async findCompaniesHeadCounts({ network, type = 'Job' }) {
    if (!network?.id) return [];

    try {
      return SearchClient.fetch(network, {
        type: 'companies_head_count',
        query: {
          type,
        },
      });
    } catch (e) {
      SentryAPI.trackError('Search: find company head count failed', { id: network.id, e: JSON.stringify(e) });
      throw e;
    }
  }

  static async findCompaniesStage({ network, type = 'Job' }) {
    if (!network?.id) return [];

    try {
      return SearchClient.fetch(network, {
        type: 'companies_stage',
        query: {
          type,
        },
      });
    } catch (e) {
      SentryAPI.trackError('Search: find company stage', { id: network.id, e: JSON.stringify(e) });
      throw e;
    }
  }

  static async findCompanyFacets({ network, origin = 'jobs' }) {
    if (!network?.id) return [];

    try {
      const { results } = await SearchClient.fetch(network, {
        type: 'company_facets',
        query: {
          origin,
        },
        camelizeKeys: false,
      });

      const facets = {
        head_count: {},
        stage: {},
        industry_tags: {},
        ...results,
      };
      const parsedFacets = Object.keys(facets).reduce(
        (arr, key) =>
          Object.assign(arr, {
            [key]: Object.entries(facets[key]).map((item) => ({
              value: item[0],
              count: item[1],
            })),
          }),
        {},
      );

      return {
        headCount: parsedFacets.head_count.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10)),
        stage: parsedFacets.stage,
        industryTags: parsedFacets.industry_tags,
      };
    } catch (e) {
      SentryAPI.trackError('Search: find company facets data', { id: network.id, e: JSON.stringify(e) });
      throw e;
    }
  }
}

export default SearchClient;
