import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import FileUpload from '../../atoms/FileUpload';

import { getFileNameFromUrl } from '../../../helpers/fileHelpers';

const ResumeFields = ({ resume, resumeUrl, setFieldValue }) => {
  const onResumeAttached = ({ name, files }) => {
    setFieldValue(name, files);
  };

  const onResumeUrlRemove = () => {
    setFieldValue('resumeUrl', null);
  };

  const onResumeRemove = () => {
    setFieldValue('resume', []);
  };

  return (
    <>
      {!resumeUrl && !resume.length && (
        <FileUpload files={resume} onChange={onResumeAttached} name="resume" accept=".pdf" />
      )}

      {resumeUrl && (
        <>
          <a href={resumeUrl} target="_blank" rel="noopener noreferrer">
            {getFileNameFromUrl(resumeUrl)}
          </a>
          <Button ml={[2]} size="small" textOnly onClick={onResumeUrlRemove}>
            Remove
          </Button>
        </>
      )}

      {!!resume.length && (
        <>
          <span>{resume[0].name}</span>
          <Button ml={[2]} size="small" textOnly onClick={onResumeRemove}>
            Remove
          </Button>
        </>
      )}
    </>
  );
};

ResumeFields.propTypes = {
  resume: PropTypes.array,
  resumeUrl: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

ResumeFields.defaultProps = {
  resume: [],
  resumeUrl: null,
};

export default ResumeFields;
