import { del, post, get, put } from './index';
import SentryAPI from './SentryAPI';

class UserAPI {
  static async create(data) {
    try {
      const response = await post('/users', data);
      return response.data;
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('Create user failed', { data, e });
      }
      throw e;
    }
  }

  static async deleteUserTalentCollection(id) {
    try {
      const response = await del(`/user_talent_collections/${id}`);
      return response.data;
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('Delete account failed', { e });
      }
      throw e;
    }
  }

  static async validate(data) {
    try {
      await post('/users/validate', data);
      return true;
    } catch (e) {
      const { response } = e;
      const { status } = response || {};

      if (status && status !== 422) {
        SentryAPI.trackError('', { data, e });
      }

      throw e;
    }
  }

  static async me(token) {
    try {
      const response = await get(
        '/users/me',
        {},
        {
          'X-User-Token': token,
        },
      );
      return response.data;
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('User sign in using token failed', { token, e });
      }
      throw e;
    }
  }

  static async requestData() {
    try {
      const response = await post('/messages/user-data');
      return response.data;
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('Request personal info failed', { e });
      }
      throw e;
    }
  }

  static async update(data) {
    try {
      const response = await put('/users/me', data);
      return response.data;
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('Update user info failed', { data, e });
      }
      throw e;
    }
  }
}

export default UserAPI;
