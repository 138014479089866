import { handleActions } from 'redux-actions';
import produce from 'immer';
import { hydrate } from '../actions/hydrate';
import {
  locationSuggestionsReset,
  locationSuggestionsFailure,
  locationSuggestionsRequest,
  locationSuggestionsSuccess,
} from '../actions/locationSuggestions';

export const initialState = {
  term: '',
  suggestions: [],
  initialized: false,
  loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const locationSuggestions = handleActions(
  {
    [locationSuggestionsRequest]: produce((draft, action) => {
      const { term } = action.payload;
      draft = { ...initialState };
      draft.loading = true;
      draft.term = term;
      return draft;
    }),
    [locationSuggestionsFailure]: produce((draft, action) => {
      draft = { ...initialState };
      draft.error = action.payload;
      return draft;
    }),
    [locationSuggestionsReset]: produce((draft) => {
      draft = { ...initialState };
      return draft;
    }),
    [locationSuggestionsSuccess]: produce((draft, action) => {
      const { suggestions, term } = action.payload;
      draft.loading = false;
      draft.initialized = true;
      draft.term = term;
      draft.suggestions = suggestions;
      return draft;
    }),
    [hydrate]: produce((draft, action) => {
      draft = action.payload.locationSuggestions;
      return draft;
    }),
  },
  initialState,
);

export default locationSuggestions;
