import { call, put, takeLatest } from 'redux-saga/effects';
import { loadDeleteAccount, errorDeleteAccount, loadedDeleteAccount } from '../actions/accountSettingsActions';
import UserAPI from '../../api/UserAPI';
import { loadLogoutRequest } from '../actions/userActions';

export function* loadDeleteAccountEvent({ payload }) {
  const { membershipId, network } = payload;
  try {
    yield call(UserAPI.deleteUserTalentCollection, membershipId);
    yield put(loadedDeleteAccount());
    yield put(loadLogoutRequest({ network }));
  } catch (e) {
    yield put(errorDeleteAccount(e));
  }
}

function* accountSettingsWatcher() {
  yield takeLatest(loadDeleteAccount().type, loadDeleteAccountEvent);
}
export default accountSettingsWatcher;
