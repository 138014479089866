import { handleActions } from 'redux-actions';
import produce from 'immer';
import uniqBy from 'lodash.uniqby';
import { loadedLogoutRequest } from '../actions/userActions';
import {
  errorFavoriteCompaniesList,
  loadedFavoriteCompaniesList,
  loadedFavoriteCompany,
  loadFavoriteCompaniesList,
  loadedDiscardCompany,
} from '../actions/companies';

export const initialState = {
  companies: [],
  page: 1,
  total: 0,
  error: null,
  isLazyLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const favoriteCompanies = handleActions(
  {
    [loadFavoriteCompaniesList]: produce((draft) => {
      draft.error = null;
      return draft;
    }),
    [loadedFavoriteCompany]: produce((draft, action) => {
      const { favorite } = action.payload;
      const company = { ...action.payload.company };
      if (!favorite) {
        const index = draft.companies.findIndex((item) => item.id === company.id);
        draft.companies.splice(index, 1);
        draft.total -= 1;
      } else if (favorite) {
        company.isFavorited = true;
        draft.companies = uniqBy([...draft.companies, company], 'id');
        draft.total += 1;
      }
      return draft;
    }),
    [loadedDiscardCompany]: produce((draft, action) => {
      const { discarded } = action.payload;
      const company = { ...action.payload.company };
      const { id } = company;
      const index = draft.companies.findIndex((item) => item.id === id);
      if (index >= 0) {
        draft.companies[index].isDiscarded = discarded;
        draft.companies[index].isFavorited = !discarded;
        draft.total += discarded ? -1 : 1;
      }
      return draft;
    }),
    [loadedFavoriteCompaniesList]: produce((draft, action) => {
      const { items, page } = action.payload;
      draft.page = parseInt(page || 1, 10);
      if (items.length) {
        draft.isLazyLoading = true;
      }
      draft.companies = uniqBy([...draft.companies, ...items], 'id');
      draft.total = draft.companies.length;
      return draft;
    }),
    [errorFavoriteCompaniesList]: produce((draft, action) => {
      draft = { ...initialState };
      draft.error = action.payload.error;
      return draft;
    }),
    [loadedLogoutRequest]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
  },
  initialState,
);

export default favoriteCompanies;
