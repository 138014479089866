import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

const GlobalStyle = ({ theme, ...props }) => (
  <Box
    sx={{
      fontFamily: 'body',
      fontWeight: theme.fontWeights.body,
      fontStyle: 'normal',
      fontSize: theme.fontSizes[2],
      color: theme.colors.text.main,
    }}
    {...props}
  />
);

GlobalStyle.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default GlobalStyle;
