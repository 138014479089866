export const hasAdvancedDesignSelector = (network) => {
  const { features } = network || {};

  if (!features) return false;
  return features.indexOf('advanced_design') !== -1;
};

export const hasCustomHeroSelector = (network) => {
  const { features } = network || {};

  if (!features) return false;
  return features.indexOf('custom_hero') !== -1;
};

export const hideNumbersInHeroSelector = (network) => {
  if (!network) return true;

  const { features } = network;

  return !features || features.indexOf('hide_numbers_in_hero') !== -1 || features.indexOf('numbers_in_nav') !== -1;
};

export const skillsDeactivatedSelector = (network) => {
  const { features } = network || {};

  if (!features) return false;
  return features.indexOf('skills_deactivated') !== -1;
};

export const hideLogoInHeroSelector = (network) => {
  const hasAdvancedDesign = hasAdvancedDesignSelector(network);
  if (hasAdvancedDesign) return true;
  if (!network) return true;

  const { features, logoUrl } = network;
  return !logoUrl || !features || features.indexOf('hide_logo_in_hero') !== -1;
};

export const hideNameInHeroSelector = (network) => {
  const hasAdvancedDesign = hasAdvancedDesignSelector(network);
  if (hasAdvancedDesign) return true;
  if (!network) return true;

  const { features } = network;
  return !features || features.indexOf('hide_name_in_hero') !== -1;
};

export const hideNetworkInfoSelector = (network) => {
  if (!network) return true;

  const { features } = network;

  if (!features) return true;
  if (features.indexOf('hide_collection_info_in_hero') !== -1) return true;

  return (
    features.indexOf('hide_logo_in_hero') !== -1 &&
    features.indexOf('hide_numbers_in_hero') !== -1 &&
    features.indexOf('hide_name_in_hero') !== -1
  );
};

export const hideTnCtaInHeroSelector = (network) => {
  if (!network) return true;

  const { features } = network;

  return !features || features.indexOf('hide_tn_cta_in_hero') !== -1;
};

export const claimCompanyProfileInHeroSelector = (network) => {
  const { features = [] } = network || {};

  return features.indexOf('claim_organization_profile_in_hero') !== -1;
};

export const talentNetworkSelector = (network) => {
  const { features = [] } = network || {};

  return features.indexOf('talent_network') !== -1;
};

export const talentNetworkV2Selector = (network) => {
  const { features = [] } = network || {};

  return features.indexOf('tn_onboarding_v2') !== -1;
};

export const numbersInNavSelector = (network) => {
  const { features = [] } = network || {};

  return !features || features.indexOf('numbers_in_nav') !== -1;
};

export const networkLabelSelector = (network) => {
  const { label } = network || {};

  return label || 'getro-network';
};

export const networkLegalSelector = (network) => {
  const { legal } = network || {};

  if (!legal) {
    return {
      ccpaCompliant: false,
    };
  }

  return legal;
};

export const makeFeatureSelector = (feature, network) => {
  const { features } = network || {};

  return features && features.indexOf(feature) !== -1;
};

export const organizationsStringSelector = (plural, network) => {
  const hasOrganizationsString = makeFeatureSelector('organizations_string', network);
  let organizationString = hasOrganizationsString ? 'organization' : 'company';

  if (plural) {
    organizationString = hasOrganizationsString ? 'organizations' : 'companies';
  }

  return organizationString;
};

export const portfolioCompanySelectorFilter = (network) => {
  const hasOrganizationsString = makeFeatureSelector('organizations_string', network);
  return hasOrganizationsString ? 'organization' : 'portfolio company';
};

export const organizationsStringSelectorFilter = (plural, network) => {
  const hasOrganizationsString = makeFeatureSelector('organizations_string', network);
  let organizationString = hasOrganizationsString ? 'Organization' : 'Company';

  if (plural) {
    organizationString = hasOrganizationsString ? 'Organizations' : 'Companies';
  }

  return organizationString;
};

export const showJobAlertsSelector = (network) => {
  const hasJobAlertsDisabled = makeFeatureSelector('no_job_alerts', network);
  const hasTalentNetwork = talentNetworkSelector(network);

  return !hasJobAlertsDisabled && !hasTalentNetwork;
};
