import React from 'react';
import PropTypes from 'prop-types';

import CareerInformationForm from '../../../components/molecules/CareerInformationForm';

import { OnboardingPropTypes } from '../initialValues';

const CareerInformationStep = ({ nextStep, prevStep, formData }) => (
  <CareerInformationForm
    initialValues={formData}
    onSubmit={nextStep}
    onBack={prevStep}
    saveText="Next step"
    title="Next, we'd like to understand your needs to show more jobs relevant to you."
  />
);

CareerInformationStep.propTypes = {
  formData: OnboardingPropTypes.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default CareerInformationStep;
