import { put } from './index';
import SentryAPI from './SentryAPI';

class JobAlertPreferencesAPI {
  static async update({ preferences, networkId }) {
    try {
      const response = await put('/users/me/job_alert_preferences', preferences);
      return response.status === 200;
    } catch (e) {
      const { response } = e;
      if (response) {
        SentryAPI.trackError('Update user job alert preferences failed', { preferences, networkId, e });
      }
      throw e;
    }
  }
}

export default JobAlertPreferencesAPI;
