import { createActions } from 'redux-actions';

export const { moveOnboardingForward, moveOnboardingBack, moveOnboardingTo } = createActions(
  'MOVE_ONBOARDING_FORWARD',
  'MOVE_ONBOARDING_BACK',
  'MOVE_ONBOARDING_TO',
);
export const { submitOnboardingRequest, submitOnboardingSuccess, submitOnboardingFailure } = createActions(
  'SUBMIT_ONBOARDING_REQUEST',
  'SUBMIT_ONBOARDING_SUCCESS',
  'SUBMIT_ONBOARDING_FAILURE',
);

export const { jobSeekerOnboardingStart, jobSeekerOnboardingFinishStep, signupTakenEmail } = createActions(
  'JOB_SEEKER_ONBOARDING_START',
  'JOB_SEEKER_ONBOARDING_FINISH_STEP',
  'SIGNUP_TAKEN_EMAIL',
);

export const { setOnboardingFormData } = createActions('SET_ONBOARDING_FORM_DATA');
