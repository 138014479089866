import { combineReducers } from 'redux';
import { routerReducer } from 'connected-next-router';

import onboarding from './onboardingReducer';
import user from './userReducer';
import jobs from './jobsReducer';
import profile from './profileReducer';
import layout from './layout';
import locationSuggestions from './locationSuggestions';
import companies from './companiesReducer';
import companyDetails from './companyDetailsReducer';
import loadingReducer from './loadingReducer';
import apiReducer from './apiReducer';
import favoriteJobs from './favoriteJobsReducer';
import discardedJobs from './discardedJobsReducer';
import allCompanies from './allCompaniesReducer';
import introductionRequest from './introductionRequest';
import introductions from './introductionsReducer';
import discardedCompanies from './discardedCompaniesReducer';
import favoriteCompanies from './favoriteCompaniesReducer';
import deleteAccount from './deleteAccountReducer';
import page from './page';
import customFilters from './customFiltersReducer';
import jobFunctions from './jobFunctionsReducer';
import allJobFunctions from './allJobFunctionsReducer';
import advancedDesign from './advancedDesign';
import jobAlert from './jobAlertReducer';
import jobApplication from './jobApplicationReducer';
import talentNetworkOnboarding from './talentNetworkOnboardingReducer';
import filters from './filters';

const rootReducer = combineReducers({
  advancedDesign,
  allCompanies,
  companyDetails,
  favoriteJobs,
  discardedJobs,
  introductionRequest,
  introductions,
  loading: loadingReducer,
  api: apiReducer,
  companies,
  jobs,
  onboarding,
  profile,
  user,
  layout,
  router: routerReducer,
  locationSuggestions,
  discardedCompanies,
  favoriteCompanies,
  deleteAccount,
  page,
  customFilters,
  jobFunctions,
  allJobFunctions,
  jobAlert,
  jobApplication,
  talentNetworkOnboarding,
  filters,
});

export default rootReducer;
