import { createSelector } from 'reselect';
import LINKEDIN_URL_REGEX from '../../constants/linkedin';
import { EMPLOYMENT_TYPES, PROFILE_VISIBILITIES } from '../../constants';
import strings from '../../constants/strings';
import { getFileNameFromUrl } from '../../helpers/fileHelpers';

export const userSelector = (state) => state.user;

export const makeSelectShowSignUpModal = createSelector(userSelector, (state) => state.showSignUpModal);

export const makeSelectFavoriteJobIds = createSelector(userSelector, (state) => state.jobLikeIds);

export const makeSelectFavoriteCompanyIds = createSelector(userSelector, (state) => state.organizationLikeIds);
export const makeSelectDiscardCompanyIds = createSelector(userSelector, (state) => state.organizationDiscardIds);

export const makeSelectUserVerified = ({ network }) =>
  createSelector(userSelector, (user) => {
    const { loaded: networkLoaded, id: networkId } = network;
    const { id: userId, loaded: userLoaded, userTalentCollections } = user;

    if (!networkLoaded || networkId === undefined) {
      return false;
    }

    if (!userLoaded || userId === undefined) {
      return false;
    }

    const userTalentCollection = userTalentCollections.find(
      (utc) => parseInt(utc.collectionId, 10) === parseInt(networkId, 10),
    );
    return userTalentCollection?.verified;
  });

export const hasValidLinkedInUrlSelector = createSelector(userSelector, (user) => {
  const { linkedinUrl } = user;

  if (!linkedinUrl) {
    return false;
  }

  return LINKEDIN_URL_REGEX.test(linkedinUrl.toLowerCase());
});

export const isOnboardingPendingSelector = createSelector(userSelector, (user) => {
  const { onboardingCompleted, id } = user;
  return id && !onboardingCompleted;
});

export const authenticationTokenSelector = createSelector(userSelector, (user) => {
  const { authenticationToken } = user;
  return authenticationToken;
});

export const isAuthenticatedSelector = createSelector(
  authenticationTokenSelector,
  (authenticationToken) =>
    authenticationToken !== undefined && authenticationToken !== null && authenticationToken !== '',
);

export const tokenSelector = createSelector(userSelector, (user) => {
  const { token = '' } = user;
  return token;
});

export const makeOrganizationIntroductionRequests = (organization) =>
  createSelector(userSelector, (user) => {
    const { introductionRequests = [] } = user;

    return introductionRequests.filter(
      ({ introducibleId, introducibleType }) =>
        introducibleId === organization.id && introducibleType === 'Organization',
    );
  });

export const makeOrganizationAlreadyIntroduced = (organization) =>
  createSelector(
    makeOrganizationIntroductionRequests(organization),
    (organizationIntroductionRequests) => organizationIntroductionRequests.length > 0,
  );

export const makeOrganizationIntroductionStatus = (organization) =>
  createSelector(makeOrganizationIntroductionRequests(organization), (organizationIntroductionRequests) => {
    if (organizationIntroductionRequests.length === 0) return null;

    const acceptedIntroductionRequest = organizationIntroductionRequests.find(({ status }) => status === 'accepted');
    if (acceptedIntroductionRequest === undefined) return 'pending';

    return 'accepted';
  });

export const makeSelectUserProfile = (network) =>
  createSelector(userSelector, (user) => {
    if (user) {
      const { userTalentCollections } = user;
      let { userTalentCollection } = user;
      if (!userTalentCollection || !userTalentCollection.id) {
        userTalentCollection = userTalentCollections.find(({ collectionId }) => collectionId === network.id) || {};
      }
      const { hideFromDomains, hideFromCompanies, ...otherUserTalentCollection } = userTalentCollection;
      let { visibility } = userTalentCollection;
      // in the odd edge case a user does not have a value set we default to visible to all
      if (visibility === null || visibility === undefined) {
        visibility = PROFILE_VISIBILITIES[0];
      } else {
        visibility = PROFILE_VISIBILITIES.find((v) => v.value === visibility) || PROFILE_VISIBILITIES[0];
      }

      const seniorities = Object.keys(strings.profile.seniorities)
        .filter((k) => k !== 'job_level_unspecified')
        .map((item) => ({
          value: item,
          label: `${strings.profile.seniorities[item].title} (${strings.profile.seniorities[item].description})`,
        }));

      return {
        ...user,
        referralsReceived: user.referralsReceived || [],
        twitterUrl: user.twitterUrl || '',
        resumeName: getFileNameFromUrl(user.resumeUrl),
        resumeUrl: user.resumeUrl || '',
        currentLocation: user.currentLocation || 'Unspecified',
        jobSearchStatus: user.jobSearchStatus || 'not_looking',
        jobSearchStage: strings.profile.jobSearchStatuses[user.jobSearchStatus || 'not_looking'],
        locations: user.locations.map((l) => ({ value: l.placeId, label: l.name })),
        avatarUrl: user.avatarUrl,
        bio: user.bio || '',
        employmentTypes: user.employmentTypes || [],
        firstName: user.firstName || '',
        jobFunctionIds: user.jobFunctions.map((j) => j.id),
        lastName: user.lastName || '',
        opportunities: user.opportunityTypes.includes('employment'),
        seniority: seniorities.find((s) => s.value === user.seniority),
        verified: userTalentCollection.verified,
        userTalentCollection: {
          ...otherUserTalentCollection,
          visibility,
          hideFromDomains: hideFromDomains || [],
          hideFromCompanies: hideFromCompanies || [],
        },
      };
    }
    return null;
  });

export const makeSelectUsWorkStatus = createSelector(userSelector, (state) => {
  const result = [];
  if (state) {
    if (state.usWorkAuthorization) {
      result.push('Authorized');
    } else {
      result.push('Unauthorized');
    }

    if (state.requireSponsorshipVisa) {
      result.push('will require H1B');
    } else {
      result.push('sponsorship not required');
    }
  }
  return result.join(', ');
});

export const makeSelectPreferredWorkLocations = createSelector(userSelector, (state) => {
  const workLocations = [];
  if (state) {
    if (state.remoteWork) {
      workLocations.push('Remote');
    }
    if (state.locations) {
      state.locations.forEach((loc) => {
        workLocations.push(loc.label);
      });
    }
  }
  if (!workLocations.length) {
    workLocations.push('Unspecified');
  }
  return workLocations.join(', ');
});

export const makeSelectPreferredJobTypes = createSelector(userSelector, (state) => {
  const employmentTypes = [];
  if (state) {
    if (state.employmentTypes) {
      const EMPLOYMENT_TYPES_OBJECT = {};
      EMPLOYMENT_TYPES.forEach((item) => {
        EMPLOYMENT_TYPES_OBJECT[item.value] = item.label;
      });
      state.employmentTypes.forEach((item) => {
        employmentTypes.push(EMPLOYMENT_TYPES_OBJECT[item]);
      });
    }
  }
  if (!employmentTypes.length) {
    employmentTypes.push('Unspecified');
  }
  return employmentTypes.join(', ');
});
