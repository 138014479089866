import featureToggles from '../featureToggles.json';

const featureToggleValue = (feature) => {
  const featuresInEnv = featureToggles[process.env.NODE_ENV];

  if (!featuresInEnv) return false;

  return featuresInEnv[feature];
};

const useFeatureToggle = (feature) => featureToggleValue(feature);

export { featureToggleValue };

export default useFeatureToggle;
