import getConfig from 'next/config';
import { extractValuesFrom } from './arrayHelpers';
import GooglePlacesAPI from '../api/GooglePlacesAPI';
import { getItem } from './localStorageHelper';

const { publicRuntimeConfig } = getConfig();
const { privacyPolicyVersion: PRIVACY_POLICY_VERSION, termsPolicyVersion: TERMS_POLICY_VERSION } = publicRuntimeConfig;

const prepareRequestData = (formData, networkId, updateOnboardingCompleted) => {
  const {
    currentLocation,
    skills,
    locations,
    hideFromCompanies,
    resume,
    visibility,
    hideFromDomains,
    linkedinId,
    password,
    onboardingCompleted,
    forcePasswordUpdate,
    userJobAlertPreference,
    ...otherProps
  } = formData;
  const [firstResume] = resume || [];
  const { value: placeId = null } = currentLocation || {};
  const visibilityRestricted = visibility.value === 'restricted';
  const talentGroupCode = getItem('talentGroupCode');
  const result = {
    currentLocationId: placeId,
    skillIds: extractValuesFrom(skills),
    locationIds: extractValuesFrom(locations),
    visibility: visibility.value,
    hideFromCompanyIds: visibilityRestricted ? extractValuesFrom(hideFromCompanies) : [],
    hideFromDomains: visibilityRestricted ? hideFromDomains : [],
    resume: firstResume || null,
    collectionId: networkId,
    privacyPolicyVersion: PRIVACY_POLICY_VERSION,
    termsPolicyVersion: TERMS_POLICY_VERSION,
    linkedinId,
    password: password || undefined,
    talentGroupCode: talentGroupCode || undefined,
    passwordConfirmation: password || undefined,
    ...otherProps,
    onboardingCompleted: updateOnboardingCompleted !== undefined ? updateOnboardingCompleted : onboardingCompleted,
  };

  if (userJobAlertPreference) {
    const {
      locations: userJobAlertPreferenceLocations,
      frequency = {},
      ...otherUserJobAlertPreferenceProps
    } = userJobAlertPreference;

    result.userJobAlertPreferenceAttributes = {
      ...otherUserJobAlertPreferenceProps,
      frequency: frequency?.value,
      locationIds: extractValuesFrom(userJobAlertPreferenceLocations),
    };
  }

  return result;
};

export const rehydrateLocationsDetails = async (user) => {
  const { locations } = user;
  return Promise.all(
    locations.map(async (location) => {
      const { placeId } = location;
      const { country = null } = await GooglePlacesAPI.placeDetails(placeId);
      return {
        ...location,
        country,
      };
    }),
  );
};

export default prepareRequestData;
