import { createSelector } from 'reselect';

const jobAlertSelector = (state) => state.jobAlert;
const createJobAlertSelector = createSelector(jobAlertSelector, ({ create }) => create);
const updateJobAlertSelector = createSelector(jobAlertSelector, ({ update }) => update);
const unsubscribeJobAlertSelector = createSelector(jobAlertSelector, ({ unsubscribe }) => unsubscribe);

export const createJobAlertSuccessSelector = createSelector(createJobAlertSelector, ({ success }) => success);
export const createJobAlertErrorSelector = createSelector(createJobAlertSelector, ({ error }) => error);
export const loadedJobAlertSelector = createSelector(jobAlertSelector, ({ jobAlert }) => jobAlert);
export const hasActiveJobAlertSelector = createSelector(loadedJobAlertSelector, ({ status }) => status === 'active');

export const updateJobAlertSuccessSelector = createSelector(updateJobAlertSelector, ({ success }) => success);
export const updateJobAlertErrorSelector = createSelector(updateJobAlertSelector, ({ error }) => error);

export const unsubscribeJobAlertSuccessSelector = createSelector(unsubscribeJobAlertSelector, ({ success }) => success);
export const unsubscribeJobAlertErrorSelector = createSelector(unsubscribeJobAlertSelector, ({ error }) => error);
