import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useBasepathFix = () => {
  const router = useRouter();

  useEffect(() => {
    const { basePath } = router;
    const handleRouteChange = (url) => {
      // eslint-disable-next-line no-throw-literal
      if (basePath && url.startsWith(basePath + basePath)) throw 'aborting navigation due to duplicated base path bug';
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  });
};

export default useBasepathFix;
