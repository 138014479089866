import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';
import SearchClient from '../../api/SearchClient';
import {
  errorAllJobFunctions,
  errorJobFunctions,
  loadAllJobFunctions,
  loadedAllJobFunctions,
  loadedJobFunctions,
  loadJobFunctions,
} from '../actions/jobFunctionActions';
import { get } from '../../api';
import { jobFunctionsSelector } from '../selectors/jobFunctions';

export function* jobFunctionsRequest({ payload }) {
  const abortController = new AbortController();

  const { network } = payload;
  const jobFunctions = yield select(jobFunctionsSelector);

  if (jobFunctions !== null) {
    yield put(loadedJobFunctions(jobFunctions));
  }

  try {
    const response = yield call(SearchClient.findJobFunctions, network, { signal: abortController.signal });
    yield put(loadedJobFunctions(response));
  } catch (e) {
    yield put(errorJobFunctions());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* loadAllJobFunctionsRequest() {
  try {
    const response = yield call(get, '/job_functions');
    const { data } = response;
    const { items } = data;
    yield put(loadedAllJobFunctions(items));
  } catch (e) {
    yield put(errorAllJobFunctions());
  }
}

export default function* jobFunctionsWatcher() {
  yield takeLatest(loadJobFunctions().type, jobFunctionsRequest);
  yield takeLatest(loadAllJobFunctions().type, loadAllJobFunctionsRequest);
}
