import React, { useCallback, useEffect, useState } from 'react';
import { UploadCloud } from 'lucide-react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { readFileAsBase64 } from '../../../helpers/fileHelpers';

const filesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    content: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
);

const Container = styled.div`
  height: 67px;
  line-height: 67px;
  margin-bottom: 20px;
  background: #f6f8fa;
  border: none;
  cursor: default;
`;

const UploadDropZone = styled.div`
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  line-height: normal;
  text-align: center;
  font-family: var(--font-main);
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: #3f495a;
  cursor: default;
`;

const ListItem = styled.div`
  & + & {
    margin-top: var(--space-sm);
  }
`;

const FileUpload = ({ name, files: initialFiles, onChange, accept }) => {
  const [files, setFiles] = useState(initialFiles);

  useEffect(() => {
    setFiles(initialFiles);
  }, [initialFiles]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filesLoaded = [];

      acceptedFiles.forEach((file) => {
        const { name: fileName } = file;

        readFileAsBase64(file, (content) => {
          filesLoaded.push({ name: fileName, content });

          onChange({ name, files: filesLoaded });
          setFiles(filesLoaded);
        });
      });
    },
    [onChange, name],
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
  });

  return (
    <>
      <Container {...getRootProps()}>
        <input {...getInputProps()} name={name} data-testid={`input-${name}`} />
        <UploadDropZone>
          <Box as={UploadCloud} strokeWidth="1.5" aria-hidden="true" mr="1" sx={{ verticalAlign: 'middle' }} />
          Drag and drop or click to upload.
        </UploadDropZone>
      </Container>
      {files && files.length > 0 && files.map((file) => <ListItem key={file.name}>{file.name}</ListItem>)}
    </>
  );
};

FileUpload.propTypes = {
  accept: PropTypes.string.isRequired,
  files: filesPropType,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  files: [],
};

export { filesPropType };

export default FileUpload;
