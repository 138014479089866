const removeElementFrom = (array, element) => {
  const result = [...array];
  const index = result.indexOf(element);

  if (index > -1) {
    result.splice(index, 1);
  }

  return result;
};

const extractValuesFrom = (array, attribute) => (array ? array.map((item) => item[attribute || 'value']) : []);

const checkArrayHasDuplicatedElements = (array) => {
  const valuesArr = extractValuesFrom(array);
  return valuesArr.some((item, idx) => valuesArr.indexOf(item) !== idx);
};

export { extractValuesFrom, removeElementFrom, checkArrayHasDuplicatedElements };
