import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box } from 'rebass/styled-components';

import { ActionsFooter, OnboardingLayoutContent, Search } from '@getro/rombo';
import STRINGS from '../../../constants/strings';
import SkillsAPI from '../../../api/SkillsAPI';

const formSchema = Yup.object().shape({
  skills: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      }),
    )
    .required(STRINGS.validation.selectAtLeastOne),
});

const SkillsForm = ({ disableSave, initialValues, onSubmit, onBack, saveText, title, errorMessage, updating }) => {
  const onSearchSkill = async (query) => {
    if (!query.length) {
      return Promise.resolve([]);
    }

    return SkillsAPI.search(query);
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit} enableReinitialize>
        {({ dirty, setFieldValue, values }) => {
          const onChange = (e) => {
            const { target } = e;
            const { name, value } = target;

            setFieldValue(name, value);
          };

          return (
            <Form>
              <OnboardingLayoutContent title={title}>
                <Box
                  sx={{
                    margin: '2.5rem auto',
                    '&:first-child': {
                      marginTop: 0,
                    },
                  }}
                >
                  <Field
                    name="skills"
                    component={(data) => (
                      <Search
                        data-testid="skills-select"
                        name="skills"
                        orientation="horizontal"
                        value={values.skills}
                        onChange={onChange}
                        multiple
                        label="Select the skills you're best at. We'll display these skills on your profile."
                        placeholder="Start typing..."
                        onSearch={onSearchSkill}
                        {...data}
                      />
                    )}
                  />
                </Box>
                <ActionsFooter
                  saveDisabled={disableSave && !dirty}
                  saveAsSubmit
                  saveText={saveText}
                  onBack={onBack}
                  errorMessage={errorMessage}
                  loading={updating}
                />
              </OnboardingLayoutContent>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

SkillsForm.propTypes = {
  disableSave: PropTypes.bool,
  initialValues: PropTypes.shape({
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  }),
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  saveText: PropTypes.string,
  title: PropTypes.string,
  errorMessage: PropTypes.node,
  updating: PropTypes.bool,
};

SkillsForm.defaultProps = {
  disableSave: false,
  initialValues: {
    skills: [],
  },
  errorMessage: null,
  onBack: () => {},
  saveText: undefined,
  title: '',
  updating: false,
};

export default SkillsForm;
