import { handleActions } from 'redux-actions';
import produce from 'immer';
import { loginSuccessRequest, loadedLogoutRequest } from '../actions/userActions';
import {
  loadUpdateUserProfile,
  loadedUpdateUserProfile,
  errorUpdateUserProfile,
  loadUpdateUserProfileVisibility,
  loadedUpdateUserProfileVisibility,
  errorUpdateUserProfileVisibility,
  errorUpdateWorkPreferences,
  loadedUpdateWorkPreferences,
  loadUpdateWorkPreferences,
} from '../actions/profileActions';
import {
  jobAlertPreferencesUpdateRequest,
  jobAlertPreferencesUpdateSuccess,
} from '../actions/jobAlertPreferencesActions';

export const initialState = {
  errors: {},
  updating: false,
};

/* eslint-disable default-case, no-param-reassign */
const profileReducer = handleActions(
  {
    [jobAlertPreferencesUpdateRequest]: produce((draft) => {
      draft.updating = true;
      return draft;
    }),
    [jobAlertPreferencesUpdateSuccess]: produce((draft) => {
      draft.updating = false;
      return draft;
    }),
    [loadUpdateUserProfile]: produce((draft) => {
      draft.updating = true;
      return draft;
    }),
    [loadUpdateWorkPreferences]: produce((draft) => {
      draft.updating = true;
      return draft;
    }),
    [loadUpdateUserProfileVisibility]: produce((draft) => {
      draft.updating = true;
      return draft;
    }),
    [loadedUpdateUserProfile]: produce((draft, action) => {
      const { pathname } = action.payload;
      delete draft.errors[pathname];
      draft.updating = false;
      return draft;
    }),
    [loadedUpdateWorkPreferences]: produce((draft, action) => {
      const { pathname } = action.payload;
      delete draft.errors[pathname];
      draft.updating = false;
      return draft;
    }),
    [loadedUpdateUserProfileVisibility]: produce((draft, action) => {
      const { pathname } = action.payload;
      delete draft.errors[pathname];
      draft.updating = false;
      return draft;
    }),
    [errorUpdateUserProfile]: produce((draft, action) => {
      const { pathname, message } = action.payload;
      draft.errors[pathname] = message;
      draft.updating = false;
      return draft;
    }),
    [errorUpdateWorkPreferences]: produce((draft, action) => {
      const { pathname, message } = action.payload;
      draft.errors[pathname] = message;
      draft.updating = false;
      return draft;
    }),
    [errorUpdateUserProfileVisibility]: produce((draft, action) => {
      const { pathname, message } = action.payload;
      draft.errors[pathname] = message;
      draft.updating = false;
      return draft;
    }),
    [loginSuccessRequest]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [loadedLogoutRequest]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
  },
  initialState,
);

export default profileReducer;
