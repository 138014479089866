import { handleActions } from 'redux-actions';
import produce from 'immer';
import { hydrate } from '../actions/hydrate';
import { errorAllCompanies, loadAllCompanies, loadedAllCompanies } from '../actions/companies';

export const initialState = {
  list: [],
  error: false,
  initialized: false,
};

/* eslint-disable default-case, no-param-reassign */
const allCompaniesReducer = handleActions(
  {
    [loadAllCompanies]: produce((draft) => {
      draft.list = [];
      draft.error = false;
      draft.initialized = false;
      return draft;
    }),
    [loadedAllCompanies]: produce((draft, action) => {
      draft.list = action.payload.items;
      draft.initialized = true;
      return draft;
    }),
    [errorAllCompanies]: produce((draft, action) => {
      draft.list = [];
      draft.error = !!action.payload.error;
      return draft;
    }),
    [hydrate]: produce((draft, action) => {
      const { allCompanies } = action.payload;
      const { initialized } = allCompanies;
      if (initialized) {
        draft = allCompanies;
      }
      return draft;
    }),
  },
  initialState,
);

export default allCompaniesReducer;
