import React from 'react';
import PropTypes from 'prop-types';

import SkillsForm from '../../../components/molecules/SkillsForm';

import { OnboardingPropTypes } from '../initialValues';

const SkillsStep = ({ formData, nextStep, prevStep }) => (
  <SkillsForm
    initialValues={formData}
    onBack={prevStep}
    onSubmit={nextStep}
    saveText="Next step"
    title="Tell us what you're great at so we can help showcase your talents"
  />
);

SkillsStep.propTypes = {
  formData: OnboardingPropTypes.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default SkillsStep;
