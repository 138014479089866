import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Text } from 'rebass/styled-components';
import { Label } from '@rebass/forms/styled-components';

import { ActionsFooter, Toggle, InputMultiple, OnboardingLayoutContent, Select } from '@getro/rombo';
import STRINGS from '../../../constants/strings';
import WorkLocationFields from '../WorkLocationForm/WorkLocationFields';
import { JOB_ALERT_FREQUENCIES } from '../../../constants';

const JobAlertsForm = ({ disableSave, errorMessage, initialValues, loading, onBack, onSubmit, title, saveText }) => {
  const { userJobAlertPreference, mailTnTlJobAlerts } = initialValues;
  const {
    keywords = [],
    locations = [],
    remoteWork = false,
    willWorkAnywhere = false,
    frequency = 'weekly',
  } = userJobAlertPreference || {};

  const formInitialValues = {
    mailTnTlJobAlerts,
    keywords,
    locations,
    remoteWork,
    willWorkAnywhere,
    frequency,
  };

  const formSchema = Yup.object()
    .shape({
      mailTnTlJobAlerts: Yup.bool(),
      keywords: Yup.array().when('mailTnTlJobAlerts', {
        is: true,
        then: Yup.array()
          .of(Yup.string())
          .min(1, 'Add at least one keyword to make sure the alerts are relevant.')
          .required('Add at least one keyword to make sure the alerts are relevant.'),
      }),
      locations: Yup.array().when('mailTnTlJobAlerts', {
        is: true,
        then: Yup.array().of(
          Yup.object().shape({
            value: Yup.string().required(),
            label: Yup.string().required(),
          }),
        ),
      }),
      remoteWork: Yup.boolean(),
      frequency: Yup.object()
        .shape({
          value: Yup.string().required(),
          label: Yup.string().required(),
        })
        .nullable()
        .when('mailTnTlJobAlerts', {
          is: true,
          then: Yup.object()
            .shape({
              value: Yup.string().required(),
              label: Yup.string().required(),
            })
            .required(STRINGS.validation.requiredField),
        }),
      willWorkAnywhere: Yup.boolean(),
    })
    .test('atLeastOneOptionSelected', null, (obj) => {
      if (!obj.mailTnTlJobAlerts) {
        // skip validations if job alerts are off
        return true;
      }

      const { locations: formLocations, willWorkAnywhere: formWillWorkAnywhere, remoteWork: formRemoteWork } = obj;

      if (formLocations.length > 0 || formWillWorkAnywhere || formRemoteWork) {
        return true; // everything is fine
      }

      return new Yup.ValidationError(STRINGS.validation.selectAtLeastOne, null, 'atLeastOneOptionSelected');
    });

  const handleSubmit = (values) => {
    const {
      mailTnTlJobAlerts: formMailTnTlJobAlerts,
      keywords: formKeywords,
      locations: formLocations,
      remoteWork: formRemoteWork,
      willWorkAnywhere: formWillWorkAnywhere,
      frequency: formFrequency,
    } = values;

    const payload = {
      ...initialValues,
      mailTnTlJobAlerts: formMailTnTlJobAlerts,
    };

    if (formMailTnTlJobAlerts) {
      payload.userJobAlertPreference = {
        ...userJobAlertPreference,
        keywords: formKeywords,
        locations: formLocations,
        remoteWork: formRemoteWork,
        willWorkAnywhere: formWillWorkAnywhere,
        frequency: formFrequency,
      };
    }

    onSubmit(payload);
  };

  return (
    <Formik initialValues={formInitialValues} validationSchema={formSchema} onSubmit={handleSubmit} enableReinitialize>
      {({ dirty, values, setFieldValue, errors }) => {
        const {
          mailTnTlJobAlerts: mailTnTlJobAlertsValue,
          keywords: keyworkdsValue,
          locations: locationsValue,
          remoteWork: remoteWorkValue,
          willWorkAnywhere: willWorkAnywhereValue,
          frequency: frequencyValue,
        } = values;

        const handleKeywordsChange = (data) => {
          const { name, value } = data;

          setFieldValue(name, value);
        };

        const handleChange = (e) => {
          const { target } = e;
          const { name, value } = target;

          if (target.checked !== undefined) {
            setFieldValue(name, target.checked);
            return;
          }

          setFieldValue(name, value);
        };

        return (
          <Form>
            <OnboardingLayoutContent title={title}>
              <Field name="mailTnTlJobAlerts">
                {(data) => (
                  <Label fontWeight="body" color="text.main">
                    <Toggle
                      mr="2"
                      name="mailTnTlJobAlerts"
                      checked={mailTnTlJobAlertsValue}
                      onChange={(e) => setFieldValue('mailTnTlJobAlerts', e.target.checked)}
                      {...data}
                    />
                    Yes, send me jobs matching my interests.
                  </Label>
                )}
              </Field>

              {mailTnTlJobAlertsValue && (
                <>
                  <Text mx="auto" my={3}>
                    Set your preferences to make sure you get relevant alerts.
                  </Text>
                  <>
                    <Field
                      name="keywords"
                      component={(data) => (
                        <InputMultiple
                          className="field"
                          label="What roles do you want to get notified for?"
                          name="keywords"
                          onChange={handleKeywordsChange}
                          placeholder="Type a keyword and press enter"
                          value={keyworkdsValue}
                          {...data}
                        />
                      )}
                    />
                    <Box fs={1} color="text.subtle" mt={2}>
                      Use one or more words like <i>Marketing</i>, <i>Manager</i> or <i>VP</i>.
                    </Box>
                  </>

                  <WorkLocationFields
                    mt={3}
                    errors={errors}
                    locations={locationsValue}
                    locationsLabel="Only show jobs in the following locations"
                    willWorkAnywhereLabel="Include jobs in any location"
                    onChange={handleChange}
                    willWorkAnywhere={willWorkAnywhereValue}
                    remoteWork={remoteWorkValue}
                  />

                  <Box mt={3}>
                    <Field
                      name="frequency"
                      id="frequency"
                      component={(props) => (
                        <Select
                          label="How often do you want to get alerts?"
                          name="frequency"
                          {...props}
                          value={frequencyValue}
                          options={JOB_ALERT_FREQUENCIES}
                          onChange={(e) => setFieldValue('frequency', e)}
                        />
                      )}
                    />
                  </Box>
                </>
              )}

              <ActionsFooter
                errorMessage={errorMessage}
                loading={loading}
                saveAsSubmit
                onBack={onBack}
                saveText={saveText}
                saveDisabled={disableSave && !dirty}
              />
            </OnboardingLayoutContent>
          </Form>
        );
      }}
    </Formik>
  );
};

JobAlertsForm.propTypes = {
  disableSave: PropTypes.bool,
  errorMessage: PropTypes.node,
  initialValues: PropTypes.shape({
    mailTnTlJobAlerts: PropTypes.bool,
    userJobAlertPreference: PropTypes.shape({
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ),
      remoteWork: PropTypes.bool,
      willWorkAnywhere: PropTypes.bool,
      frequency: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  saveText: PropTypes.string,
  title: PropTypes.string,
};

JobAlertsForm.defaultProps = {
  disableSave: false,
  errorMessage: null,
  loading: false,
  onBack: () => {},
  saveText: undefined,
  title: '',
};

export default JobAlertsForm;
