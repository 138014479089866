export function getUserDisplayName(user, fallbackToEmail = true) {
  if (user.firstName) {
    return `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`;
  }

  if (fallbackToEmail && user.email) {
    return user.email;
  }

  return '';
}
