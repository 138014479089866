import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  loadCreateIntroductionRequest,
  loadedCreateIntroductionRequest,
  errorCreateIntroductionRequest,
  toggleIntroductionRequestModal,
  loadedUpdateIntroductionsRemaining,
} from '../actions/introductionRequestActions';

export const initialState = {
  visible: false,
  error: false,
  introductionSent: false,
  limit: -1,
  remaining: -1,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = handleActions(
  {
    [toggleIntroductionRequestModal]: produce((draft, action) => {
      const { payload } = action;
      const { organization, source } = payload || {};
      draft.visible = !draft.visible;
      draft.error = false;
      draft.introductionSent = false;
      draft.organization = organization;
      draft.source = source;
      return draft;
    }),
    [loadCreateIntroductionRequest]: produce((draft) => {
      draft.error = false;
      draft.introductionSent = false;
      return draft;
    }),
    [loadedCreateIntroductionRequest]: produce((draft) => {
      draft.error = false;
      draft.introductionSent = true;
      draft.remaining -= 1;
      return draft;
    }),
    [errorCreateIntroductionRequest]: produce((draft) => {
      draft.error = true;
      return draft;
    }),
    [loadedUpdateIntroductionsRemaining]: produce((draft, action) => {
      const { payload } = action;
      const { dailyLimit: limit, dailyBalance: remaining } = payload.data;

      draft.limit = limit;
      draft.remaining = remaining;
      return draft;
    }),
  },
  initialState,
);

export default reducer;
