import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'rebass/styled-components';

const StyledLink = ({ node, ...props }) => (
  <Link
    target="_blank"
    rel="noopener noreferrer"
    data-testid="link-decorator"
    tx="markdown"
    variant="link"
    {...props}
  />
);

StyledLink.propTypes = {
  node: PropTypes.object.isRequired,
};

export default StyledLink;
