import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass/styled-components';

export const Pre = ({ node, ...props }) => (
  <Text as="pre" data-testid="code-decorator" tx="markdown" variant="pre" {...props} />
);

Pre.propTypes = {
  node: PropTypes.object.isRequired,
};
