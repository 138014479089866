import CareerInformationStep from './CareerInformationStep';
import CreateAccountStep from './CreateAccountStep';
import NetworkIntroductionStep from './NetworkIntroductionStep';
import PersonalInformationStep from './PersonalInformationStep';
import ProfileVisibilityStep from './ProfileVisibilityStep';
import SkillsStep from './SkillsStep';
import USWorkAuthorizationStep from './USWorkAuthorizationStep';
import WorkLocationStep from './WorkLocationStep';
import JobAlertsStep from './JobAlertsStep';
import { skillsDeactivatedSelector } from '../../redux/selectors/network';

export const steps = [
  {
    component: NetworkIntroductionStep,
    nextButtonText: 'Build your profile',
    title: 'Welcome',
    showInSidebar: false,
  },
  {
    component: CreateAccountStep,
    title: 'Create your account',
    showInSidebar: true,
  },
  {
    component: PersonalInformationStep,
    title: 'Who you are',
    showInSidebar: true,
  },
  {
    component: CareerInformationStep,
    title: `What you're looking for`,
    showInSidebar: true,
  },
  {
    component: SkillsStep,
    title: `What you're great at`,
    showInSidebar: true,
  },
  {
    component: WorkLocationStep,
    title: `Where you'd like to work`,
    showInSidebar: true,
  },
  {
    component: USWorkAuthorizationStep,
    title: `Where you'd like to work`,
    showInSidebar: false,
    visibilitySelector: 'wantsToWorkInUSSelector',
  },
  {
    component: JobAlertsStep,
    title: 'Job alerts',
    showInSidebar: true,
  },
  {
    component: ProfileVisibilityStep,
    title: 'Who can see your profile',
    showInSidebar: true,
    nextButtonText: 'Save and finish',
  },
];

export const filterOnboardingSteps = ({ skillsDeactivated }) => {
  let result = steps;

  if (skillsDeactivated) {
    result = result.filter((step) => step.component !== SkillsStep);
  }

  return result;
};

const useOnboardingSteps = ({ network }) => {
  const skillsDeactivated = skillsDeactivatedSelector(network);

  const matchingStep = filterOnboardingSteps({ skillsDeactivated });
  matchingStep.network = network;

  return matchingStep;
};

export default useOnboardingSteps;
