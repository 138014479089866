import { call, put, takeLatest } from 'redux-saga/effects';
import {
  loadedApiPasswordResetRequest,
  loadApiPasswordResetRequest,
  errorApiPasswordResetRequest,
  loadApiPasswordReset,
  loadedApiPasswordReset,
  errorApiPasswordReset,
} from '../actions/passwordActions';
import * as API from '../../api';
import SentryAPI from '../../api/SentryAPI';

export function* loadApiPasswordResetRequestWorker({ payload }) {
  const { values, network } = payload;
  const { id: collectionId } = network;

  try {
    yield call(API.post, '/users/passwords', {
      ...values,
      collectionId,
    });

    yield put(loadedApiPasswordResetRequest());
  } catch (e) {
    yield call(SentryAPI.trackError, 'request password change', {
      ...values,
      collectionId,
      e,
    });
    yield put(errorApiPasswordResetRequest(e));
  }
}

export function* loadApiPasswordResetWorker({ payload }) {
  const { values } = payload;
  try {
    yield call(API.put, '/users/me', {
      ...values,
    });

    yield put(loadedApiPasswordReset());
  } catch (e) {
    yield call(SentryAPI.trackError, 'Update User Failed', {
      ...values,
      e,
    });
    yield put(errorApiPasswordReset(e));
  }
}

function* passwordWatcher() {
  yield takeLatest(loadApiPasswordResetRequest().type, loadApiPasswordResetRequestWorker);
  yield takeLatest(loadApiPasswordReset().type, loadApiPasswordResetWorker);
}

export default passwordWatcher;
