import { createSelector } from 'reselect';

const introductionRequestSelector = (state) => state.introductionRequest;

export const makeSelectIntroductionRequestModalVisible = createSelector(
  introductionRequestSelector,
  (introductionRequest) => introductionRequest.visible,
);

export const makeSelectIntroductionRequestOrganization = createSelector(
  introductionRequestSelector,
  (introductionRequest) => introductionRequest.organization,
);

export const makeSelectIntroductionRequestError = createSelector(
  introductionRequestSelector,
  (introductionRequest) => introductionRequest.error,
);

export const makeSelectIntroductionRequestSent = createSelector(
  introductionRequestSelector,
  (introductionRequest) => introductionRequest.introductionSent,
);

export const makeSelectIntroductionRequestsRemaining = createSelector(
  introductionRequestSelector,
  (introductionRequest) => introductionRequest.remaining,
);

export const makeSelectIntroductionRequestsLimit = createSelector(
  introductionRequestSelector,
  (introductionRequest) => introductionRequest.limit,
);
