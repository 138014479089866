import { put, select, takeLatest, call } from 'redux-saga/effects';
import Router from 'next/router';
import { setCookie } from 'nookies';
import {
  setOnboardingFormData,
  submitOnboardingSuccess,
  submitOnboardingFailure,
  submitOnboardingRequest,
} from '../actions/onboardingActions';
import { loginSuccessRequest } from '../actions/userActions';
import prepareRequestData, { rehydrateLocationsDetails } from '../../helpers/userDataRequestHelper';
import UserAPI from '../../api/UserAPI';
import { removeItem } from '../../helpers/localStorageHelper';
import { onboardingFormDataSelector } from '../selectors/onboarding';
import {
  errorTalentOnboardingSubmit,
  loadedTalentOnboardingSubmit,
  loadTalentOnboardingSubmit,
  talentOnboardingCompleted,
} from '../actions/talentOnboardingActions';
import { loadedCreateJobAlert, submitJobAlertCtaClicked } from '../actions/jobAlertActions';
import NetworkAPI from '../../api/NetworkAPI';

export function* submitOnboardingRequestWorker({ payload }) {
  const { form = {}, network } = payload;
  const { id: networkId } = network;
  yield put(setOnboardingFormData(form));
  const formData = yield select(onboardingFormDataSelector);
  const data = yield call(prepareRequestData, formData, networkId, true);

  try {
    let user;
    if (!data.id) {
      user = yield call(UserAPI.create, data);
    } else {
      user = yield call(UserAPI.update, data);
    }
    user.locations = yield call(rehydrateLocationsDetails, user);
    yield call(removeItem, 'talentGroupCode');
    const { authenticationToken } = user;
    yield call(setCookie, null, 'token', authenticationToken, {
      maxAge: 60 * 60 * 24,
      path: '/',
    });
    yield put(loginSuccessRequest({ user, network }));
    yield put(submitOnboardingSuccess({ user, network }));
    yield call(Router.push, '/jobs', undefined, { shallow: true });
  } catch (e) {
    const { response } = e;
    const { data: responseData, status, statusText } = response;
    if (status === 422) {
      const { error } = responseData;
      yield put(submitOnboardingFailure(error));
    } else {
      yield put(submitOnboardingFailure([statusText]));
    }
  }
}

export function* loadTalentOnboardingSubmitEvent({ payload }) {
  const { onboarding, network } = payload;
  try {
    const { data: jobAlert, meta } = yield call(NetworkAPI.saveOnboardingData, network.id, onboarding);
    yield put(loadedTalentOnboardingSubmit(meta));

    if (jobAlert) {
      const { id, attributes } = jobAlert;
      yield put(loadedCreateJobAlert({ jobAlert: { id, ...attributes } }));
      yield put(submitJobAlertCtaClicked({ network, source: 'talent_network_signup' }));
    }

    yield put(talentOnboardingCompleted({ ...meta, network }));
  } catch (error) {
    yield put(errorTalentOnboardingSubmit());
  }
}

export default function* submitOnboardingRequestWatcher() {
  yield takeLatest(submitOnboardingRequest().type, submitOnboardingRequestWorker);
  yield takeLatest(loadTalentOnboardingSubmit().type, loadTalentOnboardingSubmitEvent);
}
