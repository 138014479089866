export const wantsToWorkInUSSelector = (formData) => {
  const { locations = [] } = formData;

  const index = locations.findIndex((workLocation) => {
    const { country } = workLocation;
    const { name } = country || {};

    return name === 'USA' || name === 'United States';
  });

  return index !== -1;
};
