import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import useAnalytics from '../../../hooks/useAnalytics';
import { setItem } from '../../../helpers/localStorageHelper';
import CustomScripts from '../../molecules/CustomScripts';
import useAdDev from '../../../hooks/useAdDev';
import { updateReferrer, updateNavigationHistory } from '../../../redux/actions/page';
import { NetworkSchema } from '../../../schema/network';
import useMolFeatureFlags from '../../../hooks/useMolFeatureFlags';
import useBasepathFix from '../../../hooks/useBasepathFix';

const AppWrapper = ({ network, children }) => {
  useBasepathFix();
  useAdDev();
  useMolFeatureFlags();
  const dispatch = useDispatch();
  const router = useRouter();
  const { trackPageView, shouldTrackPageView } = useAnalytics({ network });
  const [previousRoute, setPreviousRoute] = useState();

  const beforeHistoryChange = (url) => {
    const previousPath = previousRoute && previousRoute.split('?')[0].split('#')[0];
    const currentPath = url && url.split('?')[0].split('#')[0];
    dispatch(updateReferrer({ referrer: `${window.location.origin}${url}` }));
    if (previousPath && currentPath !== previousPath) {
      dispatch(updateNavigationHistory({ url: window.location.href.split(window.location.origin)[1] }));
    }
  };

  useEffect(() => {
    // this is what triggers analytic page views on everything EXCEPT the first request (that is done in _app.js)
    const handleRouteChange = (route) => {
      const didPageChange = shouldTrackPageView(previousRoute, route);
      if (didPageChange) {
        trackPageView();
      }
      setPreviousRoute(route);
    };
    const { tg } = router.query;
    if (tg) {
      setItem('talentGroupCode', tg);
    }

    const onPopState = () => {
      if (window.location.pathname !== router.pathname) {
        const backpath = `${window.location.pathname}${window.location.search}${window.location.hash}`;
        router.push(backpath, undefined, { shallow: true, scroll: false });
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', onPopState);
    }
    router.events.on('beforeHistoryChange', beforeHistoryChange);
    router.events.on('routeChangeComplete', handleRouteChange);

    // Unsubscribe on unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('beforeHistoryChange', beforeHistoryChange);

      if (typeof window !== 'undefined') {
        window.removeEventListener('popstate', onPopState);
      }
    };
  });

  return (
    <>
      <Head>
        {network && network.metaFaviconUrl && <link rel="shortcut icon" href={network?.metaFaviconUrl || ''} />}
      </Head>
      {children}
      {network && <CustomScripts network={network} />}
    </>
  );
};
AppWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  network: NetworkSchema,
};

AppWrapper.defaultProps = {
  network: null,
};

export default AppWrapper;
