import produce from 'immer';
import { handleActions } from 'redux-actions';
import { setLayoutFeature } from '../actions/layout';

export const initialState = {};

/* eslint-disable default-case, no-param-reassign */
const userProfileReducer = handleActions(
  {
    [setLayoutFeature]: produce((draft, action) => {
      const { key, value } = action.payload;
      draft[key] = value;
      return draft;
    }),
  },
  initialState,
);

export default userProfileReducer;
