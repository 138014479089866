export default {
  genericError: 'Something went wrong, please try again.',
  validation: {
    linkedinUrlError: 'Your profile URL should look like http://linkedin.com/in/your-user-name.',
    passwordLength: 'Password should be 8 characters at least.',
    requiredField: 'This is a required field.',
    selectAtLeastOne: 'Please select at least one option.',
    termsAccepted: 'Accepting terms of service and privacy policy is required.',
    urlError: 'Please, enter a valid website URL or domain.',
  },
  profile: {
    visibility: [
      { label: 'Visible to companies', value: 'visible' },
      { label: 'Hidden from some companies and members', value: 'restricted' },
      { label: 'Hidden from companies', value: 'hidden' },
    ],
    jobSearchStatuses: {
      not_looking: 'Not looking',
      casually_browsing: 'Casually browsing',
      actively_looking: 'Actively looking',
    },
    seniorities: {
      job_level_unspecified: { title: 'Unspecified', description: '' },
      entry_level: {
        title: 'Entry level',
        description: '0-2 years experience',
      },
      experienced: { title: 'Experienced', description: '2+ years of experience' },
      manager: {
        title: 'Manager',
        description: 'Entry- to mid-level managers responsible for managing a team',
      },
      director: {
        title: 'Director',
        description: 'Senior-level managers responsible for managing teams of managers',
      },
      executive: {
        title: 'Executive',
        description: 'Executive-level managers and above, including C-level positions',
      },
    },
  },
};
