import React from 'react';
import PropTypes from 'prop-types';

import PersonalInformationForm from '../../../components/molecules/PersonalInformationForm';

import { OnboardingPropTypes } from '../initialValues';

const PersonalInformationStep = ({ formData, network, nextStep, prevStep }) => {
  const { name: networkName, features } = network;
  const mentorshipDeactivated = (features && features.includes('mentorship_deactivated')) || false;

  const handleSubmit = (values) => nextStep(values);

  return (
    <PersonalInformationForm
      initialValues={formData}
      networkName={networkName}
      onSubmit={handleSubmit}
      onBack={prevStep}
      mentorshipDeactivated={mentorshipDeactivated}
      title="Now, tell us a bit about yourself so we can help you find an awesome job."
      saveText="Next step"
    />
  );
};

PersonalInformationStep.propTypes = {
  formData: OnboardingPropTypes.isRequired,
  network: PropTypes.shape({
    name: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default PersonalInformationStep;
