import { put, takeLatest, call, select, throttle } from 'redux-saga/effects';
import {
  allJobsSuccess,
  allJobsFailure,
  allJobsRequest,
  loadJobRequest,
  loadedJobRequest,
  errorJobRequest,
  loadFavoriteJob,
  loadDiscardJob,
  loadedDiscardJob,
  loadedFavoriteJob,
  loadFavoriteJobsList,
  loadedFavoriteJobsList,
  loadDiscardedJobsList,
  loadedDiscardedJobsList,
  errorFavoriteJob,
  errorDiscardJob,
  errorFavoriteJobsList,
  errorDiscardedJobsList,
} from '../actions/jobs';
import SearchClient from '../../api/SearchClient';
import SentryAPI from '../../api/SentryAPI';
import { get } from '../../api';
import { userSelector } from '../selectors/user';
import GetroClient from '../../api/GetroClient';

export function* allJobsRequestEvent({ payload }) {
  const { network } = payload;
  const query = Object.keys(payload.query).reduce((acc, key) => {
    switch (key) {
      case 'head_count':
      case 'industry_tags':
      case 'stage':
        acc[`organization.${key}`] = payload.query[key];
        break;
      default:
        acc[key] = payload.query[key];
    }

    return acc;
  }, {});

  delete query.stage;
  delete query.headCount;
  delete query.industryTags;

  if (query.page) {
    query.page = parseInt(query.page, 10) - 1;
  }

  Object.keys(query).forEach((key) => {
    if (key.includes('organization.topics')) {
      if (!('organization.topics' in query)) {
        query['organization.topics'] = {};
      }

      query['organization.topics'][key.replace('organization.topics.', '')] = query[key];

      delete query[key];
    }
  });

  try {
    const response = yield call(SearchClient.findJobs, {
      query: query.q,
      network,
      page: query.page,
      filters: query,
    });
    yield put(allJobsSuccess(response));
  } catch (e) {
    yield put(allJobsFailure({ error: e }));
  }
}

export function* loadJobEvent({ payload }) {
  const { jobSlug, network } = payload;
  try {
    const result = yield call(get, `/jobs/${jobSlug}?collection_id=${network.id}`);
    const { data } = result;
    yield put(loadedJobRequest(data));
  } catch (e) {
    if (typeof window === 'undefined' || window.navigator.onLine) {
      yield call(SentryAPI.trackError, 'Load Job Event', {
        networkId: network.id,
        jobSlug,
        e,
      });
    }

    yield put(errorJobRequest(e));
  }
}

export function* favoriteJobEvent({ payload }) {
  const user = yield select(userSelector);
  const jobId = payload.job.id;
  const { favorite, network } = payload;
  try {
    yield call(GetroClient.favoriteJob, { network, jobId, favorite, user });
    yield put(loadedFavoriteJob({ job: payload.job, favorite }));
  } catch (e) {
    yield put(errorFavoriteJob({ error: e }));
  }
}

export function* discardJobEvent({ payload }) {
  const user = yield select(userSelector);
  const jobId = payload.job.id;
  const { discarded, network } = payload;
  try {
    // todo: call the api to discard the job
    yield call(GetroClient.discardJob, { network, jobId, discarded, user });
    yield put(loadedDiscardJob({ job: payload.job, discarded }));
  } catch (e) {
    yield put(errorDiscardJob({ error: e }));
  }
}

export function* loadFavoriteJobsListEvent({ payload }) {
  const { page, network } = payload;
  try {
    const result = yield call(GetroClient.favoriteJobList, { network, page });
    yield put(loadedFavoriteJobsList(result));
  } catch (e) {
    yield put(errorFavoriteJobsList({ error: e }));
  }
}

export function* loadDiscardedJobsListEvent({ payload }) {
  const { network, page } = payload;
  try {
    const result = yield call(GetroClient.discardedJobList, { network, page });
    yield put(loadedDiscardedJobsList(result));
  } catch (e) {
    yield put(errorDiscardedJobsList({ error: e }));
  }
}

export default function* jobsInitializeRequestWatcher() {
  yield takeLatest(allJobsRequest().type, allJobsRequestEvent);
  yield throttle(1000, loadJobRequest().type, loadJobEvent);
  yield takeLatest(loadFavoriteJob().type, favoriteJobEvent);
  yield takeLatest(loadDiscardJob().type, discardJobEvent);
  yield takeLatest(loadFavoriteJobsList().type, loadFavoriteJobsListEvent);
  yield takeLatest(loadDiscardedJobsList().type, loadDiscardedJobsListEvent);
}
