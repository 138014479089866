import { handleActions } from 'redux-actions';
import produce from 'immer';
import { errorCustomFilters, loadedCustomFilters } from '../actions/customFilterActions';
import Sort from '../../helpers/sort';

export const initialState = {
  filters: [],
  initialized: false,
};

/* eslint-disable default-case, no-param-reassign */
const customFilters = handleActions(
  {
    [loadedCustomFilters]: produce((draft, action) => {
      const { filters } = action.payload;
      draft.filters = filters.map(({ options, ...otherAttributes }) => {
        const sortAlphabetic = options.length > 0 && !options[0].name.match(/^\d/);

        return {
          ...otherAttributes,
          options: sortAlphabetic ? Sort.alphabetic(options) : options,
        };
      });
      draft.initialized = true;
      return draft;
    }),
    [errorCustomFilters]: produce((draft) => {
      draft.filters = [];
      draft.initialized = true;
      return draft;
    }),
  },
  initialState,
);

export default customFilters;
