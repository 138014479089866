import produce from 'immer';
import { loadedLogoutRequest } from '../actions/userActions';

export const initialState = {};

/* eslint-disable default-case, no-param-reassign */
const apiReducer = produce((draft, action) => {
  if (action) {
    const { type } = action;
    const matches = /(LOAD_API|LOADED_API|ERROR_API)_(.*)/.exec(type);

    if (type === loadedLogoutRequest().type) {
      return initialState;
    }

    // not a LOAD_API* / LOADED_API* /  ERROR_API* actions, so we ignore them
    if (matches) {
      const [, requestState, requestName] = matches;
      const stateName = requestName
        .split('_')
        .map((word, index) => {
          let w = word.toLowerCase();
          if (index > 0) {
            w = w.charAt(0).toUpperCase() + w.slice(1);
          }
          return w;
        })
        .join('');

      switch (requestState) {
        case 'LOAD_API':
          draft[stateName] = {
            succeed: null,
            data: null,
          };
          break;

        default: {
          const { payload } = action;
          const { response } = payload || {};
          const { data = null } = response || {};

          draft[stateName] = {
            succeed: requestState === 'LOADED_API',
            data,
          };
          break;
        }
      }
    }
  }
  return draft;
}, initialState);

export default apiReducer;
