/* eslint-disable max-lines */
import getConfig from 'next/config';
import toast from 'react-hot-toast';
import { del, get, post, put } from './index';
import SentryAPI from './SentryAPI';
import { optimizeImage } from '../helpers/fileHelpers';
import Sort from '../helpers/sort';

const { publicRuntimeConfig } = getConfig();

class GetroClient {
  static excludedStatusCodes = [404, 403, 429];

  static async getCompanyDetails({ network, company, req }) {
    if (!network?.id) {
      throw new Error('Get Company Details: Network not provided');
    }

    if (!company?.slug) {
      throw new Error('Get Company Details: Company not provided');
    }

    try {
      const { apiV2BaseUrl: baseURL } = publicRuntimeConfig;

      const response = await get(`/collections/${network.id}/organizations/${company.slug}`, { baseURL }, {}, req);
      const { data: companyData } = response;
      const { data, included } = companyData;
      const { id, attributes } = data;

      const organizationSocialProfiles = included
        .filter((item) => item.type === 'organization_social_profile')
        .reduce((previous, current) => {
          const { attributes: socialProfileAttributes } = current;
          const { typeId, url } = socialProfileAttributes;
          return { ...previous, [`${typeId}Url`]: url };
        }, {});

      const topics = included
        .filter((item) => item.type === 'topic')
        .map((topic) => {
          const { id: topicId, attributes: topicAttributes } = topic;
          const { name } = topicAttributes;

          return { id: topicId, name };
        });

      const jobFunctions = included
        .filter((item) => item.type === 'job_function')
        .map((jobFunction) => {
          const { id: jobFunctionId, attributes: jobFunctionAttributes } = jobFunction;
          const { name } = jobFunctionAttributes;

          return { id: jobFunctionId, name };
        });

      const locations = included
        .filter((item) => item.type === 'location')
        .map((location) => {
          const { id: locationId, attributes: locationAttributes } = location;
          const { name, placeId } = locationAttributes;

          return { id: locationId, name, placeId };
        });

      const { logoUrl, liked, discarded, ...otherAttributes } = attributes;

      return {
        id: parseInt(id, 10),
        ...otherAttributes,
        ...organizationSocialProfiles,
        logoUrl: optimizeImage(logoUrl),
        topics,
        jobFunctions,
        locations,
        isFavorite: !!liked,
        isDiscarded: !!discarded,
      };
    } catch (e) {
      const { response } = e;
      if (response && !this.excludedStatusCodes.includes(response.status)) {
        SentryAPI.trackError('Unexpected error retrieving company details', {
          server: true,
          networkId: network?.id || undefined,
          company: { slug: company?.slug },
          errorMessage: e?.message,
          error: e,
        });
      }
      return null;
    }
  }

  static async favoriteJob({ network, jobId, favorite, user }) {
    try {
      let response;
      if (favorite) {
        response = await post(`/likes`, { like: { id: jobId, type: 'job' } });
      } else {
        response = await del(`/likes`, { params: { like: { id: jobId, type: 'job' } } });
      }
      const { data } = response;
      return data;
    } catch (e) {
      SentryAPI.trackError('Favorite job failed', { networkId: network.id, jobId, userId: user.id, e });
      throw e;
    }
  }

  static async discardJob({ network, jobId, discarded, user }) {
    try {
      let response;
      if (discarded) {
        response = await post(`/discards`, { discard: { id: jobId, type: 'job' } });
      } else {
        response = await del(`/discards`, { params: { discard: { id: jobId, type: 'job' } } });
      }
      const { data } = response;
      return data;
    } catch (e) {
      SentryAPI.trackError('Discard job failed', { networkId: network.id, jobId, userId: user.id, e });
      throw e;
    }
  }

  static async favoriteJobList({ req = {}, network, page = 1, perPage = 20 }) {
    try {
      const response = await get(
        `/users/jobs?collection_id=${network.id}&page=${page}&per_page=${perPage}`,
        {},
        {},
        req,
      );
      const { data } = response;
      data.items = data.items.map((job) => ({
        id: job.id,
        isFavorite: true,
        createdAt: new Date(job.postedAt).getTime() / 1000,
        locations: job.locations.map((loc) => loc.name),
        organization: job.organization,
        source: job.source,
        slug: job.slug,
        title: job.title,
        url: job.url,
      }));
      return data;
    } catch (e) {
      SentryAPI.trackError('Favorite job list failed', { networkId: network.id, e });
      throw e;
    }
  }

  static async discardedJobList({ req = {}, network, page = 1, perPage = 20 }) {
    try {
      const response = await get(
        `/users/discarded_jobs?collection_id=${network.id}&page=${page}&per_page=${perPage}`,
        {},
        {},
        req,
      );
      const { data } = response;
      data.items = data.items.map((job) => ({
        id: job.id,
        isFavorited: false,
        isDiscarded: true,
        createdAt: new Date(job.postedAt).getTime() / 1000,
        locations: job.locations.map((loc) => loc.name),
        organization: job.organization,
        source: job.source,
        slug: job.slug,
        title: job.title,
        url: job.url,
      }));
      return data;
    } catch (e) {
      SentryAPI.trackError('Discarded job list failed', { networkId: network.id, e });
      throw e;
    }
  }

  static async discardedCompaniesList({ req = {}, network, page = 1, perPage = 20 }) {
    try {
      const response = await get(`/users/discarded_organizations?page=${page}&per_page=${perPage}`, {}, {}, req);
      const { data } = response;
      data.items = data.items.map((item) => ({
        id: item.id,
        isFavorited: item.liked,
        isDiscarded: item.discarded,
        description: item.description,
        logoUrl: optimizeImage(item.logoUrl),
        name: item.name,
        slug: item.slug,
        activeJobsCount: item.jobsCount,
      }));
      return data;
    } catch (e) {
      SentryAPI.trackError('Discarded company list failed', { networkId: network.id, e });
      throw e;
    }
  }

  static async favoriteCompaniesList({ req = {}, network, page = 1, perPage = 20 }) {
    try {
      const response = await get(`/users/organizations?page=${page}&per_page=${perPage}`, {}, {}, req);
      const { data } = response;
      data.items = data.items.map((item) => ({
        id: item.id,
        isFavorited: item.liked,
        isDiscarded: item.discarded,
        description: item.description,
        logoUrl: optimizeImage(item.logoUrl),
        name: item.name,
        slug: item.slug,
        activeJobsCount: item.jobsCount,
      }));
      return data;
    } catch (e) {
      SentryAPI.trackError('Favorited company list failed', { networkId: network.id, e });
      throw e;
    }
  }

  static async favoriteCompany({ network, companyId, favorite, user }) {
    try {
      let response;
      if (favorite) {
        response = await post(`/likes`, { like: { id: companyId, type: 'organization' } });
      } else {
        response = await del(`/likes`, { params: { like: { id: companyId, type: 'organization' } } });
      }
      const { data } = response;
      return data;
    } catch (e) {
      SentryAPI.trackError('Favorite company failed', { networkId: network.id, companyId, userId: user.id, e });
      throw e;
    }
  }

  static async discardCompany({ network, companyId, discarded, user }) {
    try {
      let response;
      if (discarded) {
        response = await post(`/discards`, { discard: { id: companyId, type: 'organization' } });
      } else {
        response = await del(`/discards`, { params: { discard: { id: companyId, type: 'organization' } } });
      }
      const { data } = response;
      return data;
    } catch (e) {
      SentryAPI.trackError('Discard company failed', { networkId: network.id, userId: user.id, companyId, e });
      throw e;
    }
  }

  static async getAllCompanies({ networkId }) {
    try {
      const response = await get(`collections/${networkId}/organizations/all`);
      const { data } = response;
      data.items = data.items.map((item) => ({ ...item, value: item.id }));
      data.items = Sort.alphabetic(data.items);
      return data;
    } catch (e) {
      const { response } = e;
      if (response && response.status === 418) {
        toast('Looks like you are offline', {
          icon: 'warning',
          id: 'connection_lost_toast',
          notificationContent: 'Check your connection and refresh the page.',
          duration: 10000,
        });

        return { items: [], error: e };
      }

      const errorDetails = {
        networkId,
        errorName: e?.name,
        errorMessage: e?.message,
        statusCode: response?.status,
        stack: e?.stack,
        responseData: response?.data,
      };

      if (response && !this.excludedStatusCodes.includes(response.status)) {
        SentryAPI.trackError('fetch all companies error', errorDetails);
      }
      return { items: [] };
    }
  }

  static async getScripts({ network }) {
    try {
      const response = await get(`collections/${network.id}/job_board_scripts`);
      const { data } = response;
      const { items } = data;
      return items;
    } catch (e) {
      const { response } = e;
      if (response && !this.excludedStatusCodes.includes(response.status)) {
        SentryAPI.trackError('fetch custom scripts error', { networkId: network?.id, e });
      }
      throw e;
    }
  }

  static async acceptIntroduction(id, token) {
    try {
      const response = await put(
        `introductions/${id}/accept`,
        {},
        {},
        {
          'X-User-Token': token,
        },
      );
      const { data } = response;
      return data;
    } catch (e) {
      SentryAPI.trackError('accept introduction request', { introductionRequestId: id });
      throw e;
    }
  }

  static async declineIntroduction(id, token) {
    try {
      const response = await put(
        `introductions/${id}/decline`,
        {},
        {},
        {
          'X-User-Token': token,
        },
      );
      const { data } = response;
      return data;
    } catch (e) {
      SentryAPI.trackError('decline introduction request', { introductionRequestId: id });
      throw e;
    }
  }

  static async sendJobApplication({ jobId, networkId, ...payload }) {
    try {
      const { apiV2BaseUrl: baseURL } = publicRuntimeConfig;
      await post(`/collections/${networkId}/jobs/${jobId}/job_applications`, payload, { baseURL }, {});
      return true;
    } catch (e) {
      const { response } = e;
      if (response && response.status !== 422) {
        SentryAPI.trackError('failed to apply for job', {
          jobId,
          networkId,
          e,
        });
      }
      throw e;
    }
  }
}

export default GetroClient;
