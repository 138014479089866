export const base64ToBlob = (base64, mimeType) => {
  // Decode the Base64 string
  const byteString = atob(base64);

  // Create an array buffer and a view (as a byte array)
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i += 1) {
    intArray[i] = byteString.charCodeAt(i);
  }

  // Create a blob from the array buffer with the specified MIME type
  const blob = new Blob([arrayBuffer], { type: mimeType });

  return blob;
};
