import { createSelector } from 'reselect';

export const onboardingSelector = (state) => state.onboarding;
export const stepSelector = createSelector(onboardingSelector, (onboarding) => {
  const { step = 0 } = onboarding;
  return step;
});
export const onboardingSubmittingSelector = createSelector(onboardingSelector, (onboarding) => {
  const { submitting = false } = onboarding;
  return submitting;
});
export const onboardingFormDataSelector = createSelector(onboardingSelector, (onboarding) => {
  const { formData } = onboarding;
  return formData;
});
export const onboardingErrorsSelector = createSelector(onboardingSelector, (onboarding) => {
  const { errors } = onboarding;
  return errors;
});
