import { useMemo } from 'react';
import { addProtocolToUrl } from '../helpers/urlHelper';

const usePrivacyPolicyUrl = (network) => {
  const privacyPolicyUrl = useMemo(() => {
    const { externalPrivacyPolicyUrl } = network || {};

    if (externalPrivacyPolicyUrl) {
      return addProtocolToUrl(externalPrivacyPolicyUrl);
    }

    return '/privacy-policy';
  }, [network]);

  const isExternal = useMemo(() => {
    const { externalPrivacyPolicyUrl } = network || {};

    return (
      externalPrivacyPolicyUrl !== null &&
      externalPrivacyPolicyUrl !== undefined &&
      externalPrivacyPolicyUrl.trim() !== ''
    );
  }, [network]);

  return { privacyPolicyUrl, isExternal };
};

export default usePrivacyPolicyUrl;
