/* eslint-disable max-lines */
import { takeLatest, call, select } from 'redux-saga/effects';
import { talentNetworkHeroCtaClicked, claimYourProfileHeroCtaClicked, wlcGetroLogoClicked } from '../actions/layout';
import { talentNetworkIntroCtaClicked } from '../actions/talentNetworkActions';
import {
  loadFavoriteJob,
  loadDiscardJob,
  moreJobsRequest,
  jobLinkClicked,
  applyToJobClicked,
  loadedJobApplicationSubmitted,
  flagWrongDataClick,
} from '../actions/jobs';
import { loadDeleteAccount } from '../actions/accountSettingsActions';
import {
  loadFavoriteCompany,
  loadDiscardCompany,
  moreCompaniesRequest,
  flagWrongDataClicked,
  claimProfileClicked,
} from '../actions/companies';

import {
  analyticsUrlLocationInfo,
  isBot,
  isPreview,
  commonProperties,
  selectedSkillsEvents,
} from '../../helpers/analyticsHelper';
import SegmentAPI from '../../api/SegmentAPI';
import { loginSsoError, loginSuccessRequest, loadedLogoutRequest, toggleSignUpModal } from '../actions/userActions';
import { companyWebsiteLinkClick } from '../actions/companyDetails';
import {
  jobSeekerOnboardingFinishStep,
  jobSeekerOnboardingStart,
  signupTakenEmail,
  submitOnboardingRequest,
  submitOnboardingSuccess,
} from '../actions/onboardingActions';
import {
  loadedCreateIntroductionRequest,
  loadedUpdateIntroductionsRemaining,
} from '../actions/introductionRequestActions';
import { updatedFilters } from '../actions/filters';
import { userSelector } from '../selectors/user';
import { loadedAcceptIntroduction, loadedRejectIntroduction } from '../actions/introductionsActions';
import { talentNetworkSelector } from '../selectors/network';
import { loadUpdateUserProfile } from '../actions/profileActions';
import {
  loadUnsubscribeJobAlert,
  myJobAlertsCtaClicked,
  submitJobAlertCtaClicked,
  subscribeToJobAlertsCtaClicked,
} from '../actions/jobAlertActions';
import { trackWantsMoreAlertsSurveyClick } from '../../components/organisms/wantsMoreAlertsSurvey/actions';
import {
  talentOnboardingCompleted,
  talentOnboardingCompleteStep,
  talentOnboardingCtaClick,
  talentOnboardingResumeTooBig,
} from '../actions/talentOnboardingActions';

export function* trackEvent({ network, eventName, properties }) {
  const user = yield select(userSelector);
  const urlInfo = analyticsUrlLocationInfo();
  // the angular.js dashboard was naming the props differently we keep these names that don't make sense to not break any existing dashboard
  // TODO: review the analytics dashboards and remove unneeded props
  try {
    urlInfo.location = urlInfo.url;
    urlInfo.url = urlInfo.path;
    delete urlInfo.path;
    if (!network.loaded) return;
    if (isBot()) return;
    if (isPreview(urlInfo.host, network.previewUrl, network.url)) return;

    yield call(SegmentAPI.track, eventName, {
      ...properties,
      ...urlInfo,
      ...commonProperties(network, user, properties),
    });
  } catch (e) {
    throw new Error(
      `Analytics error - Track Event: ${eventName}: ${JSON.stringify({
        ...properties,
        ...urlInfo,
        ...commonProperties(network, user, properties),
      })}`,
    );
  }
}

export function* identifyUserEvent({ payload }) {
  const { user, network } = payload;
  const { id: userId, firstName, lastName, userTalentCollection } = user;
  const { isCompanyAdmin = false, isNetworkAdmin = false, isNetworkAmbassador = false } = userTalentCollection || {};
  const isJbNetworkAdmin = isNetworkAdmin;
  const talentNetwork = yield call(talentNetworkSelector, network);
  const isTnNetworkAdmin = isNetworkAdmin && talentNetwork;
  const name = `${firstName} ${lastName}`.trim() || '[Name not available]';

  yield call(SegmentAPI.identify, userId, network, {
    name,
    isNetworkAdmin,
    isCompanyAdmin,
    isNetworkAmbassador,
    isJbNetworkAdmin,
    isTnNetworkAdmin,
  });
}

export function* talentNetworkHeroCtaClickedEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'talent_network_hero_cta_click',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* claimYourProfileHeroCtaClickedEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'job_board:claim_your_profile_hero_cta_click',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* talentNetworkIntroCtaClickedEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'talent_network_intro_cta_click',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* loadFavoriteJobEvent({ payload }) {
  const { favorite, job, network } = payload;
  const eventName = favorite ? 'like_job' : 'unlike_job';
  const ev = { eventName, properties: { id: job.id, jobId: job.id }, network };
  yield call(trackEvent, ev);
}

export function* loadDiscardJobEvent({ payload }) {
  const { discarded, job, network } = payload;
  const eventName = discarded ? 'discard_job' : 'undiscard_job';

  const ev = { eventName, properties: { id: job.id, jobId: job.id }, network };
  yield call(trackEvent, ev);
}

export function* loadFavoriteCompanyEvent({ payload }) {
  const { favorite, company, network } = payload;
  const eventName = favorite ? 'like_organization' : 'unlike_organization';
  const ev = {
    eventName,
    properties: {
      id: company.id,
      organizationId: company.id,
      organizationName: company.name,
    },
    network,
  };
  yield call(trackEvent, ev);
}

function* loadDiscardCompanyEvent({ payload }) {
  const { discarded, company, network } = payload;
  const eventName = discarded ? 'discard_organization' : 'undiscard_organization';
  const ev = {
    eventName,
    properties: {
      id: company.id,
      organizationId: company.id,
      organizationName: company.name,
      type: 'organization',
    },
    network,
  };
  yield call(trackEvent, ev);
}

export function* toggleSignUpModalEvent({ payload }) {
  if (!payload) return;
  if (payload.button === undefined) return;

  const { type, id, button, network } = payload;
  const events = {
    like: 'like_button_click',
    discard: 'discard_button_click',
  };
  const eventName = events[button];
  const ev = {
    eventName,
    properties: {
      collectionName: network.name,
      id,
      jobId: id,
      type,
    },
    network,
  };
  yield call(trackEvent, ev);
}

export function* companyWebsiteLinkClickEvent({ payload }) {
  const { network, organizationName, id, origin } = payload;
  const ev = {
    eventName: 'organization_website_link_click',
    properties: {
      id,
      organizationName,
      organizationId: id,
      origin,
    },
    network,
  };
  yield call(trackEvent, ev);
}

export function* moreJobsRequestEvent({ payload }) {
  const { network } = payload;
  const ev = { eventName: 'load_more_jobs', properties: {}, network };

  yield call(trackEvent, ev);
}

export function* logoutRequestEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'user_logged_out',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* submitOnboardingSuccessEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'user_signed_up',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* loginSuccessRequestEvent({ payload }) {
  const { user, network } = payload;
  const {
    source,
    userTalentCollection: { collectionId },
    password,
    id,
  } = user;
  if (source !== 'login-form') return;
  const ev = {
    eventName: 'user_logged_in',
    properties: {
      collectionId,
      userId: id,
      type: password ? 'email' : 'sso',
      provider: password ? undefined : 'linkedin',
    },
    network,
  };
  yield call(trackEvent, ev);
}

export function* loadedCreateIntroductionRequestEvent({ payload }) {
  const {
    introducibleId: organizationId,
    name: organizationName,
    networkId,
    hasIntroductionsLimit,
    remaining,
    limit,
    network,
  } = payload;

  let ev;

  if (hasIntroductionsLimit && remaining - 1 === 0) {
    ev = {
      eventName: 'introductions:reach_daily_limit',
      properties: {
        limit,
        origin: 'job_board',
      },
      network,
    };
    yield call(trackEvent, ev);
  }

  ev = {
    eventName: 'request_introduction',
    properties: {
      id: organizationId,
      name: organizationName,
      type: 'organization',
      collectionId: networkId,
      organizationId,
    },
    network,
  };

  yield call(trackEvent, ev);
}

export function* loadDeleteAccountEvent({ payload }) {
  const { membershipId, network } = payload;
  const ev = {
    network,
    eventName: 'delete_account',
    properties: {
      userId: membershipId,
    },
  };
  yield call(trackEvent, ev);
}

export function* moreCompaniesRequestEvent({ payload }) {
  const { network, ...rest } = payload;
  const ev = {
    eventName: 'load_more_organizations',
    properties: { ...rest },
    network,
  };
  yield call(trackEvent, ev);
}

export function* jobSeekerOnboardingStartEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'job_seeker_onboarding_start',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* jobSeekerOnboardingFinishStepEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'job_seeker_onboarding_finish_step',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* submitOnboardingRequestEvent({ payload }) {
  const { network, ...properties } = payload;

  const ev = {
    eventName: 'job_seeker_onboarding_completed',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* signupTakenEmailEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'signup_taken_email',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* loginSsoErrorEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'sso_error',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* jobLinkClickedEvent({ payload }) {
  const {
    id,
    title,
    organizationId,
    network,
    featured,
    source,
    position,
    filtersApplied = false,
    query = '',
  } = payload;
  const ev = {
    eventName: 'job_external_link_click',
    properties: {
      id,
      jobId: id,
      title,
      organizationId,
      featured,
      job_source: source === 'career_page' ? 'extracted' : 'posted',
      position,
      filtersApplied,
      query,
    },
    network,
  };

  yield call(trackEvent, ev);
}

export function* wlcGetroLogoClickedEvent({ payload }) {
  const { network, ...properties } = payload;
  const ev = {
    eventName: 'wlc_monday_logo_click',
    properties,
    network,
  };
  yield call(trackEvent, ev);
}

export function* updatedFiltersEvent({
  payload: {
    filterName,
    mode,
    filterOptionAdded,
    totalActiveFilters,
    clearedOptionsCount,
    totalSelectedFilters,
    network,
    clearedFiltersCount,
    trackExtraParams,
  },
}) {
  const optionMode = mode === 'add' ? 'optionAdded' : 'optionRemoved';
  const ev = {
    eventName: 'job_board:filter_added',
    properties: {
      collectionId: network.id,
      filterName,
      [optionMode]: filterOptionAdded,
      totalActiveFilters,
      totalSelectedFilters,
      ...trackExtraParams,
    },
    network,
  };

  if (mode === 'clear') {
    ev.eventName = 'job_board:filter_cleared';
    ev.properties = {
      collectionId: network.id,
      filterName,
      clearedOptionsCount,
      ...trackExtraParams,
    };
  }

  if (mode === 'clear_all') {
    ev.eventName = 'job_board:all_filters_cleared';
    ev.properties = {
      collectionId: network.id,
      clearedOptionsCount,
      clearedFiltersCount,
      ...trackExtraParams,
    };
  }

  if (mode === 'remove') {
    ev.eventName = 'job_board:filter_removed';
  }
  yield call(trackEvent, ev);
}

export function* applyToJobClickedEvent({ payload }) {
  const { job, origin, network } = payload;
  const { id: jobId, organization, source } = job;
  const { id: organizationId } = organization;
  const ev = {
    eventName: 'job_apply_link_click',
    properties: {
      organizationId,
      jobId,
      origin,
      job_source: source === 'career_page' ? 'extracted' : 'posted',
    },
    network,
  };
  yield call(trackEvent, ev);
}

export function* flagWrongDataClickedEvent({ payload }) {
  const { network, ...properties } = payload;

  const ev = { eventName: 'flag_wrong_data_click', properties, network };
  yield call(trackEvent, ev);
}

export function* talentAcceptedIntroductionEvent({ payload }) {
  const { introduction, network, ...properties } = payload;
  const ev = {
    eventName: 'accept_introduction_talent',
    properties: {
      medium: 'email',
      from: introduction.introductionRequest.userId,
      to: introduction.introductionRequest.introducibleId,
      ...properties,
    },
    network,
  };
  yield call(trackEvent, ev);
}

export function* talentDeclinedIntroductionEvent({ payload }) {
  const { introduction, network, ...properties } = payload;

  const ev = {
    eventName: 'decline_introduction_talent',
    properties: {
      medium: 'email',
      from: introduction.introductionRequest.userId,
      to: introduction.introductionRequest.introducibleId,
      properties,
    },
    network,
  };
  yield call(trackEvent, ev);
}

export function* claimProfileClickedEvent({ payload }) {
  const { network, organizationName, id, collectionName, collectionId, origin } = payload;
  const ev = {
    eventName: 'claim_your_profile_click',
    properties: {
      id,
      organizationName,
      organizationId: id,
      collectionName,
      collectionId,
      origin,
    },
    network,
  };
  yield call(trackEvent, ev);
}

export function* loadedUpdateIntroductionsRemainingEvent({ payload }) {
  const { network, data } = payload;
  const { dailyBalance: remaining, dailyLimit: limit } = data;

  if (remaining !== 0) return;

  const ev = {
    eventName: 'introductions:no_introductions_available',
    properties: {
      limit,
      origin: 'job_board',
    },
    network,
  };

  yield call(trackEvent, ev);
}
export function* trackSkills(skills, network) {
  const evs = selectedSkillsEvents({ skills, network });
  // eslint-disable-next-line no-restricted-syntax
  for (const ev of evs) {
    yield call(trackEvent, ev);
  }
}
export function* profileSkillsUpdatedEvent({ payload }) {
  const { updatedUser, network } = payload;
  const { skills } = updatedUser;
  yield trackSkills(skills, network);
}

export function* onBoardingSkillsUpdatedEvent({ payload }) {
  const { form, network } = payload;
  const { skills } = form;
  yield trackSkills(skills, network);
}

export function* loadUnsubscribeJobAlertEvent({ payload }) {
  const { networkId, ...properties } = payload;

  const ev = {
    eventName: 'job_seeker_notifications:unsubscribe_cta_button_click',
    properties,
    network: { id: networkId, loaded: true },
  };
  yield call(trackEvent, ev);
}

export function* subscribeToJobAlertsCtaClickedEvent({ payload }) {
  const { networkId, ...properties } = payload;

  const ev = {
    eventName: 'job_seeker_notifications:subscribe_cta_button_click',
    properties,
    network: { id: networkId, loaded: true },
  };
  yield call(trackEvent, ev);
}

export function* trackWantsMoreAlertsSurveyClickEvent({ payload }) {
  const { network } = payload;

  const event = {
    eventName: 'job_seeker_notifications:multiple_alerts_interest',
    network,
  };

  yield call(trackEvent, event);
}

export function* trackMyJobAlertsCtaClickedEvent({ payload }) {
  const { network, ...properties } = payload;

  const event = {
    eventName: 'job_seeker_notifications:see_all_notifications',
    network,
    properties,
  };

  yield call(trackEvent, event);
}

export function* trackSubmitJobAlertCtaClickedEvent({ payload }) {
  const { network, ...properties } = payload;

  const event = {
    eventName: 'job_seeker_notifications:subscription_source',
    network,
    properties,
  };

  yield call(trackEvent, event);
}

export function* loadJobApplicationSubmittedEvent({ payload }) {
  const { network, ...properties } = payload;

  const event = {
    eventName: 'job_apply_form_submitted',
    network,
    properties: {
      job_id: properties.jobId,
      organization_id: properties.organizationId,
    },
  };

  yield call(trackEvent, event);
}

export function* talentOnboardingCompleteStepEvent({ payload }) {
  const { network, eventName, ...properties } = payload;

  const event = {
    eventName: `talent_network_signup:${eventName}`,
    network,
    properties,
  };

  yield call(trackEvent, event);
}

export function* talentOnboardingCompletedEvent({ payload }) {
  const { network, ...properties } = payload;

  const event = {
    eventName: 'talent_network_signup:completed',
    network,
    properties,
  };

  yield call(trackEvent, event);
}

export function* talentOnboardingResumeTooBigEvent({ payload }) {
  const { network } = payload;

  const event = {
    eventName: 'talent_network_signup:resume_too_big',
    network,
  };

  yield call(trackEvent, event);
}

export function* talentOnboardingCtaClickEvent({ payload }) {
  const { network, origin } = payload;

  const event = {
    eventName: 'talent_network_signup:cta_click',
    network,
    properties: {
      origin,
    },
  };

  yield call(trackEvent, event);
}

export function* flagWrongDataClickEvent({ payload }) {
  const { jobId, network } = payload;

  const event = {
    eventName: 'flag_wrong_data_click',
    network,
    properties: {
      job_id: jobId,
    },
  };

  yield call(trackEvent, event);
}

export default function* analyticsWatcher() {
  yield takeLatest(loadUpdateUserProfile().type, profileSkillsUpdatedEvent);
  yield takeLatest(submitOnboardingRequest().type, onBoardingSkillsUpdatedEvent);
  yield takeLatest(talentNetworkHeroCtaClicked().type, talentNetworkHeroCtaClickedEvent);
  yield takeLatest(talentNetworkIntroCtaClicked().type, talentNetworkIntroCtaClickedEvent);
  yield takeLatest(loadFavoriteJob().type, loadFavoriteJobEvent);
  yield takeLatest(applyToJobClicked().type, applyToJobClickedEvent);
  yield takeLatest(loadDiscardJob().type, loadDiscardJobEvent);
  yield takeLatest(moreJobsRequest().type, moreJobsRequestEvent);
  yield takeLatest(moreCompaniesRequest().type, moreCompaniesRequestEvent);
  yield takeLatest(toggleSignUpModal().type, toggleSignUpModalEvent);
  yield takeLatest(loadFavoriteCompany().type, loadFavoriteCompanyEvent);
  yield takeLatest(loadDiscardCompany().type, loadDiscardCompanyEvent);
  yield takeLatest(companyWebsiteLinkClick().type, companyWebsiteLinkClickEvent);
  yield takeLatest(loadedLogoutRequest().type, logoutRequestEvent);
  yield takeLatest(submitOnboardingSuccess().type, submitOnboardingSuccessEvent);
  yield takeLatest(loginSuccessRequest().type, loginSuccessRequestEvent);
  yield takeLatest(loginSuccessRequest().type, identifyUserEvent);
  yield takeLatest(loadDeleteAccount().type, loadDeleteAccountEvent);
  yield takeLatest(loadedCreateIntroductionRequest().type, loadedCreateIntroductionRequestEvent);
  yield takeLatest(jobLinkClicked().type, jobLinkClickedEvent);
  yield takeLatest(claimYourProfileHeroCtaClicked().type, claimYourProfileHeroCtaClickedEvent);
  yield takeLatest(updatedFilters().type, updatedFiltersEvent);
  yield takeLatest(jobSeekerOnboardingStart().type, jobSeekerOnboardingStartEvent);
  yield takeLatest(jobSeekerOnboardingFinishStep().type, jobSeekerOnboardingFinishStepEvent);
  yield takeLatest(submitOnboardingRequest().type, submitOnboardingRequestEvent);
  yield takeLatest(signupTakenEmail().type, signupTakenEmailEvent);
  yield takeLatest(wlcGetroLogoClicked().type, wlcGetroLogoClickedEvent);
  yield takeLatest(loginSsoError().type, loginSsoErrorEvent);
  yield takeLatest(flagWrongDataClicked().type, flagWrongDataClickedEvent);
  yield takeLatest(loadedAcceptIntroduction().type, talentAcceptedIntroductionEvent);
  yield takeLatest(loadedRejectIntroduction().type, talentDeclinedIntroductionEvent);
  yield takeLatest(claimProfileClicked().type, claimProfileClickedEvent);
  yield takeLatest(loadedUpdateIntroductionsRemaining().type, loadedUpdateIntroductionsRemainingEvent);
  yield takeLatest(loadUnsubscribeJobAlert().type, loadUnsubscribeJobAlertEvent);
  yield takeLatest(subscribeToJobAlertsCtaClicked().type, subscribeToJobAlertsCtaClickedEvent);
  yield takeLatest(trackWantsMoreAlertsSurveyClick().type, trackWantsMoreAlertsSurveyClickEvent);
  yield takeLatest(myJobAlertsCtaClicked().type, trackMyJobAlertsCtaClickedEvent);
  yield takeLatest(submitJobAlertCtaClicked().type, trackSubmitJobAlertCtaClickedEvent);
  yield takeLatest(loadedJobApplicationSubmitted().type, loadJobApplicationSubmittedEvent);
  yield takeLatest(talentOnboardingCompleteStep().type, talentOnboardingCompleteStepEvent);
  yield takeLatest(talentOnboardingCompleted().type, talentOnboardingCompletedEvent);
  yield takeLatest(talentOnboardingResumeTooBig().type, talentOnboardingResumeTooBigEvent);
  yield takeLatest(talentOnboardingCtaClick().type, talentOnboardingCtaClickEvent);
  yield takeLatest(flagWrongDataClick().type, flagWrongDataClickEvent);
}
