/* eslint-disable */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Search } from '@getro/rombo';
import NetworkAPI from '../../../api/NetworkAPI';
import { checkArrayHasDuplicatedElements } from '../../../helpers/arrayHelpers';
import { hasError } from '../../../helpers/formHelpers';

const SearchCompany = ({ form, label, networkId, name, onChange, value, ...otherProps }) => {
  const onSearchCompany = async (keyword) => {
    if (!keyword.length) {
      return [];
    }

    const companies = await NetworkAPI.activeCompanies(networkId, { keyword });
    return companies.map((company) => ({
      value: company.id,
      label: company.name,
    }));
  };
  const error = hasError(name, form);
  const className = useMemo(() => (error ? 'field error' : 'field'), [error]);

  const onCompanySelected = (e) => {
    const { target } = e;
    const { value: newValue } = target;

    if (Array.isArray(newValue)) {
      if (checkArrayHasDuplicatedElements(newValue)) return;
    }

    onChange(e);
  };

  return (
    <div className={className}>
      <Search
        {...otherProps}
        label={label}
        name={name}
        value={value}
        onChange={onCompanySelected}
        onSearch={onSearchCompany}
        orientation="horizontal"
        form={form}
        placeholder="Start typing..."
      />
    </div>
  );
};

SearchCompany.propTypes = {
  form: PropTypes.object,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  networkId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]),
};

SearchCompany.defaultProps = {
  label: null,
  multiple: false,
  value: null,
};

export default SearchCompany;
