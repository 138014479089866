/* eslint-disable max-lines */
import { baseTheme } from '@getro/rombo';
import merge from 'lodash.merge';
import { tag } from './tag';

const customTheme = {
  colors: {
    customPrimary: '#4900FF', // this is the primary color for the site to allow customization (but not replace all getro colors)
    customAccent: '#ffffff', // this is the accent color for the site to allow customization (but not replace all getro colors)
    loader: '#4900FF',
    navigationBar: '#4900FF',
  },
  breakpoints: ['40em', '65em'],
  buttons: {
    default: {
      fontFamily: 'body',
    },
    linkedIn: {
      variant: 'buttons.primary',
      bg: 'blue',
      color: 'white',
      borderColor: 'blue',
      ':hover': {
        bg: 'blue',
        color: 'white',
      },
    },
    cardControl: {
      variant: 'buttons.secondary',
      borderColor: 'primary',
      boxShadow: 'none',
      bg: 'transparent',
      borderRadius: 'circle',
      color: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      position: 'relative',
      zIndex: 2,
      mx: 1,
      p: 0,
      ':hover': {
        opacity: '1 !important',
      },
      ':focus': {
        opacity: '1 !important',
      },
    },
    companyFavorite: {
      variant: 'buttons.secondary',
      boxShadow: 'none',
      bg: 'white',
      color: 'primary',
      borderColor: 'primary',
      ':hover': {
        color: 'purple.500',
        borderColor: 'purple.500',
      },
    },
    cardLink: {
      cursor: 'pointer',
      variant: 'buttons.accent',
      bg: 'white',
      borderColor: 'customPrimary',
      zIndex: 1,
      ':after': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      ':hover': {
        bg: 'white',
      },
    },
    jobCardLink: {
      cursor: 'pointer',
      variant: 'buttons.accent',
      bg: 'white',
      border: 'none',
      zIndex: 1,
      ':after': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      ':hover': {
        bg: 'white',
      },
    },
    getroLink: {
      px: 2,
      fontSize: 2,
      position: 'relative',
      zIndex: 2,
      variant: 'buttons.tertiary',
      justifyContent: 'unset',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      marginRight: 3,
      padding: 0,
      ':hover': { textDecoration: 'underline' },
      backgroundColor: 'transparent',
    },
    listActions: {
      variant: 'buttons.tertiary',
      textAlign: 'left',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      py: 2,
      fontWeight: 'body',
      fontSize: 1,
      width: '100%',
    },
    subNav: {
      variant: 'buttons.tertiary',
      justifyContent: 'left',
      py: 2,
      px: [0],
      color: 'inherit',
      fontWeight: 'inherit',
      fontSize: 2,
      ':hover': {
        color: 'text.dark',
        fontWeight: 'semibold',
      },
      ':active': {
        color: 'text.dark',
        fontWeight: 'semibold',
      },
    },
    companyListItemAction: {
      variant: 'buttons.tertiary',
      ':hover': { cursor: 'pointer' },
      py: 2,
      flexGrow: 1,
      zIndex: 2,
      position: 'relative',
      fontSize: 1,
    },
    /* These are the buttons we normally theme */
    primary: {
      bg: 'customPrimary',
      color: 'customAccent',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'customPrimary',
    },
    heroCta: {
      variant: 'buttons.primary',
      fontSize: 3,
      px: 4,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'customPrimary',
    },
    accent: {
      variant: 'buttons.secondary',
      px: 3,
      textDecoration: 'none',
      py: 2,
      bg: 'customAccent',
      boxShadow: 'none',
      borderStyle: 'solid',
      borderWidth: '1px',
      transition: 'unset',
      borderColor: 'customPrimary',
      color: 'customPrimary',
      ':hover': {
        bg: 'inherit',
      },
    },
    anchorLink: {
      position: 'relative',
      zIndex: 2,
      justifyContent: 'unset',
      textDecoration: 'underline',
      cursor: 'pointer',
      borderRadius: 0,
      border: 'none',
      p: 0,
      bg: 'transparent',
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
    },
    sideBarNav: {
      variant: 'buttons.tertiary',
      lineHeight: 1.45,
      fontWeight: 'normal',
      fontSize: 3,
      color: 'text.subtle',
      '&.active': {
        fontWeight: 'semibold',
        color: 'text.dark',
      },
    },
  },
  fontWeights: {
    light: 200,
    default: 300,
  },
  text: {
    circle: {
      height: '2em',
      width: '2em',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'text.main',
      borderRadius: 'circle',
      color: 'text.main',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'medium',
    },
    clamp: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '3',
    },
    noResults: {
      fontSize: 1,
      fontStyle: 'italic',
    },
    listTitle: {
      fontSize: 0,
      fontWeight: 'normal',
      textTransform: 'uppercase',
      color: 'text.subtle',
      letterSpacing: '1px',
    },
    srOnly: {
      border: '0',
      clip: 'rect(,0, 0, 0, 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: '0',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    },
    legalTitle1: {
      mb: [2],
      fontWeight: 'semibold',
      color: 'black',
      fontSize: 5,
    },
    legalSubtitle1: {
      mb: [4],
      fontSize: 1,
      color: 'text.main',
    },
    legalTitle2: {
      mt: [4],
      mb: [3],
      fontWeight: 'body',
      color: 'black',
      fontSize: 4,
    },
    legalTitle3: {
      mt: [3],
      mb: [3],
      fontWeight: 'body',
      color: 'black',
      fontSize: 3,
    },
    legalParagraph: {
      fontSize: 2,
      mb: [3],
      color: 'text.main',
      lineHeight: 1.5,
    },
  },
  card: {
    default: {
      px: 3,
      py: 2,
      background: 'white',
      borderStyle: 'solid',
      borderColor: 'neutral.100',
      borderWidth: '1px',
      boxShadow: 'none',
    },
    filters: {
      px: 3,
      py: 2,
      background: 'white',
      border: 'none',
      boxShadow: 'xs',
      borderRadius: '4px',
    },
    listItem: {
      variant: 'card.default',
      transition: 'all ease .3s',
      position: 'relative',
      border: 'none',
      mx: '1px',
      my: ['2px', '8px'],
      '&.discarded': {
        boxShadow: 'none',
        cursor: 'default',
      },
      ':hover': {
        boxShadow: 'small',
        zIndex: 2,
        cursor: 'pointer',
      },
    },
    subscribeToJobAlerts: {
      variant: 'card.default',
      transition: 'all ease .3s',
      position: 'relative',
      borderTopColor: 'customPrimary',
      borderTopWidth: '4px',
      borderTopStyle: 'solid',
      mx: '1px',
      my: ['2px', '8px'],
      boxShadow: 'xs',
      borderRadius: '4px',
      p: [3, 4],
      '&:not(:last-child)': {
        marginBottom: [2],
      },
    },
    dropdownPanel: {
      variant: 'card.default',
      border: 'none',
      px: 0,
      pt: 0,
      width: ['100%', '280px'],
    },
    subNav: {
      bg: 'white',
    },
  },
  forms: {
    input: {
      borderColor: 'neutral.400',
      color: 'text.main',
      '&:focus': {
        outline: 'none !important',
        borderColor: 'neutral.700',
      },
    },
    search: {
      borderColor: 'transparent',
      backgroundColor: 'neutral.20',
      color: 'text.main',
      lineHeight: 2,
      transition: 'all ease .3s',
      '&:focus': {
        outline: 'none !important',
        borderColor: 'neutral.400',
        transition: 'all ease .3s',
      },
      '::-webkit-search-cancel-button': {
        '-webkit-appearance': 'none',
      },
    },
    onboardingRadio: {
      variant: 'forms.radio',
    },
    onboardingCustomRadio: {
      variant: 'forms.radio',
      'input[type="radio"]:checked ~ &&': {
        color: 'transparent',
        borderColor: 'neutral.700',
      },
      'input[type="radio"]:checked + &&': {
        bg: 'neutral.700',
      },
    },
    onboardingCheckbox: {
      variant: 'forms.checkbox',
    },
    onboardingCustomCheckbox: {
      variant: 'forms.checkbox',
      '&& > path': {
        display: 'none',
        color: 'neutral.700',
      },
      'input[type="checkbox"]:checked ~ &&': {
        borderColor: 'neutral.700',
        color: 'neutral.700',
        '& > path': {
          display: 'block',
          transform: 'scale(1.3333)',
          transformOrigin: 'center',
        },
      },
    },
    onboardingCustomInputContainer: {
      bg: 'neutral.20',
    },
    onboardingCustomInputContainerAccent: {
      bg: 'neutral.100',
    },
    onboardingInputContainer: {
      bg: 'neutral.20',
    },
    onboardingInputContainerAccent: {
      bg: 'purple.100',
    },
  },
  variants: {
    icon: {
      alignSelf: 'center',
      flexShrink: 0,
    },
    hr: {
      border: 'none',
      borderBottomStyle: 'solid',
      borderBottomColor: 'neutral.100',
      borderBottomWidth: '1px',
      flex: '1 1 auto',
    },
    cardOverlay: {
      background: 'transparent',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 4,
    },
    legalTableHeader: {
      textAlign: 'center',
      fontWeight: 'bold',
      p: 1,
      fontSize: 2,
      color: 'black',
      verticalAlign: 'top',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'neutral.100',
    },
    legalTableBody: {
      p: 1,
      fontSize: 2,
      color: 'text.main',
      verticalAlign: 'top',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'neutral.100',
      overflow: 'hidden',
    },
    inactiveJobMessage: {
      color: '#3F495A!important',
      fontWeight: '500',
    },
  },
  lists: {
    styleless: {
      listStyle: 'none',
      m: 0,
      p: 0,
    },
    subNav: {
      variant: 'lists.styless',
      color: 'text.subtle',
      '&.active': {
        color: 'text.dark',
        fontWeight: 'semibold',
      },
    },
  },
  fonts: {
    body: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu','Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
  },
  markdown: {
    pre: {
      whiteSpace: 'pre-wrap',
    },
    h1: {
      fontWeight: 'semibold',
      fontSize: 4,
      marginX: 'auto',
      marginBottom: 4,
      marginTop: '48px',
      color: 'text.dark',
      '&:first-child': {
        marginTop: 0,
      },
    },
    h2: {
      fontWeight: 'semibold',
      fontSize: 3,
      marginX: 'auto',
      marginBottom: 4,
      marginTop: 5,
      color: 'text.dark',
      '&:first-child': {
        marginTop: 0,
      },
    },
    paragraph: {
      color: 'text.main',
      fontWeight: 'body',
      fontSize: 2,
      marginTop: 0,
      marginBottom: 4,
    },
    link: {
      color: 'text.main',
      textDecoration: 'underline',
      '&:visited': {
        color: 'text.main',
      },
      marginBottom: 3,
    },
  },
  modals: {
    mobile: {
      wrapper: {
        variant: 'modals.default.wrapper',
        '[data-testid="close"]': {
          display: 'none',
        },
      },
      overlay: {
        variant: 'modals.default.overlay',
      },
      modal: {
        variant: 'modals.default.modal',
        position: 'fixed',
        bottom: '0',
        maxWidth: ['100%', '100%'],
        width: '100%',
        minHeight: 'calc(100% - 32px)',
        height: 'calc(100% - 32px)',
      },
      header: {
        variant: 'modals.default.header',
        mx: '0px',
        px: '16px',
        py: '16px',
      },
      closeButton: {
        variant: 'modals.default.closeButton',
      },
      title: {
        variant: 'modals.default.title',
        textAlign: 'center',
      },
      body: {
        variant: 'modals.default.body',
        width: '100%',
        flexGrow: 1,
        p: '0px',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          color: 'red',
          width: '3px',
        },
        '&::-webkit-scrollbar-track': {},
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
        },
        '&::-webkit-scrollbar-bar': {
          width: '12px',
        },
        scrollbarWidth: '3px',
      },
      footer: {
        variant: 'modals.default.footer',
        mx: '0px',
        px: '16px',
      },
    },
    large: {
      body: {
        p: 0,
      },
    },
  },
  tag,
};

// combine the baseTheme with any site specific theme
export const siteTheme = merge(baseTheme, customTheme);
