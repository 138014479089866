import { handleActions } from 'redux-actions';
import produce from 'immer';
import { errorJobFunctions, loadedJobFunctions } from '../actions/jobFunctionActions';

export const initialState = null;

/* eslint-disable default-case, no-param-reassign */
const jobFunctions = handleActions(
  {
    [loadedJobFunctions]: produce((draft, action) => {
      draft = action.payload || [];

      return draft;
    }),
    [errorJobFunctions]: produce((draft) => {
      draft = [];
      return draft;
    }),
  },
  initialState,
);

export default jobFunctions;
