import React from 'react';
import Head from 'next/head';
import parse from 'html-react-parser';
import { NetworkSchema } from '../../../schema/network';

const CustomScripts = ({ network }) => {
  const { scripts } = network || {};

  if (!scripts || scripts.length === 0) return null;

  const headerScripts = scripts.filter(({ header }) => header);
  const bodyScripts = scripts.filter(({ header }) => !header);

  return (
    <>
      {headerScripts.length > 0 && <Head>{parse(headerScripts.map(({ body }) => body).join(''))}</Head>}
      {bodyScripts.length > 0 && parse(bodyScripts.map(({ body }) => body).join(''))}
    </>
  );
};

CustomScripts.propTypes = {
  network: NetworkSchema,
};

CustomScripts.defaultProps = {
  network: null,
};

export default CustomScripts;
