import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  loadJobApplicationSubmitted,
  loadedJobApplicationSubmitted,
  errorJobApplicationSubmitted,
  resetErrorJobApplicationSubmitted,
} from '../actions/jobs';
import strings from '../../constants/strings';

export const initialState = {
  done: {},
  error: null,
};

/* eslint-disable default-case, no-param-reassign */
const jobsReducer = handleActions(
  {
    [loadJobApplicationSubmitted]: produce((draft, { payload }) => {
      draft = {
        error: null,
        done: {
          ...draft.done,
          [payload.jobId]: false,
        },
      };
      return draft;
    }),
    [loadedJobApplicationSubmitted]: produce((draft, { payload }) => {
      draft = {
        error: null,
        done: {
          ...draft.done,
          [payload.jobId]: true,
        },
      };
      return draft;
    }),
    [errorJobApplicationSubmitted]: produce((draft, { payload }) => {
      draft = {
        error: strings.genericError,
        done: {
          ...draft.done,
          [payload.jobId]: false,
        },
      };
      return draft;
    }),
    [resetErrorJobApplicationSubmitted]: produce((draft) => {
      draft = {
        ...draft,
        error: null,
      };
      return draft;
    }),
  },
  initialState,
);

export default jobsReducer;
