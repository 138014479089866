import { getGooglePlacePredictions, getGooglePlaceDetails } from '../helpers/googlePlaces';
import SentryAPI from './SentryAPI';

class GooglePlacesAPI {
  static async searchGoogle(query) {
    if (!query.length) {
      return Promise.resolve([]);
    }
    try {
      const result = await getGooglePlacePredictions(query);

      return Promise.resolve(
        result.map(({ description, place_id: placeId, terms }) => ({
          value: placeId,
          label: description,
          country: { name: terms.pop().value },
        })),
      );
    } catch (e) {
      SentryAPI.trackError('Search locations failed', { query, e });
      return Promise.resolve([]);
    }
  }

  static async placeDetails(placeId) {
    try {
      const place = await getGooglePlaceDetails(placeId);
      if (place) {
        const { place_id: value, address_components: terms } = place;
        return Promise.resolve({
          value,
          country: { name: terms.pop().long_name },
        });
      }
      return Promise.resolve({ value: null, country: null });
    } catch (e) {
      return Promise.resolve({ value: null, country: null });
    }
  }
}

export default GooglePlacesAPI;
