import produce from 'immer';
import { handleActions } from 'redux-actions';
import { errorDeleteAccount, loadDeleteAccount } from '../actions/accountSettingsActions';

export const initialState = {};

/* eslint-disable default-case, no-param-reassign */
const deleteAccount = handleActions(
  {
    [errorDeleteAccount]: produce((draft, action) => {
      const { error } = action.payload.response.data;
      draft.error = error;
      return draft;
    }),
    [loadDeleteAccount]: produce((draft) => {
      draft.error = null;
      return draft;
    }),
  },
  initialState,
);

export default deleteAccount;
