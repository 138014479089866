import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass/styled-components';
import { OnboardingLayoutContent } from '@getro/rombo';
import MarkdownRenderer from '../../../components/atoms/MarkdownRenderer';

const NetworkIntroductionStep = ({ network, nextButtonText, nextStep }) => {
  const { name, tnSettings } = network;
  const { tnSignUpWelcomeText, tnSignUpWelcomeTitle } = tnSettings || {};
  const title = tnSignUpWelcomeTitle || `Welcome to the ${name} talent network`;

  return (
    <OnboardingLayoutContent nextStepText={nextButtonText} onNextStep={nextStep} title={title}>
      {tnSignUpWelcomeText && <MarkdownRenderer source={tnSignUpWelcomeText} />}

      {!tnSignUpWelcomeText && (
        <>
          <Text fs={3}>
            To begin building your profile and matching you with companies relevant to you, we&apos;d like to ask you a
            few questions about your previous experience and what you&apos;re looking for.
          </Text>

          <Text fs={3}>This will only take a few minutes, and you can always edit this information later.</Text>
        </>
      )}
    </OnboardingLayoutContent>
  );
};

NetworkIntroductionStep.propTypes = {
  network: PropTypes.shape({
    name: PropTypes.string,
    tnSettings: PropTypes.shape({
      tnSignUpWelcomeText: PropTypes.string,
      tnSignUpWelcomeTitle: PropTypes.string,
    }),
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
  nextButtonText: PropTypes.string,
};
NetworkIntroductionStep.defaultProps = {
  nextButtonText: null,
};

export default NetworkIntroductionStep;
