import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  resetJobAlertActionsState,
  loadCreateJobAlert,
  loadedCreateJobAlert,
  errorCreateJobAlert,
  loadUpdateJobAlert,
  loadedUpdateJobAlert,
  errorUpdateJobAlert,
  loadedJobAlert,
  loadUnsubscribeJobAlert,
  loadedUnsubscribeJobAlert,
  errorUnsubscribeJobAlert,
  resetLoadedJobAlert,
} from '../actions/jobAlertActions';

export const initialState = {
  create: {
    error: false,
    success: false,
  },
  update: { error: false, success: false },
  unsubscribe: { error: false, success: false },
  jobAlert: {},
};

/* eslint-disable default-case, no-param-reassign */
const jobAlertReducer = handleActions(
  {
    [resetJobAlertActionsState]: produce((draft) => {
      draft.create = {
        ...initialState.create,
      };
      draft.update = {
        ...initialState.update,
      };
      draft.unsubscribe = {
        ...initialState.unsubscribe,
      };
      return draft;
    }),
    [loadCreateJobAlert]: produce((draft) => {
      draft.create = {
        ...initialState.create,
      };
      return draft;
    }),
    [loadedCreateJobAlert]: produce((draft, action) => {
      const { payload } = action;
      const { jobAlert } = payload;

      draft.create.success = true;
      draft.create.error = false;
      draft.jobAlert = {
        ...jobAlert,
      };
      return draft;
    }),
    [errorCreateJobAlert]: produce((draft) => {
      draft.create.error = true;
      return draft;
    }),
    [loadUpdateJobAlert]: produce((draft) => {
      draft.update = {
        ...initialState.update,
      };
      return draft;
    }),
    [loadedUpdateJobAlert]: produce((draft, action) => {
      const {
        payload: { filters },
      } = action;

      const {
        organization_ids: organizationIds,
        custom_filters: customFilters,
        job_functions: jobFunctions,
        industry_tags: industryTags,
        head_count: headCount,
        ...otherFilters
      } = filters;
      draft.update.success = true;
      draft.update.error = false;
      draft.jobAlert = {
        ...draft.jobAlert,
        filters: {
          ...otherFilters,
          organizationIds,
          jobFunctions,
          customFilters,
          industryTags,
          headCount,
        },
      };
      return draft;
    }),
    [errorUpdateJobAlert]: produce((draft) => {
      draft.update.error = true;
      draft.update.success = false;

      return draft;
    }),
    [loadUnsubscribeJobAlert]: produce((draft) => {
      draft.unsubscribe = {
        ...initialState.unsubscribe,
      };
      return draft;
    }),
    [errorUnsubscribeJobAlert]: produce((draft) => {
      draft.unsubscribe.error = true;
      draft.unsubscribe.success = false;

      return draft;
    }),
    [loadedJobAlert]: produce((draft, action) => {
      const { payload } = action;
      draft.jobAlert = {
        ...payload,
      };
    }),
    [loadedUnsubscribeJobAlert]: produce((draft, action) => {
      const { payload: uuid } = action;

      if (draft.jobAlert.id === uuid) {
        draft.unsubscribe.success = true;
        draft.unsubscribe.error = false;
        draft.jobAlert = {};
      }
      return draft;
    }),
    [resetLoadedJobAlert]: produce((draft, action) => {
      const { payload: uuid } = action;

      if (draft.jobAlert.id === uuid) {
        draft.jobAlert = {};
      }
      return draft;
    }),
  },
  initialState,
);

export default jobAlertReducer;
