// Gets the file name for a file stored in our S3 bucket
// URL example: https://internal.sandbox2.getro.com/files?path=sandbox%2F467-Sample+pdf+2.pdf
export const getFileNameFromUrl = (url) => {
  if (typeof url !== 'string' || !url.length) {
    return url;
  }

  const parts = url.split('path=');
  const path = decodeURIComponent(parts[parts.length - 1]);
  const pathParts = path.split('/');
  const fileName = pathParts[pathParts.length - 1];
  return fileName.replace(/^(\d*-)/, '');
};

export const optimizeImage = (img) => {
  let logoUrl = img;
  if (logoUrl && logoUrl.indexOf('cdn.filepicker.io/api/file/') > -1) {
    const fileGuid = logoUrl.split('cdn.filepicker.io/api/file/')[1];
    logoUrl = `https://cdn.filestackcontent.com/output=f:webp,t:true,q:80,c:true/cache=expiry:max/resize=w:340/${fileGuid}`;
  } else if (logoUrl && logoUrl.indexOf('cdn.filestackcontent.com/') > -1) {
    const fileGuid = logoUrl.split('cdn.filestackcontent.com/')[1];
    logoUrl = `https://cdn.filestackcontent.com/output=f:webp,t:true,q:80,c:true/cache=expiry:max/resize=w:340/${fileGuid}`;
  }
  return logoUrl || null;
};

const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const readFileAsBase64 = (file, onLoad) => {
  const reader = new FileReader();
  reader.onload = () => {
    const content = arrayBufferToBase64(reader.result);

    onLoad(content);
  };
  reader.readAsArrayBuffer(file);
};
