import { createActions } from 'redux-actions';

export const {
  locationSuggestionsReset,
  locationSuggestionsRequest,
  locationSuggestionsSuccess,
  locationSuggestionsFailure,
} = createActions(
  'LOCATION_SUGGESTIONS_RESET',
  'LOCATION_SUGGESTIONS_REQUEST',
  'LOCATION_SUGGESTIONS_SUCCESS',
  'LOCATION_SUGGESTIONS_FAILURE',
);
