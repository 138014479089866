/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Flex, Text } from 'rebass/styled-components';

import { ActionsFooter, InputMultiple, Message, OnboardingLayoutContent, Select } from '@getro/rombo';
import useFeatureToggle from '../../../hooks/useFeatureToggle';

import { PROFILE_VISIBILITIES } from '../../../constants';
import SearchCompany from '../SearchCompany';
import STRINGS from '../../../constants/strings';

const formSchema = Yup.object()
  .shape({
    visibility: Yup.object()
      .shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
      .required(STRINGS.validation.requiredField),
    hideFromCompanies: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(),
          label: Yup.string().required(),
        }),
      )
      .nullable(),
    hideFromDomains: Yup.array().of(Yup.string()).nullable(),
  })
  .test('exclusionFilters', null, (obj) => {
    const { visibility, hideFromCompanies, hideFromDomains } = obj;
    const { value: visibilityValue } = visibility;

    if (visibilityValue !== 'restricted') return true;
    if ((hideFromCompanies && hideFromCompanies.length) || (hideFromDomains && hideFromDomains.length)) return true;

    return new Yup.ValidationError(
      'You must select at least one company or domain to exclude',
      null,
      'exclusionFilters',
    );
  });

const ProfileVisibilityForm = ({
  disableSave,
  errorMessage,
  initialValues,
  loading,
  networkId,
  networkName,
  onBack,
  onSubmit,
  title,
  saveText,
}) => {
  const restrictedProfileVisibilityActive = useFeatureToggle('restrictedProfileVisibility');
  const profileVisibilities = restrictedProfileVisibilityActive
    ? PROFILE_VISIBILITIES
    : PROFILE_VISIBILITIES.filter(({ value }) => value !== 'restricted');

  return (
    <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit} enableReinitialize>
      {({ dirty, values, setFieldValue, errors, touched }) => {
        const { visibility, hideFromCompanies, hideFromDomains } = values;

        const handleOnChange = (data) => {
          setFieldValue(data.name, data.value);
        };

        return (
          <Form>
            <OnboardingLayoutContent title={title}>
              {restrictedProfileVisibilityActive && (
                <Message mb={4}>
                  We believe that you should be in complete control over who sees your profile. Here, you can exclude
                  some companies or users from seeing your profile -- for example, your employer if you’d like to keep
                  your search confidential.
                </Message>
              )}

              {!restrictedProfileVisibilityActive && (
                <Text mt={0} mx="auto" mb={3}>
                  Choose whether or not to make your profile visible to companies in the {networkName} network. You can
                  always change this setting later in your profile settings.
                </Text>
              )}

              <Box mb={3} width={[1, 0.5]}>
                <Field
                  name="visibility"
                  id="visibility"
                  component={(props) => (
                    <Select
                      label="Profile visibility"
                      name="visibility"
                      {...props}
                      value={visibility}
                      options={profileVisibilities}
                      onChange={(e) => setFieldValue('visibility', e)}
                    />
                  )}
                />
              </Box>

              {visibility && visibility.value === 'restricted' && (
                <>
                  <Flex sx={{ columnGap: 4 }} flexDirection={['column', 'row']}>
                    <Box flex="1">
                      <Box mb={3}>
                        <Field
                          component={(data) => (
                            <SearchCompany
                              name="hideFromCompanies"
                              value={hideFromCompanies}
                              onChange={(e) => setFieldValue('hideFromCompanies', e.target.value)}
                              label="Hide from companies"
                              networkId={networkId}
                              multiple
                              {...data}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box flex="1">
                      <Box mb={3}>
                        <Field
                          component={(data) => (
                            <InputMultiple
                              className="field"
                              label="Hide from domains"
                              name="hideFromDomains"
                              value={hideFromDomains}
                              onChange={handleOnChange}
                              {...data}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                  </Flex>

                  {errors.exclusionFilters && touched.hideFromCompanies && touched.hideFromDomains && (
                    <Box
                      color="danger"
                      sx={{
                        marginTop: '0.5rem',
                      }}
                    >
                      {errors.exclusionFilters}
                    </Box>
                  )}
                </>
              )}

              <ActionsFooter
                errorMessage={errorMessage}
                loading={loading}
                saveAsSubmit
                onBack={onBack}
                saveText={saveText}
                saveDisabled={disableSave && !dirty}
              />
            </OnboardingLayoutContent>
          </Form>
        );
      }}
    </Formik>
  );
};

ProfileVisibilityForm.propTypes = {
  disableSave: PropTypes.bool,
  errorMessage: PropTypes.node,
  initialValues: PropTypes.shape({
    visibility: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    hideFromCompanies: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ).isRequired,
    hideFromDomains: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  loading: PropTypes.bool,
  networkId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  networkName: PropTypes.string,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  saveText: PropTypes.string,
  title: PropTypes.string,
};

ProfileVisibilityForm.defaultProps = {
  title: null,
  disableSave: false,
  onBack: null,
  saveText: 'Save',
  errorMessage: null,
  loading: false,
  networkName: '',
};

export default ProfileVisibilityForm;
