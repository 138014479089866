import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import merge from 'lodash.merge';
import LoadingBar from 'react-top-loading-bar';
import { useRouter } from 'next/router';
import { siteTheme } from '../../theme';
import GlobalStyle from '../../components/atoms/globalStyle';

const CustomTheme = ({ children, advancedTheme = {}, isV2Onboarding }) => {
  const router = useRouter();
  const ref = useRef(null);
  const [mounted, setMounted] = useState(false);
  const [theme, setTheme] = useState(siteTheme);

  const onRouteChangeStart = () => {
    ref.current?.continuousStart();
  };
  const onRouteChangeDone = () => {
    ref.current?.complete();
  };

  useEffect(() => {
    const element = document.getElementById('main-wrapper');

    setTheme(merge(siteTheme, advancedTheme));

    if (element) {
      if (isV2Onboarding) {
        element.style.background = '#ffffff';
        element.style.maxWidth = '100%';
      } else {
        element.style.background = '';
        element.style.maxWidth = '';
      }
    }
  }, [advancedTheme, isV2Onboarding]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeDone);
    router.events.on('routeChangeError', onRouteChangeDone);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeDone);
      router.events.off('routeChangeError', onRouteChangeDone);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const body = (
    <ThemeProvider theme={theme}>
      <GlobalStyle theme={theme}>
        <LoadingBar color={theme.colors.loader} ref={ref} />
        {children}
      </GlobalStyle>
    </ThemeProvider>
  );

  // prevents ssr flash for mismatched dark mode

  return React.cloneElement(body, { style: { visibility: mounted ? 'visible' : 'hidden' } });
};
CustomTheme.propTypes = {
  children: PropTypes.node.isRequired,
  advancedTheme: PropTypes.object,
  isV2Onboarding: PropTypes.bool,
};

CustomTheme.defaultProps = {
  advancedTheme: {},
  isV2Onboarding: false,
};

export default CustomTheme;
