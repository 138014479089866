import produce from 'immer';
import { loadedLogoutRequest } from '../actions/userActions';

const initialState = {};

/* eslint-disable default-case, no-param-reassign */
const loadingReducer = produce((draft, action) => {
  if (action) {
    const { type } = action;
    const matches = /(LOAD|LOADED|ERROR)_(.*)/.exec(type);

    if (type === loadedLogoutRequest().type) {
      return initialState;
    }

    // not a LOAD_* / LOADED_* /  ERROR_* actions, so we ignore them
    if (matches) {
      const [, requestState, requestName] = matches;
      const stateName = requestName
        .split('_')
        .map((word, index) => {
          let w = word.toLowerCase();
          if (index > 0) {
            w = w.charAt(0).toUpperCase() + w.slice(1);
          }
          return w;
        })
        .join('');

      draft[stateName] = requestState === 'LOAD';
    }
  }
  return draft;
}, initialState);

export default loadingReducer;
