import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const getGooglePlacePredictions = (query) => {
  if (typeof window !== 'undefined') {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      fetch(`${publicRuntimeConfig.apiV2BaseUrl}/places/predictions?query=${query}`, {
        headers,
      }).then((response) => {
        if (!response.ok) {
          return reject(Error(`Google Places Error - Status: ${response.status}`));
        }
        return response.json().then(({ predictions }) => resolve(predictions));
      });
    });
  }
  return Promise.resolve(null);
};

const getGooglePlaceDetails = (placeId) => {
  if (typeof window !== 'undefined') {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      fetch(`${publicRuntimeConfig.apiV2BaseUrl}/places/details?place_id=${placeId}`, {
        headers,
      }).then((response) => {
        if (!response.ok) {
          return reject(Error(`Google Places Error - Status: ${response.status}`));
        }
        return response.json().then(({ place }) => resolve(place));
      });
    });
  }
  return Promise.resolve(null);
};

export { getGooglePlacePredictions, getGooglePlaceDetails };
