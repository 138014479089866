import { createStore, applyMiddleware } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import createSagaMiddleware from 'redux-saga';
import Router from 'next/router';
import { format } from 'url';
import { createRouterMiddleware, initialRouterState } from 'connected-next-router';
import getConfig from 'next/config';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { initialState as jobAlertInitialState } from './reducers/jobAlertReducer';

const { publicRuntimeConfig } = getConfig();
const { enableReduxDevTools } = publicRuntimeConfig;

let enableDevelopmentTools = process.env.NODE_ENV !== 'production';
if (enableReduxDevTools === '0') {
  enableDevelopmentTools = false;
}
const bindMiddleware = (middleware) => {
  if (enableDevelopmentTools) {
    // eslint-disable-next-line global-require
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore = (context) => {
  const routerMiddleware = createRouterMiddleware();
  const { asPath, pathname, query } = context.ctx || Router.router || {};
  let initialState;
  if (asPath) {
    const url = format({ pathname, query });
    initialState = {
      router: initialRouterState(url, asPath),
    };
  }

  // 1: Create the middleware
  const sagaMiddleware = createSagaMiddleware();

  // 2: Add an extra parameter for applying middleware:
  const loadInitialState = () => {
    let state = initialState;

    if (typeof window === 'undefined') return state;

    const jobAlert = localStorage.getItem('jobAlert');
    if (jobAlert) {
      state = {
        ...initialState,
        jobAlert: {
          ...jobAlertInitialState,
          jobAlert: JSON.parse(jobAlert),
        },
      };
    }

    return state;
  };

  const store = createStore(rootReducer, loadInitialState(), bindMiddleware([sagaMiddleware, routerMiddleware]));
  store.subscribe(() => {
    if (typeof window === 'undefined') return;

    const { jobAlert } = store.getState();
    const { jobAlert: storedJobAlert } = jobAlert;
    localStorage.setItem('jobAlert', JSON.stringify(storedJobAlert));
  });

  // 3: Run your sagas on server
  store.sagaTask = sagaMiddleware.run(rootSaga);

  // 4: now return the store:
  return store;
};

export const wrapper = createWrapper(makeStore, { debug: enableDevelopmentTools });
