import { createActions } from 'redux-actions';

export const { loadApiPasswordResetRequest, loadedApiPasswordResetRequest, errorApiPasswordResetRequest } =
  createActions(
    'LOAD_API_PASSWORD_RESET_REQUEST',
    'LOADED_API_PASSWORD_RESET_REQUEST',
    'ERROR_API_PASSWORD_RESET_REQUEST',
  );

export const { loadApiPasswordReset, loadedApiPasswordReset, errorApiPasswordReset } = createActions(
  'LOAD_API_PASSWORD_RESET',
  'LOADED_API_PASSWORD_RESET',
  'ERROR_API_PASSWORD_RESET',
);
