import { handleActions } from 'redux-actions';
import produce from 'immer';
import { loadedCompanyDetailsJobs } from '../actions/companyDetails';

export const initialState = {
  id: null,
  jobs: [],
};

/* eslint-disable default-case, no-param-reassign */
const companyDetails = handleActions(
  {
    [loadedCompanyDetailsJobs]: produce((draft, action) => {
      const { jobs } = action.payload;
      draft.jobs = jobs;
    }),
  },
  initialState,
);

export default companyDetails;
