import { createAction, createActions } from 'redux-actions';

export const updatedFilters = createAction('UPDATED_FILTERS');

export const { loadIndustryTags, loadedIndustryTags, errorIndustryTags } = createActions(
  'LOAD_INDUSTRY_TAGS',
  'LOADED_INDUSTRY_TAGS',
  'ERROR_INDUSTRY_TAGS',
);

export const { loadCompaniesHeadCount, loadedCompaniesHeadCount, errorCompaniesHeadCount } = createActions(
  'LOAD_COMPANIES_HEAD_COUNT',
  'LOADED_COMPANIES_HEAD_COUNT',
  'ERROR_COMPANIES_HEAD_COUNT',
);

export const { loadCompaniesStage, loadedCompaniesStage, errorCompaniesStage } = createActions(
  'LOAD_COMPANIES_STAGE',
  'LOADED_COMPANIES_STAGE',
  'ERROR_COMPANIES_STAGE',
);

export const { loadCompanyFacets, loadedCompanyFacets, errorCompanyFacets } = createActions(
  'LOAD_COMPANY_FACETS',
  'LOADED_COMPANY_FACETS',
  'ERROR_COMPANY_FACETS',
);
