const defaultTheme = {
  variant: 'tag.default',
  container: {
    variant: 'tag.default.container',
    bg: 'neutral.50',
    height: '32px',
    pl: '16px',
    mr: 0,
    cursor: 'pointer',
  },
  label: {
    variant: 'tag.default.label',
    mr: '4px',
    fontSize: '16px',
  },
};

const accentTheme = {
  variant: 'tag.accent',
  container: {
    variant: 'tag.accent.container',
    height: '32px',
    px: '16px',
    mr: 0,
    cursor: 'pointer',
  },
  label: {
    variant: 'tag.accent.label',
    mr: '4px',
    fontSize: '16px',
    fontWeight: '500',
    color: 'purple.400',
  },
};

export const tag = {
  onboarding: defaultTheme,
  onboardingCustom: defaultTheme,
  onboardingAccent: accentTheme,
  onboardingCustomAccent: {
    ...accentTheme,
    container: {
      ...accentTheme.container,
      bg: 'neutral.400',
    },
    label: {
      ...accentTheme.label,
      color: 'neutral.0',
    },
  },
  transparent: {
    variant: 'tag.default',
    container: {
      variant: 'tag.accent.container',
      bg: 'transparent',
      border: '1px solid',
      borderRadius: '100px',
      color: 'border.subtle',
      borderColor: 'border.subtle',
      height: 'auto',
      py: '4px',
    },
    label: {
      lineHeight: '16px',
      fontSize: '13px',
      color: 'text.subtle',
    },
  },
  white: {
    variant: 'tag.transparent',
    container: {
      variant: 'tag.transparent.container',
      bg: 'white',
    },
    label: {
      variant: 'tag.transparent.label',
      color: 'text.main',
    },
  },
};
