import { useEffect } from 'react';
import toast from 'react-hot-toast';

export const useNetworkAlert = () => {
  useEffect(() => {
    const onConnectionLost = () => {
      toast('Looks like you are offline', {
        icon: 'warning',
        id: 'connection_lost_toast',
        notificationContent: 'Check your connection and refresh the page.',
        duration: 10000,
      });
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('offline', onConnectionLost);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('offline', onConnectionLost);
      }
    };
  }, []);
};
