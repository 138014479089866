import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass/styled-components';

const H2 = ({ node, ...props }) => <Text as="h2" data-testid="h2-decorator" tx="markdown" variant="h2" {...props} />;

H2.propTypes = {
  node: PropTypes.object.isRequired,
};

export default H2;
