import React from 'react';
import PropTypes from 'prop-types';
import JobAlertsForm from '../../../components/molecules/JobAlertsForm';
import { OnboardingPropTypes } from '../initialValues';

const JobAlertsStep = ({ formData, nextStep, prevStep }) => (
  <JobAlertsForm
    initialValues={formData}
    onBack={prevStep}
    onSubmit={nextStep}
    saveText="Next step"
    title="Job alerts"
  />
);

JobAlertsStep.propTypes = {
  formData: OnboardingPropTypes.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default JobAlertsStep;
