import axios from 'axios';
import humps from 'humps';
import { parseCookies } from 'nookies';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const generateDefaultOptions = (req = null) => {
  const { apiBaseUrl } = publicRuntimeConfig;
  const ctx = {};
  if (req) {
    ctx.req = req;
  }
  const cookies = parseCookies(ctx);

  const defaultOptions = {
    baseURL: apiBaseUrl,
    headers: {
      Accept: 'application/json',
    },
    transformResponse: [...axios.defaults.transformResponse, (data) => humps.camelizeKeys(data)],
    transformRequest: [(data) => humps.decamelizeKeys(data), ...axios.defaults.transformRequest],
  };

  if (cookies?.token) {
    defaultOptions.headers['X-User-Token'] = cookies?.token;
  }

  return defaultOptions;
};

export const del = async (url, options = {}, headers = {}) => {
  const defaultOptions = generateDefaultOptions();
  return axios.delete(url, {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...headers,
    },
    ...options,
  });
};

export const get = async (url, options = {}, headers = {}, req) => {
  const defaultOptions = generateDefaultOptions(req);

  return axios.get(url, {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...headers,
    },
    ...options,
  });
};

export const post = async (url, data, options = {}, headers = {}) => {
  const defaultOptions = generateDefaultOptions();

  return axios.post(url, data, {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...headers,
    },
    ...options,
  });
};

export const put = async (url, data, options = {}, headers = {}) => {
  const defaultOptions = generateDefaultOptions();

  return axios.put(url, data, {
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...headers,
    },
    ...options,
  });
};

/**
 * This is a simple function that returns a promise that will allow a user to communicate with an api. By default it
 * assumes that the request method is 'get' but if the user desires other methods they can be used by sending a
 * method param in the option object as per axios documentation.
 *
 * @param url - The URL of the API you want to call
 * @param options - axios options for the api request as per axios docs: https://github.com/axios/axios
 * @returns {AxiosPromise}
 */
export const request = ({ url, options = {} }) => {
  const defaultOptions = generateDefaultOptions();
  const customOptions = defaultOptions;
  customOptions.method = 'get';
  return axios(url, {
    ...customOptions,
    headers: {
      ...defaultOptions.headers,
      ...options.headers,
    },
    ...options,
  });
};
