import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Field } from 'formik';
import { RadioButtons } from '@getro/rombo';
import { YES_OR_NO_VALUES } from '../../../constants/index';

const USWorkAuthorizationFields = ({ onChange, requireSponsorshipVisa, usWorkAuthorization }) => (
  <>
    <Box
      sx={{
        '& + &': {
          marginTop: 4,
        },
      }}
    >
      <Field
        component={(data) => (
          <RadioButtons
            name="usWorkAuthorization"
            label="Are you legally authorized to work in the United States?"
            elements={YES_OR_NO_VALUES}
            checkedValue={usWorkAuthorization}
            onChange={onChange}
            size="md"
            {...data}
          />
        )}
      />
    </Box>

    <Box
      sx={{
        '& + &': {
          marginTop: 4,
        },
      }}
    >
      <Field
        component={(data) => (
          <RadioButtons
            name="requireSponsorshipVisa"
            label="Do you now, or will you in the future, require sponsorship for employment visa status (e.g. H-1B visa status, etc.) to work legally in the United States?"
            elements={YES_OR_NO_VALUES}
            checkedValue={requireSponsorshipVisa}
            onChange={onChange}
            {...data}
          />
        )}
      />
    </Box>
  </>
);

USWorkAuthorizationFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  requireSponsorshipVisa: PropTypes.bool.isRequired,
  usWorkAuthorization: PropTypes.bool.isRequired,
};

export default USWorkAuthorizationFields;
