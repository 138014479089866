import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  errorCompaniesHeadCount,
  errorCompaniesStage,
  errorIndustryTags,
  loadCompaniesHeadCount,
  loadCompaniesStage,
  loadedCompaniesHeadCount,
  loadedCompaniesStage,
  loadedIndustryTags,
  loadIndustryTags,
  loadCompanyFacets,
  loadedCompanyFacets,
  errorCompanyFacets,
} from '../actions/filters';

export const initialState = {
  industryTags: {
    data: [],
    error: false,
  },
  headCount: {
    data: [],
    error: false,
  },
  stage: {
    data: [],
    error: false,
  },
};

/* eslint-disable default-case, no-param-reassign */
const filters = handleActions(
  {
    [loadIndustryTags]: produce((draft) => {
      draft.industryTags = initialState.industryTags;
    }),
    [loadedIndustryTags]: produce((draft, { payload: { data } }) => {
      draft.industryTags.data = data;

      return draft;
    }),
    [errorIndustryTags]: produce((draft) => {
      draft.industryTags.error = true;
      return draft;
    }),
    [loadCompaniesHeadCount]: produce((draft) => {
      draft.headCount = initialState.headCount;
    }),
    [loadedCompaniesHeadCount]: produce((draft, { payload: { data } }) => {
      draft.headCount.data = data;

      return draft;
    }),
    [errorCompaniesHeadCount]: produce((draft) => {
      draft.headCount.error = true;
      return draft;
    }),
    [loadCompaniesStage]: produce((draft) => {
      draft.stage = initialState.stage;
    }),
    [loadedCompaniesStage]: produce((draft, { payload: { data } }) => {
      draft.stage.data = data;

      return draft;
    }),
    [errorCompaniesStage]: produce((draft) => {
      draft.stage.error = true;
      return draft;
    }),
    [loadCompanyFacets]: produce((draft) => {
      draft = { ...initialState };
      return draft;
    }),
    [loadedCompanyFacets]: produce((draft, { payload: { data } }) => {
      draft.headCount.data = data.headCount;
      draft.stage.data = data.stage;
      draft.industryTags.data = data.industryTags;

      return draft;
    }),
    [errorCompanyFacets]: produce((draft) => {
      draft.headCount.error = true;
      draft.stage.error = true;
      draft.industryTags.error = true;
      return draft;
    }),
  },
  initialState,
);

export default filters;
