/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import ProfileVisibilityForm from '../../../components/molecules/ProfileVisibilityForm';
import { OnboardingPropTypes } from '../initialValues';

const ProfileVisibilityStep = ({
  errors,
  formData,
  network,
  nextStep,
  prevStep,
  onboardingSubmitting,
  nextButtonText,
}) => {
  const { id: networkId, name: networkName } = network;

  const onSubmit = (values) => {
    nextStep(values);
  };

  return (
    <ProfileVisibilityForm
      errorMessage={!!errors.length && errors.map((e, i) => <div key={i}>{e}</div>)}
      initialValues={formData}
      saveText={nextButtonText}
      loading={onboardingSubmitting}
      networkId={networkId}
      networkName={networkName}
      onBack={prevStep}
      onSubmit={onSubmit}
      title="Control who sees your profile"
    />
  );
};

ProfileVisibilityStep.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  formData: OnboardingPropTypes.isRequired,
  network: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  onboardingSubmitting: PropTypes.bool.isRequired,
  nextButtonText: PropTypes.string,
};

ProfileVisibilityStep.defaultProps = {
  errors: null,
  nextButtonText: null,
};

export default ProfileVisibilityStep;
