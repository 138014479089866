import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { Field } from 'formik';

import { Checkbox } from '@getro/rombo';
import SearchLocation from '../SearchLocation';

const WorkLocationFields = ({
  className,
  errors,
  onChange,
  locations,
  locationsLabel,
  remoteWork,
  willWorkAnywhere,
  willWorkAnywhereLabel,
  ...rebassProps
}) => (
  <Box className={className} {...rebassProps}>
    <Field
      component={(data) => (
        <Box mt={4}>
          <SearchLocation
            name="locations"
            value={willWorkAnywhere ? [] : locations}
            onChange={onChange}
            label={locationsLabel}
            multiple
            disabled={willWorkAnywhere}
            {...data}
          />
        </Box>
      )}
    />

    <Field
      name="willWorkAnywhere"
      component={(data) => (
        <Checkbox
          label={willWorkAnywhereLabel}
          name="willWorkAnywhere"
          checked={willWorkAnywhere}
          onChange={onChange}
          containerProps={{ mt: 3 }}
          {...data}
        />
      )}
    />

    <Field
      name="remoteWork"
      component={(data) => (
        <Checkbox
          label="I'm open to working remotely"
          name="remoteWork"
          checked={remoteWork}
          onChange={onChange}
          containerProps={{ mt: 3 }}
          {...data}
        />
      )}
    />

    {errors && errors.atLeastOneOptionSelected && (
      <Box mt={3} sx={{ color: 'danger' }}>
        {errors.atLeastOneOptionSelected}
      </Box>
    )}
  </Box>
);

WorkLocationFields.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  locationsLabel: PropTypes.string,
  remoteWork: PropTypes.bool.isRequired,
  willWorkAnywhere: PropTypes.bool.isRequired,
  willWorkAnywhereLabel: PropTypes.string,
};

WorkLocationFields.defaultProps = {
  className: '',
  errors: null,
  locationsLabel: 'Where in the world would you like to work?',
  willWorkAnywhereLabel: "I'm open to working everywhere",
};

export default WorkLocationFields;
