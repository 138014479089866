import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import {
  companiesListSuccess,
  companiesListRequest,
  companiesListFailure,
  loadDiscardCompany,
  loadFavoriteCompany,
  loadedFavoriteCompany,
  loadedDiscardCompany,
  loadDiscardedCompaniesList,
  loadedDiscardedCompaniesList,
  loadedFavoriteCompaniesList,
  loadFavoriteCompaniesList,
  errorFavoriteCompaniesList,
  errorDiscardCompany,
  errorDiscardedCompaniesList,
  errorFavoriteCompany,
} from '../actions/companies';
import SearchClient from '../../api/SearchClient';
import { userSelector } from '../selectors/user';
import GetroClient from '../../api/GetroClient';

export function* companiesListRequestEvent({ payload = { query: {} } }) {
  const { network } = payload;
  const query = { ...payload.query };

  if (query.page) {
    query.page = parseInt(query.page, 10) - 1;
  }

  Object.keys(query).forEach((key) => {
    if (key.includes('organization.topics')) {
      if (!('topics' in query)) {
        query.topics = {};
      }

      query.topics[key.replace('organization.topics.', '')] = query[key];
      delete query[key];
    }
  });

  try {
    const result = yield call(SearchClient.findCompanies, {
      query: query.q,
      network,
      page: query.page,
      filters: query,
    });
    const { companies, ...otherResultProperties } = result;

    const allPromises = [];
    for (let index = 0; index < companies.length; index += 50) {
      allPromises.push(companies.slice(index, index + 50));
    }

    const results = yield all(
      allPromises.map((item) =>
        call(SearchClient.findCompaniesJobTotals, {
          network,
          companies: item,
        }),
      ),
    );

    const companiesWithTotalJobsCount = results.flat(1);

    yield put(
      companiesListSuccess({
        companies: companiesWithTotalJobsCount,
        ...otherResultProperties,
      }),
    );
  } catch (e) {
    yield put(companiesListFailure());
  }
}

export function* favoriteCompanyEvent({ payload }) {
  const user = yield select(userSelector);
  const { network } = payload;
  const companyId = payload.company.id;
  const { favorite } = payload;
  try {
    yield call(GetroClient.favoriteCompany, { network, companyId, favorite, user });
    yield put(loadedFavoriteCompany({ company: payload.company, favorite }));
  } catch (e) {
    yield put(errorFavoriteCompany({ error: e }));
  }
}

export function* discardCompanyEvent({ payload }) {
  const user = yield select(userSelector);
  const companyId = payload.company.id;
  const { discarded, network } = payload;
  try {
    yield call(GetroClient.discardCompany, { network, companyId, discarded, user });
    yield put(loadedDiscardCompany({ company: payload.company, discarded }));
  } catch (e) {
    yield put(errorDiscardCompany({ error: e }));
  }
}

export function* discardedCompaniesList({ payload }) {
  const { network, page } = payload;
  try {
    const result = yield call(GetroClient.discardedCompaniesList, { network, page });
    yield put(loadedDiscardedCompaniesList(result));
  } catch (e) {
    yield put(errorDiscardedCompaniesList({ error: e }));
  }
}

export function* favoriteCompaniesList({ payload }) {
  const { page, network } = payload;
  try {
    const result = yield call(GetroClient.favoriteCompaniesList, { network, page });
    yield put(loadedFavoriteCompaniesList(result));
  } catch (e) {
    yield put(errorFavoriteCompaniesList({ error: e }));
  }
}

export default function* companiesRequest() {
  yield takeLatest(companiesListRequest().type, companiesListRequestEvent);
  yield takeLatest(loadFavoriteCompany().type, favoriteCompanyEvent);
  yield takeLatest(loadDiscardCompany().type, discardCompanyEvent);
  yield takeLatest(loadDiscardedCompaniesList().type, discardedCompaniesList);
  yield takeLatest(loadFavoriteCompaniesList().type, favoriteCompaniesList);
}
