import { takeLatest, put, call, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import {
  errorCreateJobAlert,
  loadCreateJobAlert,
  loadedCreateJobAlert,
  loadUpdateJobAlert,
  loadedUpdateJobAlert,
  errorUpdateJobAlert,
  loadUnsubscribeJobAlert,
  loadedUnsubscribeJobAlert,
  errorUnsubscribeJobAlert,
  verifyLoadedJobAlert,
  resetLoadedJobAlert,
  submitJobAlertCtaClicked,
} from '../actions/jobAlertActions';
import JobAlertsAPI from '../../api/JobAlertsAPI';
import { loadedJobAlertSelector } from '../selectors/jobAlertSelectors';

export function* loadCreateJobAlertEvent({ payload }) {
  const { network, silent, source, ...rest } = payload;
  try {
    const jobAlert = yield call(JobAlertsAPI.create, rest);
    yield put(loadedCreateJobAlert({ jobAlert }));
    if (!silent) {
      yield call(toast, 'You are now subscribed to job alerts', { icon: 'success', id: 'job_alert_api_response' });
    }
    yield put(submitJobAlertCtaClicked({ network, source }));
  } catch (e) {
    yield put(errorCreateJobAlert());
    yield call(toast, 'Job alert creation failed', { icon: 'danger', id: 'job_alert_api_response' });
  }
}

export function* loadUpdateJobAlertEvent({ payload }) {
  try {
    yield call(JobAlertsAPI.update, payload);
    yield put(loadedUpdateJobAlert({ filters: payload.filters }));
    yield call(toast, 'Job alerts preferences updated', { icon: 'success', id: 'job_alert_api_response' });
  } catch (e) {
    yield put(errorUpdateJobAlert(e));
    yield call(toast, 'Job alerts update failed', { icon: 'danger', id: 'job_alert_api_response' });
  }
}

export function* loadUnsubscribeJobAlertEvent({ payload }) {
  const { uuid } = payload;
  try {
    const success = yield call(JobAlertsAPI.unsubscribe, { token: uuid });
    if (success) {
      yield put(loadedUnsubscribeJobAlert(uuid));
    } else {
      yield put(errorUnsubscribeJobAlert(Error('already subscribed')));
    }
  } catch (e) {
    yield put(errorUnsubscribeJobAlert(e));
  }
}

export function* verifyLoadedJobAlertEvent() {
  const jobAlert = yield select(loadedJobAlertSelector);
  const { id: token } = jobAlert;

  try {
    if (token) {
      const jobAlertData = yield call(JobAlertsAPI.load, { token });
      const { status } = jobAlertData;

      if (status !== 'active') {
        yield put(resetLoadedJobAlert(token));
      }
    }
  } catch (e) {
    yield put(resetLoadedJobAlert(token));
  }
}

export default function* jobAlertWatcher() {
  yield takeLatest(loadCreateJobAlert().type, loadCreateJobAlertEvent);
  yield takeLatest(loadUpdateJobAlert().type, loadUpdateJobAlertEvent);
  yield takeLatest(verifyLoadedJobAlert().type, verifyLoadedJobAlertEvent);
  yield takeLatest(loadUnsubscribeJobAlert().type, loadUnsubscribeJobAlertEvent);
}
