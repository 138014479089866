import { call, put, takeLatest } from 'redux-saga/effects';
import { loginSuccessRequest, loginFailureRequest, userTokenAuthenticationRequest } from '../actions/userActions';
import UserAPI from '../../api/UserAPI';
import { rehydrateLocationsDetails } from '../../helpers/userDataRequestHelper';

export function* userTokenAuthenticationRequestWorker({ payload }) {
  const { token, source, password, network } = payload;
  try {
    const userData = yield call(UserAPI.me, token);
    userData.locations = yield call(rehydrateLocationsDetails, userData);
    // all users should have a userTalentCollection but depending on how the user is made some do not
    userData.userTalentCollection = userData.userTalentCollection || {};
    const { userTalentCollections } = userData;
    let { userTalentCollection } = userData;
    if (!userTalentCollection || !userTalentCollection.id) {
      userTalentCollection =
        userTalentCollections.find(({ collectionId }) => collectionId === parseInt(network.id, 10)) || {};
    }
    userData.userTalentCollection = userTalentCollection;

    yield put(
      loginSuccessRequest({
        user: {
          ...userData,
          source,
          password,
          authenticationToken: token,
        },
        network,
      }),
    );
  } catch (e) {
    yield put(loginFailureRequest());
  }
}

export function* userTokenAuthenticationRequestWatcher() {
  yield takeLatest(userTokenAuthenticationRequest().type, userTokenAuthenticationRequestWorker);
}

export default userTokenAuthenticationRequestWatcher;
