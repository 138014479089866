const mem = {};
const memStorage = {
  getItem: (key) => mem[key],
  removeItem: (key) => {
    delete mem[key];
  },
  setItem: (key, val) => {
    mem[key] = val;
  },
  _mem: mem,
};
export const storage = () => (typeof localStorage === 'undefined' ? memStorage : localStorage);

export function getItem(key) {
  return storage().getItem(key);
}

export function removeItem(key) {
  return storage().removeItem(key);
}
export function setItem(key, val) {
  return storage().setItem(key, val);
}
