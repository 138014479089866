import { handleActions } from 'redux-actions';
import produce from 'immer';

import {
  errorTalentOnboardingSubmit,
  loadedTalentOnboardingSubmit,
  loadTalentOnboardingSubmit,
} from '../actions/talentOnboardingActions';

export const initialState = {
  error: false,
  talentOnboarding: null,
};

/* eslint-disable default-case, no-param-reassign */
const talentNetworkOnboardingReducer = handleActions(
  {
    [loadTalentOnboardingSubmit]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [loadedTalentOnboardingSubmit]: produce((draft, { payload }) => {
      draft.talentOnboarding = payload;
      draft.error = false;
      return draft;
    }),
    [errorTalentOnboardingSubmit]: produce((draft) => {
      draft = {
        ...draft,
        error: true,
      };

      return draft;
    }),
  },
  initialState,
);

export default talentNetworkOnboardingReducer;
