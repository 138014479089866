import { put, takeLatest, call } from 'redux-saga/effects';
import getConfig from 'next/config';
import {
  loadJobApplicationSubmitted,
  errorJobApplicationSubmitted,
  loadedJobApplicationSubmitted,
} from '../actions/jobs';
import GetroClient from '../../api/GetroClient';
import { base64ToBlob } from '../../helpers/base64';

const { publicRuntimeConfig } = getConfig();

export function* uploadCV(cv) {
  try {
    if (!cv) return null;
    const { fileStackApiKey, fileStackApiUrl } = publicRuntimeConfig;

    const apiUrl = `${fileStackApiUrl}/store/S3?key=${fileStackApiKey}`;

    const formData = new FormData();
    formData.append('fileUpload', base64ToBlob(cv.content, 'application/pdf'), cv.name);

    const cvUploadResponse = yield fetch(apiUrl, {
      method: 'POST',
      body: formData,
    }).then((response) => response.json());

    return cvUploadResponse.url;
  } catch (error) {
    throw new Error(`Unable to upload cv :${error.message}`);
  }
}
export function* loadJobApplicationSubmittedEvent({ payload }) {
  const {
    network,
    jobId,
    cv,
    firstName,
    lastName,
    usVisaSponsorship,
    usWorkAuthorization,
    linkedinUrl,
    profileUrl,
    email,
    organizationId,
    additionalInfo,
  } = payload;
  try {
    const cvUrl = yield call(uploadCV, cv);

    const formattedLinkedinUrl = linkedinUrl.replace(/www./, '').replace(/^(?:(?:http(?:s)?|):\/\/)/, '');
    const applicationPayload = {
      jobId,
      networkId: network.id,
      firstName,
      lastName,
      email,
      linkedinUrl: `https://www.${formattedLinkedinUrl}`,
      usVisaSponsorship,
      usWorkAuthorization,
      additionalInfo,
    };

    if (cvUrl) {
      applicationPayload.cvUrl = cvUrl;
    }

    if (profileUrl) {
      applicationPayload.profileUrl = `https://www.${profileUrl
        .replace(/www./, '')
        .replace(/^(?:(?:http(?:s)?|):\/\/)/, '')}`;
    }

    yield call(GetroClient.sendJobApplication, applicationPayload);
    yield put(loadedJobApplicationSubmitted({ network, jobId, organizationId }));
  } catch (e) {
    yield put(errorJobApplicationSubmitted({ jobId }));
  }
}

export default function* jobApplicationWatcher() {
  yield takeLatest(loadJobApplicationSubmitted().type, loadJobApplicationSubmittedEvent);
}
