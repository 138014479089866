import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box } from 'rebass/styled-components';
import { Form, Formik } from 'formik';

import { ActionsFooter, OnboardingLayoutContent } from '@getro/rombo';
import STRINGS from '../../../constants/strings';
import WorkLocationFields from './WorkLocationFields';

import USWorkAuthorizationFields from '../USWorkAuthorizationForm/USWorkAuthorizationFields';
import { wantsToWorkInUSSelector } from '../../../containers/onboarding/stepVisibility';

const formSchema = Yup.object()
  .shape({
    willWorkAnywhere: Yup.boolean(),
    remoteWork: Yup.boolean(),
    locations: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      }),
    ),
  })
  .test('atLeastOneOptionSelected', null, (obj) => {
    if (obj.locations.length > 0 || obj.willWorkAnywhere || obj.remoteWork) {
      return true; // everything is fine
    }

    return new Yup.ValidationError(STRINGS.validation.selectAtLeastOne, null, 'atLeastOneOptionSelected');
  });

const WorkLocationForm = ({
  disableSave,
  includeUSWorkAuthorization,
  initialValues,
  onBack,
  onSubmit,
  saveText,
  title,
  errorMessage,
  updating,
}) => {
  const preparedInitialValues = {
    ...initialValues,
    willWorkAnywhere: initialValues.willWorkAnywhere === null ? false : initialValues.willWorkAnywhere,
    remoteWork: initialValues.remoteWork === null ? false : initialValues.remoteWork,
  };

  return (
    <Formik
      initialValues={preparedInitialValues}
      validationSchema={formSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      enableReinitialize
    >
      {({ dirty, values, setFieldValue, errors }) => {
        const { locations, remoteWork, requireSponsorshipVisa, usWorkAuthorization, willWorkAnywhere } = values;

        const handleChange = (e) => {
          const { target } = e;
          const { name, value } = target;

          if (target.checked !== undefined) {
            setFieldValue(name, target.checked);
            return;
          }

          setFieldValue(name, value);
        };

        const showUSWorkAuthorization = includeUSWorkAuthorization && wantsToWorkInUSSelector(values);

        const handleUsWorkAuthorizationChange = (e) => {
          const { target } = e;
          const { name, value } = target;

          setFieldValue(name, value === 'true');
        };

        return (
          <Form>
            <OnboardingLayoutContent title={title}>
              <WorkLocationFields
                errors={errors}
                locations={locations}
                onChange={handleChange}
                remoteWork={remoteWork}
                willWorkAnywhere={willWorkAnywhere}
              />

              {showUSWorkAuthorization && (
                <Box mt={4}>
                  <USWorkAuthorizationFields
                    onChange={handleUsWorkAuthorizationChange}
                    requireSponsorshipVisa={requireSponsorshipVisa}
                    usWorkAuthorization={usWorkAuthorization}
                  />
                </Box>
              )}

              <ActionsFooter
                saveDisabled={disableSave && !dirty}
                saveAsSubmit
                saveText={saveText}
                onBack={onBack}
                errorMessage={errorMessage}
                loading={updating}
              />
            </OnboardingLayoutContent>
          </Form>
        );
      }}
    </Formik>
  );
};

WorkLocationForm.propTypes = {
  disableSave: PropTypes.bool,
  errorMessage: PropTypes.node,
  includeUSWorkAuthorization: PropTypes.bool,
  initialValues: PropTypes.shape({
    locations: PropTypes.array,
    remoteWork: PropTypes.bool,
    willWorkAnywhere: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  saveText: PropTypes.string,
  title: PropTypes.string,
  updating: PropTypes.bool,
};

WorkLocationForm.defaultProps = {
  disableSave: false,
  includeUSWorkAuthorization: false,
  initialValues: {
    locations: [],
    remoteWork: false,
    willWorkAnywhere: false,
  },
  errorMessage: null,
  onBack: () => {},
  saveText: undefined,
  title: '',
  updating: false,
};

export default WorkLocationForm;
