import React from 'react';
import PropTypes from 'prop-types';

import WorkLocationForm from '../../../components/molecules/WorkLocationForm';

import { OnboardingPropTypes } from '../initialValues';

const WorkLocationStep = ({ formData, nextStep, prevStep }) => (
  <WorkLocationForm
    initialValues={formData}
    onSubmit={nextStep}
    onBack={prevStep}
    saveText="Next step"
    title="Where would you like to work, ideally?"
  />
);

WorkLocationStep.propTypes = {
  formData: OnboardingPropTypes.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default WorkLocationStep;
