import { takeLatest, put, call } from 'redux-saga/effects';
import {
  loadAcceptIntroduction,
  loadedAcceptIntroduction,
  errorAcceptIntroduction,
  loadRejectIntroduction,
  loadedRejectIntroduction,
  errorRejectIntroduction,
} from '../actions/introductionsActions';

import GetroClient from '../../api/GetroClient';

export function* loadAcceptIntroductionRequestWorker({ payload }) {
  const { token, id, network } = payload;

  try {
    const introduction = yield call(GetroClient.acceptIntroduction, id, token);
    yield put(loadedAcceptIntroduction({ network, introduction }));
  } catch (e) {
    yield put(errorAcceptIntroduction({ error: e }));
  }
}
export function* loadRejectIntroductionRequestWorker({ payload }) {
  const { token, id, network } = payload;

  try {
    const introduction = yield call(GetroClient.declineIntroduction, id, token);
    yield put(loadedRejectIntroduction({ network, introduction }));
  } catch (e) {
    yield put(errorRejectIntroduction({ error: e }));
  }
}
export default function* loadIntroductionsWatcher() {
  yield takeLatest(loadAcceptIntroduction().type, loadAcceptIntroductionRequestWorker);
  yield takeLatest(loadRejectIntroduction().type, loadRejectIntroductionRequestWorker);
}
