import PropTypes from 'prop-types';
import { JOB_SEARCH_STATUS_VALUES, PROFILE_VISIBILITIES, SENIORITIES, JOB_ALERT_FREQUENCIES } from '../../constants';
import { filesPropType } from '../../components/atoms/FileUpload';

const initialValues = {
  avatarUrl: '',
  linkedinId: '',
  firstName: '',
  lastName: '',
  email: '',
  mailTnTlJobAlerts: true,
  password: '',
  termsOfService: false,
  websiteUrl: '',
  linkedinUrl: '',
  dribbbleUrl: '',
  githubUrl: '',
  currentLocation: null,
  resume: [],
  bio: '',
  mentorship: false,
  jobSearchStatus: JOB_SEARCH_STATUS_VALUES[0].value,
  employmentTypes: [],
  seniority: SENIORITIES[0].value,
  skills: [],
  locations: [],
  willWorkAnywhere: false,
  remoteWork: false,
  usWorkAuthorization: false,
  requireSponsorshipVisa: true,
  visibility: PROFILE_VISIBILITIES[0],
  hideFromCompanies: [],
  hideFromDomains: [],
  userJobAlertPreference: {
    keywords: [],
    locations: [],
    remoteWork: false,
    willWorkAnywhere: false,
    frequency: JOB_ALERT_FREQUENCIES[1],
  },
};

const OnboardingPropTypes = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  termsOfService: PropTypes.bool,
  websiteUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  dribbbleUrl: PropTypes.string,
  githubUrl: PropTypes.string,
  resume: filesPropType,
  mentorship: PropTypes.bool,
  currentLocation: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  willWorkAnywhere: PropTypes.bool,
  remoteWork: PropTypes.bool,
  usWorkAuthorization: PropTypes.bool,
  requireSponsorshipVisa: PropTypes.bool,
  jobSearch: PropTypes.string,
  employmentTypes: PropTypes.arrayOf(PropTypes.string),
  seniority: PropTypes.string,
  visibility: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  hideFromCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  hideFromDomains: PropTypes.arrayOf(PropTypes.string),
  userJobAlertPreference: PropTypes.shape({
    keywords: PropTypes.arrayOf(PropTypes.string),
    remoteWork: PropTypes.bool,
    willWorkAnywhere: PropTypes.bool,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  }),
});

export { OnboardingPropTypes };

export default initialValues;
