import pino from 'pino';
import { inProduction, inTest } from './environmentHelpers';

const pinoConfig = {
  name: `platform-${process.env.NODE_ENV}`,
  level: inProduction() ? 'info' : 'debug',
  enabled: !inTest(),
  browser: {
    asObject: true,
  },
};

const logger = pino(pinoConfig);

export default logger;
