import { useSelector } from 'react-redux';
import { isBot, commonProperties, isPreview, analyticsUrlLocationInfo } from '../helpers/analyticsHelper';
import { inProduction, inTest } from '../helpers/environmentHelpers';
import { userSelector } from '../redux/selectors/user';
import logger from '../helpers/logger';
import { hasAdvancedDesignSelector, showJobAlertsSelector, talentNetworkSelector } from '../redux/selectors/network';
import SegmentAPI from '../api/SegmentAPI';
import { jobsSelector } from '../redux/selectors/jobs';

const useAnalytics = ({ network = { loaded: false } }) => {
  const user = useSelector(userSelector);
  const hasAdvancedDesign = hasAdvancedDesignSelector(network);
  const hasTalentDatabase = talentNetworkSelector(network);
  const showJobAlerts = showJobAlertsSelector(network);
  const job = useSelector(jobsSelector);

  if (!network) {
    return { trackPageView: () => {}, shouldTrackPageView: () => {} };
  }
  const { loaded, previewUrl } = network;
  const trackPageView = async () => {
    const urlInfo = analyticsUrlLocationInfo();

    if (!loaded) {
      return;
    }
    if (isBot()) {
      return;
    }
    if (isPreview(urlInfo.host, previewUrl, network.url)) {
      return;
    }
    const title = document ? document.title : null;
    const search = typeof window !== 'undefined' ? window.location.search.replace('?', '') : null;

    const properties = {
      ...urlInfo,
      custom_design: hasAdvancedDesign,
      user_agent: navigator.userAgent,
      talent_database: hasTalentDatabase,
      ...commonProperties(network, user),
      title,
      search,
      job_alerts: showJobAlerts,
    };

    if (job?.currentJob) {
      properties.page_type = 'job_description';
      properties.job_source = job?.currentJob?.source === 'career_page' ? 'extracted' : 'posted';
    }

    await SegmentAPI.page(properties);
    /* istanbul ignore next */
    if (!inProduction() && !inTest()) {
      logger.debug({ properties }, 'Track page view');
    }
  };

  const shouldTrackPageView = (previousRoute, currentRoute) => {
    const previousPath = previousRoute && previousRoute.split('?')[0].split('#')[0];
    return currentRoute.indexOf('?') === -1 && previousPath !== currentRoute;
  };

  return { trackPageView, shouldTrackPageView };
};

export default useAnalytics;
