import { put, select, takeEvery, call } from 'redux-saga/effects';
import { moveOnboardingForward, moveOnboardingTo, setOnboardingFormData } from '../actions/onboardingActions';
import { filterOnboardingSteps } from '../../containers/onboarding/steps';
import * as VisibilityFunctions from '../../containers/onboarding/stepVisibility';
import { skillsDeactivatedSelector } from '../selectors/network';
import { onboardingFormDataSelector, stepSelector } from '../selectors/onboarding';

export const checkNextStep = (currentStepIndex, formData, steps) => {
  let nextStepIndex = -1;
  for (let i = currentStepIndex + 1; i < steps.length && nextStepIndex === -1; i += 1) {
    const step = steps[i];

    const { visibilitySelector } = step;
    if (visibilitySelector === undefined) {
      nextStepIndex = i;
    } else {
      const visible = VisibilityFunctions[visibilitySelector](formData);
      if (visible) {
        nextStepIndex = i;
      }
    }
  }

  return nextStepIndex;
};

export function* moveOnboardingForwardRequestWorker(action) {
  const { payload: newFormData = {} } = action;

  const currentStepIndex = yield select(stepSelector);
  yield put(setOnboardingFormData(newFormData));
  const formData = yield select(onboardingFormDataSelector);
  const skillsDeactivated = yield select(skillsDeactivatedSelector);
  const steps = yield call(filterOnboardingSteps, {
    skillsDeactivated,
  });
  const nextStepIndex = yield call(checkNextStep, currentStepIndex, formData, steps);

  if (nextStepIndex !== -1) {
    yield put(moveOnboardingTo(nextStepIndex));
  }
}

export default function* moveOnboardingForwardRequestWatcher() {
  yield takeEvery(moveOnboardingForward().type, moveOnboardingForwardRequestWorker);
}
