import { createActions } from 'redux-actions';

export const { loadAllCompanies, loadedAllCompanies, errorAllCompanies } = createActions(
  'LOAD_ALL_COMPANIES',
  'LOADED_ALL_COMPANIES',
  'ERROR_ALL_COMPANIES',
);

export const {
  loadFavoriteCompany,
  loadedFavoriteCompany,
  errorFavoriteCompany,
  loadDiscardCompany,
  loadedDiscardCompany,
  errorDiscardCompany,
} = createActions(
  'LOAD_FAVORITE_COMPANY',
  'LOADED_FAVORITE_COMPANY',
  'ERROR_FAVORITE_COMPANY',
  'LOAD_DISCARD_COMPANY',
  'LOADED_DISCARD_COMPANY',
  'ERROR_DISCARD_COMPANY',
);

export const { companiesListRequest, moreCompaniesRequest, companiesListSuccess, companiesListFailure } = createActions(
  'COMPANIES_LIST_REQUEST',
  'MORE_COMPANIES_REQUEST',
  'COMPANIES_LIST_SUCCESS',
  'COMPANIES_LIST_FAILURE',
);

export const { loadDiscardedCompaniesList, loadedDiscardedCompaniesList, errorDiscardedCompaniesList } = createActions(
  'LOAD_DISCARDED_COMPANIES_LIST',
  'LOADED_DISCARDED_COMPANIES_LIST',
  'ERROR_DISCARDED_COMPANIES_LIST',
);

export const { loadFavoriteCompaniesList, loadedFavoriteCompaniesList, errorFavoriteCompaniesList } = createActions(
  'LOAD_FAVORITE_COMPANIES_LIST',
  'LOADED_FAVORITE_COMPANIES_LIST',
  'ERROR_FAVORITE_COMPANIES_LIST',
);

export const { flagWrongDataClicked, claimProfileClicked } = createActions(
  'FLAG_WRONG_DATA_CLICKED',
  'CLAIM_PROFILE_CLICKED',
);
