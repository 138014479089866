import { call, put, takeLatest } from 'redux-saga/effects';
import SearchClient from '../../api/SearchClient';
import {
  errorIndustryTags,
  loadIndustryTags,
  loadedIndustryTags,
  loadCompaniesHeadCount,
  loadedCompaniesHeadCount,
  errorCompaniesHeadCount,
  loadedCompaniesStage,
  errorCompaniesStage,
  loadCompaniesStage,
  loadCompanyFacets,
  loadedCompanyFacets,
  errorCompanyFacets,
} from '../actions/filters';

export function* loadIndustryFiltersEvent({ payload }) {
  const { network, type } = payload;
  try {
    const industryTags = yield call(SearchClient.findIndustryTags, { network, type });

    yield put(loadedIndustryTags({ data: industryTags }));
  } catch (e) {
    yield put(errorIndustryTags(e));
  }
}

export function* loadCompaniesHeadCountEvent({ payload }) {
  const { network, type } = payload;
  try {
    const companiesHeadCount = yield call(SearchClient.findCompaniesHeadCounts, { network, type });

    yield put(
      loadedCompaniesHeadCount({
        data: companiesHeadCount.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10)),
      }),
    );
  } catch (e) {
    yield put(errorCompaniesHeadCount(e));
  }
}

export function* loadCompaniesStageEvent({ payload }) {
  const { network, type } = payload;
  try {
    const stage = yield call(SearchClient.findCompaniesStage, { network, type });

    yield put(
      loadedCompaniesStage({
        data: stage,
      }),
    );
  } catch (e) {
    yield put(errorCompaniesStage(e));
  }
}

export function* loadCompanyFacetsEvent({ payload }) {
  const { network, origin } = payload;
  try {
    const data = yield call(SearchClient.findCompanyFacets, { network, origin });

    yield put(
      loadedCompanyFacets({
        data,
      }),
    );
  } catch (e) {
    yield put(errorCompanyFacets(e));
  }
}

export default function* filterWatcher() {
  yield takeLatest(loadIndustryTags().type, loadIndustryFiltersEvent);
  yield takeLatest(loadCompaniesHeadCount().type, loadCompaniesHeadCountEvent);
  yield takeLatest(loadCompaniesStage().type, loadCompaniesStageEvent);
  yield takeLatest(loadCompanyFacets().type, loadCompanyFacetsEvent);
}
