import React, { useEffect, useMemo, useCallback } from 'react';
import Script from 'next/script';
import PropTypes from 'prop-types';
import debounce from 'debounce';

export const Scripts = ({ advancedDesign, label }) => {
  const { scripts = [], linkedScripts = [] } = advancedDesign || {};

  const hasCustomScripts = scripts.length > 0 || linkedScripts.length > 0;
  const scriptsWithIds = useMemo(
    () => scripts.map((script, index) => ({ id: `navigation-script-${index}`, script })),
    [scripts],
  );

  const customHeaderId = useMemo(() => `${label}-custom-header`, [label]);

  const resetCustomHeader = useCallback((id) => {
    const elem = document.getElementById(id);
    if (!elem) return;
    const clone = elem.cloneNode(true);
    elem.parentNode.replaceChild(clone, elem);
  }, []);

  const loadADScriptHandler = debounce(() => {
    resetCustomHeader(customHeaderId);

    linkedScripts.forEach((url) => {
      const oldScript = document.querySelector(`[src="${url}"]`);
      if (oldScript) {
        const head = document.getElementsByTagName('head')[0];
        head.removeChild(oldScript);
      }
      const script = document.createElement('script');
      script.src = url;
      document.head.appendChild(script);
    });

    scriptsWithIds.forEach((item) => {
      try {
        document.getElementById(item.id)?.remove();
        const script = document.createElement('script');
        script.setAttribute('id', item.id);
        script.textContent = item.script;
        document.head.appendChild(script);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
  }, 1000);

  useEffect(() => {
    if (scriptsWithIds.length) {
      window.addEventListener('load-ad-script', loadADScriptHandler);

      return () => {
        window.removeEventListener('load-ad-script', loadADScriptHandler);
      };
    }

    return () => {};
  }, [loadADScriptHandler, scriptsWithIds]);

  if (!hasCustomScripts) return null;

  return (
    <>
      <Script
        id="jquery"
        strategy="beforeInteractive"
        src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"
      />
    </>
  );
};

Scripts.propTypes = {
  advancedDesign: PropTypes.shape({
    scripts: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  label: PropTypes.string.isRequired,
};

export default Scripts;
