import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  unsubscribeJobAlertErrorSelector,
  unsubscribeJobAlertSuccessSelector,
} from '../redux/selectors/jobAlertSelectors';
import { resetJobAlertActionsState } from '../redux/actions/jobAlertActions';

const useUnsubscribeSuccess = () => {
  const dispatch = useDispatch();
  const unsubscribeSuccess = useSelector(unsubscribeJobAlertSuccessSelector);
  const unsubscribeError = useSelector(unsubscribeJobAlertErrorSelector);

  useEffect(() => {
    if (unsubscribeError) {
      toast('Unsubscribed failed', { icon: 'danger', id: 'job_alert_api_response' });
      dispatch(resetJobAlertActionsState());
    }
    if (unsubscribeSuccess) {
      toast('You’ve been unsubscribed', { icon: 'success', id: 'job_alert_api_response' });
      dispatch(resetJobAlertActionsState());
    }
  }, [unsubscribeError, unsubscribeSuccess, dispatch]);
};

export default useUnsubscribeSuccess;
