import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  moveOnboardingTo,
  setOnboardingFormData,
  submitOnboardingFailure,
  submitOnboardingRequest,
  submitOnboardingSuccess,
} from '../actions/onboardingActions';
import { loadedLogoutRequest } from '../actions/userActions';
import initialValues from '../../containers/onboarding/initialValues';

export const initialState = {
  step: 0,
  submitting: false,
  formData: initialValues,
  errors: [],
};

/* eslint-disable default-case, no-param-reassign */
const onboardingReducer = handleActions(
  {
    [setOnboardingFormData]: produce((draft, action) => {
      const { payload } = action;
      draft.formData = { ...draft.formData, ...payload };
      return draft;
    }),
    [moveOnboardingTo]: produce((draft, action) => {
      const { payload } = action;
      draft.step = payload;
      return draft;
    }),
    [submitOnboardingRequest]: produce((draft) => {
      draft.submitting = true;
      return draft;
    }),
    [submitOnboardingSuccess]: produce((draft) => {
      draft.errors = [];
      draft.submitting = false;
      return draft;
    }),
    [submitOnboardingFailure]: produce((draft, action) => {
      const { payload } = action;
      draft.submitting = false;
      draft.errors = payload;
      return draft;
    }),
    [loadedLogoutRequest]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
  },
  initialState,
);

export default onboardingReducer;
