import { createAction, createActions } from 'redux-actions';

export const { loadCreateJobAlert, loadedCreateJobAlert, errorCreateJobAlert } = createActions(
  'LOAD_CREATE_JOB_ALERT',
  'LOADED_CREATE_JOB_ALERT',
  'ERROR_CREATE_JOB_ALERT',
);

export const { loadUpdateJobAlert, loadedUpdateJobAlert, errorUpdateJobAlert } = createActions(
  'LOAD_UPDATE_JOB_ALERT',
  'LOADED_UPDATE_JOB_ALERT',
  'ERROR_UPDATE_JOB_ALERT',
);

export const { loadUnsubscribeJobAlert, loadedUnsubscribeJobAlert, errorUnsubscribeJobAlert } = createActions(
  'LOAD_UNSUBSCRIBE_JOB_ALERT',
  'LOADED_UNSUBSCRIBE_JOB_ALERT',
  'ERROR_UNSUBSCRIBE_JOB_ALERT',
);

export const { resetJobAlertActionsState } = createActions('RESET_JOB_ALERT_ACTIONS_STATE');

export const { loadedJobAlert, verifyLoadedJobAlert, resetLoadedJobAlert } = createActions(
  'LOADED_JOB_ALERT',
  'VERIFY_LOADED_JOB_ALERT',
  'RESET_LOADED_JOB_ALERT',
);

export const { subscribeToJobAlertsCtaClicked, myJobAlertsCtaClicked } = createActions(
  'SUBSCRIBE_TO_JOB_ALERTS_CTA_CLICKED',
  'MY_JOB_ALERTS_CTA_CLICKED',
);

export const submitJobAlertCtaClicked = createAction('SUBMIT_JOB_ALERT_CTA_CLICKED');
