import { handleActions } from 'redux-actions';
import produce from 'immer';
import {
  allJobsFailure,
  allJobsRequest,
  allJobsSuccess,
  loadJobRequest,
  loadedJobRequest,
  errorJobRequest,
  loadedDiscardJob,
  moreJobsRequest,
  cleanJobRequest,
} from '../actions/jobs';
import { hydrate } from '../actions/hydrate';
import { loadedLogoutRequest } from '../actions/userActions';

export const initialState = {
  found: [],
  currentJob: null,
  total: 0,
  initialized: false,
  loading: false,
  isLazyLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const jobsReducer = handleActions(
  {
    [moreJobsRequest]: produce((draft) => {
      draft.loading = true;
      return draft;
    }),
    [loadedDiscardJob]: produce((draft, action) => {
      draft.found.map((job) => {
        if (job.id === action.payload.job.id) {
          job.isDiscarded = action.payload.discarded;
        }
        return job;
      });
      draft.loading = false;
      return draft;
    }),
    [allJobsRequest]: produce((draft) => {
      draft.loading = true;
      return draft;
    }),
    [allJobsSuccess]: produce((draft, action) => {
      const { page, jobs, total } = action.payload;
      if (page) {
        draft.found = [...draft.found, ...jobs];
        if (jobs.length) {
          draft.isLazyLoading = true;
        }
      } else {
        draft.found = jobs;
      }
      draft.total = parseInt(total, 10);
      draft.loading = false;
      draft.initialized = true;
      return draft;
    }),
    [allJobsFailure]: produce((draft) => {
      draft = { ...initialState };
      return draft;
    }),
    [loadJobRequest]: produce((draft) => {
      draft.loading = true;
      draft.currentJob = null;
      return draft;
    }),
    [loadedJobRequest]: produce((draft, action) => {
      const { payload } = action;
      draft.loading = false;
      draft.currentJob = payload;
      return draft;
    }),
    [errorJobRequest]: produce((draft) => {
      draft.loading = false;
      return draft;
    }),
    [cleanJobRequest]: produce((draft) => {
      draft.currentJob = null;
      return draft;
    }),
    [loadedLogoutRequest]: produce((draft) => {
      draft = initialState;
      return draft;
    }),
    [hydrate]: produce((draft, action) => {
      const { jobs } = action.payload;
      draft = { ...jobs };
      draft.loading = false;
      return draft;
    }),
  },
  initialState,
);

export default jobsReducer;
