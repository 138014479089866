import { put, select, takeEvery, call } from 'redux-saga/effects';
import { moveOnboardingBack, moveOnboardingTo } from '../actions/onboardingActions';
import { filterOnboardingSteps } from '../../containers/onboarding/steps';
import * as VisibilityFunctions from '../../containers/onboarding/stepVisibility';
import { skillsDeactivatedSelector } from '../selectors/network';
import { onboardingFormDataSelector, stepSelector } from '../selectors/onboarding';

export const checkPreviousStep = (currentStepIndex, formData, steps) => {
  let previousStepIndex = -1;
  for (let i = currentStepIndex - 1; i >= 0 && previousStepIndex === -1; i -= 1) {
    const step = steps[i];

    const { visibilitySelector } = step;
    if (visibilitySelector === undefined) {
      previousStepIndex = i;
    } else {
      const visible = VisibilityFunctions[visibilitySelector](formData);
      if (visible) {
        previousStepIndex = i;
      }
    }
  }

  return previousStepIndex;
};

export function* moveOnboardingBackwardRequestWorker() {
  const currentStepIndex = yield select(stepSelector);
  const formData = yield select(onboardingFormDataSelector);
  const skillsDeactivated = yield select(skillsDeactivatedSelector);
  const steps = yield call(filterOnboardingSteps, {
    skillsDeactivated,
  });
  const previousStepIndex = yield call(checkPreviousStep, currentStepIndex, formData, steps);
  if (previousStepIndex !== -1) {
    yield put(moveOnboardingTo(previousStepIndex));
  }
}

export default function* moveOnboardingBackwardRequestWatcher() {
  yield takeEvery(moveOnboardingBack().type, moveOnboardingBackwardRequestWorker);
}
